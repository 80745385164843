import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import styled from "styled-components/macro";
const { IbanElement } = require("@stripe/react-stripe-js");

// Custom styling can be passed as options when creating an Element.
const IBAN_STYLE = {
  base: {
    color: "#32325d",
    fontSize: "16px",
    "::placeholder": {
      color: "#aab7c4",
    },
    ":-webkit-autofill": {
      color: "#32325d",
    },
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
    ":-webkit-autofill": {
      color: "#fa755a",
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ["SEPA"],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  placeholderCountry: "DE",
  style: IBAN_STYLE,
};

const IbanFormWrapper = styled("div")`
  .sr-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: var(--form-width);
    min-width: 450px;
    align-self: center;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 0px 0px 0.5px,
      rgba(50, 50, 93, 0.1) 0px 2px 5px 0px,
      rgba(0, 0, 0, 0.07) 0px 1px 1.5px 0px;
    padding: 32px 50px;
    background: var(--body-color);
    border-radius: var(--radius);
  }
  .sr-combo-inputs-row:first-child {
    margin-top: 0px;
  }
  .sr-combo-inputs-row {
    margin-top: 20px;
  }
  .sr-combo-inputs-row {
    width: 100%;
    margin-top: 20px;
  }
  .sr-combo-inputs-row {
    display: flex;
  }
  .sr-combo-inputs-row .col:not(:last-child) {
    margin-right: 20px;
  }
  .sr-combo-inputs-row .col {
    width: 100%;
  }
  label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    max-width: 100%;
    color: rgb(107, 124, 147);
  }
  #work {
    width: 100%;
    outline: none;
    height: 40px;
    color: rgb(50, 50, 93);
    background-color: white;
    box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
    padding: 10px 12px;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    border-image: initial;
    border-radius: 4px;
    transition: box-shadow 150ms ease 0s;
    :-internal-autofill-selected {
      appearance: menulist-button;
      background-image: none !important;
      background-color: -internal-light-dark(
        rgb(232, 240, 254),
        rgba(70, 90, 126, 0.4)
      ) !important;
      color: -internal-light-dark(black, white) !important;
    }
  }
  .StripeElement--webkit-autofill {
    background-color: rgb(254, 253, 229) !important;
  }
  .StripeElement {
    height: 40px;
    color: rgb(50, 50, 93);
    background-color: white;
    box-shadow: rgb(230, 235, 241) 0px 1px 3px 0px;
    padding: 10px 12px;
    border-width: 1px;
    border-style: solid;
    width: 100%;
    border-color: transparent;
    border-image: initial;
    border-radius: 4px;
    transition: box-shadow 150ms ease 0s;
  }
  #mandate-acceptance {
    font-size: 14px;
    text-align: justify;
    margin: 20px 0px;
  }
  #confirm-mandate:hover {
    filter: contrast(115%);
  }
  #confirm-mandate {
    color: white;
    margin-top: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 4px 5.5px 0px;
    width: 100%;
    background: #32325d;
    border-radius: 6px;
    margin-bottom: 10px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    padding: 12px 16px;
    transition: all 0.2s ease 0s;
  }
`;

/*
 */

function IbanForm({ onSubmit, disabled, price }: any) {
  const { t } = useTranslation();
  const isEnglish = useSelector((state: RootState) => state.global.language);
  return (
    <IbanFormWrapper>
      <div className="sr-main">
        <form onSubmit={onSubmit}>
          <div className="sr-combo-inputs-row">
            <div className="col">
              <label>Name</label>
              <input
                name="accountholder-name"
                placeholder="Jenny Rosen"
                required
                id="work"
              />
            </div>

            <div className="col">
              <label>{t("Email Address")}</label>
              <input
                name="email"
                type="email"
                id="work"
                placeholder="jenny.rosen@example.com"
                required
              />
            </div>
          </div>

          <div className="sr-combo-inputs-row">
            <div className="col">
              <label>IBAN</label>
              <IbanElement options={IBAN_ELEMENT_OPTIONS} />
            </div>
          </div>
          <button type="submit" id="confirm-mandate" disabled={disabled}>
            {isEnglish !== "de"
              ? `Confirm mandate and initiate debit for ${price}€`
              : `Zahlung bestätigen ${price}€`}
          </button>
          {/* Display mandate acceptance text. */}
          <div className="mandate-acceptance">
            {t(
              "By providing your payment information and confirming this payment, you authorise (A) Rocket Rides and Stripe, our payment service provider, to send instructions to your bank to debit your account and (B) your bank to debit your account in accordance with those instructions. As part of your rights, you are entitled to a refund from your bank under the terms and conditions of your agreement with your bank. A refund must be claimed within 8 weeks starting from the date on which your account was debited. Your rights are explained in a statement that you can obtain from your bank. You agree to receive notifications for future debits up to 2 days before they occur."
            )}
          </div>
        </form>
      </div>
    </IbanFormWrapper>
  );
}

export default IbanForm;
