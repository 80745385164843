import * as actionTypes from "./application.actionTypes";

export const setCurrentStep = (step: number) => {
  return {
    payload: step,
    type: actionTypes.SET_CURRENT_STEP,
  };
};

export const setPopupCurrentStep = (step: number) => {
  return {
    payload: step,
    type: actionTypes.SET_POPUP_CURRENT_STEP,
  };
};
