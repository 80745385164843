import { FormLabel, Button } from "@mui/material";
import {
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import { countryList } from "lib/countries_db";
import { Input, Select } from "antd";
import { PaymentCardWrapper } from "./PaymentCardStyles";
import { useTranslation } from "react-i18next";
const { Option } = Select;

const PaymentWithCard = ({
  setDisabled,
  setError,
  setIsPaymentWithBankAcc,
  isPaymentWithBankAcc,
  paymentRequest,
  submitHandler,
  email,
  setEmail,
  setName,
  country,
  setCountry,
  zipCode,
  setZipCode,
  name,
  processing,
  error,
  disabled,
  succeeded,
}: any) => {
  const handleChange = (event: any) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  const onSearch = (value: string) => {};
  const { t } = useTranslation();

  return (
    <PaymentCardWrapper>
      <div className="sr-main">
        <div>
          <div>
            {/* {paymentRequest && (
              <PaymentRequestButtonElement options={{ paymentRequest }} />
            )} */}
            <form onSubmit={submitHandler}>
              <FormLabel component="legend" className="lable_class">
                E-Mail
              </FormLabel>
              <Input
                placeholder={t("Enter E-Mail")}
                value={email}
                className="myInputClass"
                onChange={(e) => setEmail(e.target.value)}
              />
              <FormLabel component="legend" className="lable_class">
                {t("Card Details")}
              </FormLabel>

              <CardElement
                //@ts-ignore
                className="myInputClass"
                onChange={handleChange}
              />
              <FormLabel component="legend" className="lable_class">
                {t("Name on Card")}
              </FormLabel>
              <Input
                placeholder="Enter Your Name"
                value={name}
                className="myInputClass"
                onChange={(e) => setName(e.target.value)}
              />
              <FormLabel component="legend" className="lable_class">
                {t("Country or Region")}
              </FormLabel>
              <Select
                showSearch
                placeholder={t("Select a contry")}
                optionFilterProp="children"
                onChange={(e) => setCountry(e.target.value)}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
              >
                {countryList.map((item: any) => (
                  <Option value={item.name}>{item.name}</Option>
                ))}
              </Select>
              <FormLabel component="legend" className="lable_class">
                Zip code
              </FormLabel>
              <Input
                placeholder={t("Enter Zip Code")}
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
                className="myInputClass"
              />
              <Button
                fullWidth
                variant="contained"
                className="loginBtn"
                onClick={submitHandler}
                //@ts-ignore
                disabled={processing || disabled || succeeded}
              >
                <span>{processing ? <p> Processing</p> : t("Pay Now")}</span>
              </Button>

              {error && <div>{error}</div>}
            </form>
          </div>
        </div>
      </div>
    </PaymentCardWrapper>
  );
};

export default PaymentWithCard;
