import { Flex } from "@chakra-ui/react";
import Info from "components/vectors/Step2/Info";

const InfoIcon = (props: any) => {
  const { onClick } = props;
  return (
    <Flex
      align={"center"}
      justify={"center"}
      onClick={onClick}
      cursor={"pointer"}
    >
      <Info />
    </Flex>
  );
};

export default InfoIcon;
