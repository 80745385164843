import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/storage";

const stagingDatabase = {
  apiKey: "AIzaSyCzKo9f7eVSZg1eWyZ-nsrJ3ASNqgnDxd4",
  authDomain: "app.solarhub24.de",
  projectId: "solarhub-44450",
  storageBucket: "solarhub-44450.appspot.com",
  messagingSenderId: "217083964770",
  appId: "1:217083964770:web:9d012b55c4f86172f3d03b",
  measurementId: "${config.measurementId}"
};

const productionDatabase = {
  apiKey: "AIzaSyB4NpPwTSm3pChudxdAky_J2qBdGMlR0G8",
  authDomain: "app.solarhub24.de",
  projectId: "solarhub-production",
  storageBucket: "solarhub-production.appspot.com",
  messagingSenderId: "29847521309",
  appId: "1:29847521309:web:1469755bfff17226ba1ef4",
  measurementId: "G-KHM792Y595"
};

/**
 * required to chnage whenever we are changing database stage to prod or prod to stage
 */
// export const currentDatabase = stagingDatabase;
export const currentDatabase = productionDatabase;

//Please use alias for staging and production database
// @ts-ignore
if (!firebase?.apps.length) {
  firebase.initializeApp(currentDatabase);
} else {
  //@ts-ignore
  firebase.app();
}

// @ts-ignore
export var db = firebase.firestore();

export default firebase;
