import { makeStyles } from "@mui/styles";

export const useStyles: any = makeStyles((theme: any) => ({
  root: {
    width: "100%",
    maxWidth: "1051px",
    height: "675px",
    overflow: "auto",
    overflowX: "hidden",
    // height: "fit-content",
    margin: "0",
    padding: "0 120px 30px",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      padding: "50px",
      paddingTop: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "50px 30px",
      paddingTop: "0px",
    },
    justifyContent: "flex-start",
    alignItems: "center",
  },
  button_group: {
    backgroundColor: "#FFD75D",
    border: "none",
    borderRadius: "16px",
    fontSize: "13px",
    padding: "9px",
    fontWeight: "600px",
    color: "#2D4764",
    textTransform: "uppercase",
    "&:hover": {
      cursor: "pointer",
      transform: "scale(1.01)",
    },
    marginBlock: "15px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "15px",
      padding: "10px 30px",
    },
  },
  icon: {
    width: "30px",
    justifySelf: "flex-end",
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#2D4764",
    marginRight: "10px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  resize: {
    fontSize: "10px",
  },
  searchInput: {
    zIndex: "3",
    background: "#FFFFFF",
    borderRadius: "336px",
    [theme.breakpoints.down("xs")]: {
      width: "274px",
    },
    "&:hover": {
      transform: "scale(1.01)",
      background: "rgba(253, 184, 0, 0.1)",
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)",
    },
    width: "400px",
    "&.MuiFormControl-root div.MuiOutlinedInput-root": {
      borderRadius: "336px",
      border: "3px solid #FFD75D",
      paddingRight: 0,
    },
    "&.MuiFormControl-root div.MuiOutlinedInput-root .MuiInputAdornment-positionEnd .MuiTypography-root":
      {
        background:
          "linear-gradient(148.54deg, #FFE925 20.91%, #FFAA39 105.47%)",
        borderRadius: "336px",
        fontSize: "16px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "10px",
        },
        fontFamily: "Nunito",
        height: "30px",
      },
    "&.MuiOutlinedInput-notchedOutline div.PrivateNotchedOutline-root-193 div.MuiOutlinedInput-notchedOutline .PrivateNotchedOutline-root-193":
      {
        borderStyle: "none",
      },
  },
  noBorder: {
    border: "none",
  },
  imgRes: {
    height: "207px",
    [theme.breakpoints.down("xs")]: {
      height: "246px",
    },
  },
  searchAndPlayButton: {
    display: "flex",
    justifyContent: "space-around",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
    alignItems: "center",
    width: "90%",
  },
  buttonDiscover: {
    color: "rgba(45, 71, 100, 1)",
    padding: "5px 0",
    [theme.breakpoints.down("xs")]: {
      fontSize: "10px",
    },
    fontWeight: "600",
    borderRadius: "25px",
    textAlign: "center",
  },
  h1Helper: {
    [theme.breakpoints.down("xs")]: {
      fontSize: "15px",
    },
  },
}));
