import { CometChat } from "@cometchat-pro/chat";
import { COMETCHAT_CONSTANTS } from "comet_const";
import * as actionTypes from "./chat.actionTypes";

export const cometChatInit = (payload: any) => {
  return {
    type: actionTypes.COMET_CHAT_INIT,
    payload,
  };
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START,
  };
};

export const authSuccess = (user: any) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    user: user,
    isLoggedIn: true,
  };
};

export const authFail = (error: any) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error,
  };
};

export const logoutSuccess = () => {
  return {
    type: actionTypes.AUTH_LOGOUT,
  };
};

export const logout = () => {
  return (dispatch: any) => {
    CometChat.logout().then(
      () => {
        dispatch(logoutSuccess());
      },
      (error) => {}
    );
  };
};

export const auth = (uid: any, authKey: any) => {
  return (dispatch: any) => {
    dispatch(authStart());

    CometChat.login(uid, authKey)
      .then((user) => {
        if (user) {
          dispatch(authSuccess(user));
        } else {
          dispatch(authFail(user));
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};

export const createUser = (uid: any, name: any) => {};

export const authCheckState = () => {
  return (dispatch: any) => {
    CometChat.getLoggedinUser()
      .then((user) => {
        if (user) {
          dispatch(authSuccess(user));
        } else {
          dispatch(authFail(user));
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};

/* export const myCometChat = (uid: any, name: any, avatar: any) => {
  return (dispatch: any) => {
    CometChat.getLoggedinUser()
      .then((user) => {
        if (!user) {
          CometChat.login(uid, process.env.REACT_APP_COMETCHAT_AUTH_KEY).then(
            (user) => {
              dispatch(authSuccess(user));
            },
            (error) => {
              const new_user = new CometChat.User(uid);
              new_user.setUid(uid);
              new_user.setName(name);
              new_user.setAvatar(avatar);
              CometChat.createUser(
                new_user,
                //@ts-ignore
                process.env.REACT_APP_COMETCHAT_AUTH_KEY
              ).then(
                (user) => {
                  dispatch(auth(uid, process.env.REACT_APP_COMETCHAT_AUTH_KEY));
                },
                (error) => {}
              );
            }
          );
        } else {
          dispatch(authSuccess(user));
          // window.location.assign("/application");
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
}; */

export const myCometChat = (uid: any, name: any, avatar: any) => {
  return (dispatch: any) => {
    CometChat.getLoggedinUser()
      .then((user) => {
        if (!user) {
          CometChat.login(uid, COMETCHAT_CONSTANTS.AUTH_KEY).then(
            (user) => {
              dispatch(authSuccess(user));
            },
            (error) => {
              const new_user = new CometChat.User(uid);
              new_user.setUid(uid);
              new_user.setName(name);
              new_user.setAvatar(avatar);
              CometChat.createUser(new_user, COMETCHAT_CONSTANTS.AUTH_KEY).then(
                (user) => {
                  dispatch(auth(uid, COMETCHAT_CONSTANTS.AUTH_KEY));
                },
                (error) => {
                  console.log("error comet", error);
                  console.log(
                    "user details",
                    new_user,
                    "-",
                    COMETCHAT_CONSTANTS.AUTH_KEY
                  );
                }
              );
            }
          );
        } else {
          dispatch(authSuccess(user));
          // window.location.assign("/application");
        }
      })
      .catch((error) => {
        console.log("lol", error);
        dispatch(authFail(error));
      });
  };
};
export const myCometChat1 = (uid: any, name: any) => {
  return (dispatch: any) => {
    CometChat.getLoggedinUser()
      .then((user) => {
        if (user) {
          dispatch(logout());
        } else {
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      });
  };
};

export const setAuthRedirectPath = (path: any) => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path: path,
  };
};
