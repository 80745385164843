import { db } from "firebase-global";

export const setSelfSupply = (value: number, maxPossible: number) => {
  try {
    if (value <= maxPossible) {
      return value / 100;
    }
  } catch (err) {
    console.log(err);
  }
};

export const returnFeatchDataSolarhub = async (pid: string) => {
  const news = await db
    .collection("solarhub_&_installer_Rating")
    .doc(pid)
    .get()
    .then((doc) => {
      return doc.data();
    })
    .catch((error) => {
      return false;
    });
  return news;
};

export const getNumber = (x: any) => parseFloat(x ? x : 0);
