import { CircularProgress, TextField } from "@mui/material";
import { signInWithUserEmailLink } from "api/auth";
import AddressSelector from "components/popups/welcome/welcomeWithNoAddress/adressselect/AdressSelecor";
import {
  getAuthPopup,
  getLanguageShortCode,
  getMapAdress,
  getProjectDetails
} from "helpers/Selector";
import { useProjectTools } from "hooks/useProjectTools";
import { Mixpanel } from "mixpanel";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setPopupData } from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import { RootState } from "redux/store";
import * as trackingEvents from "trackingEvents";
import AuthButtons from "../authModeButtons/authButtons";
import cssClasses from "./login.module.css";
const SignUpPopup = ({ setSubtitle }: any) => {
  const { t } = useTranslation();
  const { save } = useProjectTools();
  const [showAddressField, setShowAddressField] = useState(true);
  const [isGoogleLogin, setIsGoogleLogIn] = useState(false);
  const project = useSelector(getProjectDetails);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isEnglish = useSelector(getLanguageShortCode);
  const authPopupState = useSelector(getAuthPopup);

  const [signupForm, setSignupForm] = useState<any>({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: ""
  });

  const [loading, setLoading] = useState(false);
  const authPopupData = useSelector(
    (state: RootState) => state.popups.AUTH_POPUP
  );

  const formValidation = () => {
    let isError = false;
    const objectKeys = Object.keys(signupForm);
    const germanFieldArr = [
      "Vorname",
      "Nachname",
      "E-main",
      "Passwort",
      "Telefonnummer",
      "Adresse"
    ];

    for (let i = 0; i < objectKeys.length; i++) {
      if (!signupForm[objectKeys[i]]) {
        isError = true;
        return toast.warn(
          t("{{ filedname }} is required", {
            filedname: isEnglish === "en" ? objectKeys[i] : germanFieldArr[i]
          })
        );
      }
    }
    return isError;
  };

  const googleValidation = () => {
    let isError = false;
    const objectKeys = ["firstname", "lastname", "phoneNumber", "address"];
    const germanFieldArr = ["Vorname", "Nachname", "Telefonnummer", "Adresse"];

    for (let i = 0; i < objectKeys.length; i++) {
      if (!signupForm[objectKeys[i]]) {
        isError = true;
        return toast.warn(
          t("{{ filedname }} is required", {
            filedname: isEnglish === "en" ? objectKeys[i] : germanFieldArr[i]
          })
        );
      }
    }
    return isError;
  };

  const submitHandler = async () => {
    if (authPopupData.data.authMode == AUTH_MODES.WEBINAR_SIGN_UP) {
      if (!signupForm.address || signupForm.address === "") {
        return toast.warn(
          "Please provide your address to continue the process"
        );
      }
    }
    if (formValidation()) {
      return;
    }
    setLoading(true);
    Mixpanel.track(trackingEvents.USER_REGISTER, {});
    await signInWithUserEmailLink(signupForm, save, project, navigate);
    setLoading(false);
  };

  const mapAdress = useSelector(getMapAdress);

  const updateForm = (target: any, value: any) => {
    setSignupForm((prev: any) => {
      const newForm: any = { ...prev };

      if (target === "email") {
        newForm.email = value;
      } else if (target === "password") {
        newForm.password = value;
      } else if (target === "firstname") {
        newForm.firstname = value;
      } else if (target === "lastname") {
        newForm.lastname = value;
      } else if (target === "phoneNumber") {
        newForm.phoneNumber = value;
      } else if (target === "address") {
        newForm.address = value;
      }

      return newForm;
    });
  };

  /**
   * useEffect
   * @purpose : Default address populate in form if user already has address
   * @assumptions : address is stored in user.mapadress.complete
   * @notice : will not have any dependency as mapaddress will change as soon as
   *    user will select address, so on first load, it should get the right
   *    map address status
   */
  useEffect(() => {
    if (!mapAdress) return;
    const selectedAddr = mapAdress.complete;
    if (selectedAddr === "") return;
    updateForm("address", selectedAddr);
    setShowAddressField(false);
  }, []);

  const goToLogin = () => {
    let nextMode = AUTH_MODES.LOG_IN;
    if (authPopupState.data.authMode == AUTH_MODES.WEBINAR_SIGN_UP)
      nextMode = AUTH_MODES.WEBINAR_LOG_IN;
    dispatch(
      setPopupData(POPUPS.AUTH_POPUP, {
        authMode: nextMode
      })
    );
  };

  return (
    <>
      <div className={cssClasses.loginContainer}>
        <TextField
          fullWidth
          id="first-name-form-field"
          label={t("Enter first name")}
          variant="outlined"
          onChange={(e: any) => {
            updateForm("firstname", e.target.value);
          }}
        />

        <TextField
          fullWidth
          id="last-name-form-field"
          label={t("Enter last name")}
          variant="outlined"
          onChange={(e: any) => {
            updateForm("lastname", e.target.value);
          }}
        />

        {!isGoogleLogin && (
          <TextField
            fullWidth
            id="email-form-field"
            label={t("Enter E-Mail")}
            variant="outlined"
            onChange={(e: any) => {
              updateForm("email", e.target.value);
            }}
          />
        )}

        {!isGoogleLogin && (
          <TextField
            fullWidth
            id="password-form-field"
            label={t("Password")}
            type="password"
            variant="outlined"
            onChange={(e: any) => {
              updateForm("password", e.target.value);
            }}
          />
        )}

        <TextField
          fullWidth
          id="phone-number-field"
          label={t("Enter phone number")}
          variant="outlined"
          onChange={(e: any) => {
            updateForm("phoneNumber", e.target.value);
          }}
        />

        {showAddressField && (
          <AddressSelector
            address={signupForm.address ?? ""}
            handleClose={() => {}}
            isFromSignup
            setAddress={(addr: any) => {
              console.log(addr, "addr");
              updateForm("address", addr);
            }}
          ></AddressSelector>
        )}
      </div>

      <button
        className={cssClasses.loginButton}
        onClick={submitHandler}
        disabled={loading}
      >
        {t("REGISTER")}
        {loading && (
          <CircularProgress size={20} className="circular-progress-custom" />
        )}
      </button>

      <AuthButtons
        setIsGoogleLogIn={setIsGoogleLogIn}
        setSubtitle={setSubtitle}
        googleValidation={googleValidation}
        isGoogleLogin={isGoogleLogin}
      />

      <div className={cssClasses.formFooter}>
        <div className={cssClasses.blueLinkText} onClick={goToLogin}>
          {t("ALREADY HAVE AN ACCOUNT")}
        </div>
        <div className={cssClasses.yellowLinkText} onClick={goToLogin}>
          {t("LOGIN NOW")}
        </div>
      </div>

      <div className={cssClasses.formFooter} style={{ marginTop: "5px" }}>
        <div className={cssClasses.blueLinkText}>{t("ANY QUESTION")}</div>
        <a
          className={cssClasses.yellowLinkText2}
          style={{
            textDecoration: "none"
          }}
          href="mailto:info@solarhub24.de"
        >
          {t("CONTACT US NOW")}
        </a>
      </div>
    </>
  );
};

export default SignUpPopup;
