import styled from "styled-components/macro";

export const InstallerRequestSuccessfullWrapper = styled.div`
  width: 100%;
  max-width: 727px;
  padding: 0 30px 20px;
  padding-top: 2rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .image_and_text {
    display: flex;
    gap: 1rem;
    align-items: center;

    .text {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
`;

export const MainText = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #2d4764;
  margin-bottom: 1rem;
  @media (max-width: 600px) {
    margin-bottom: 0;
  }
`;

export const SubText1 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
`;
