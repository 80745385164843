import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { getPopupCurrentStep } from "helpers/Selector";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { setPopupCurrentStep } from "redux/application/application.action";
import { RootState } from "redux/store";
import { popupStepperHeading } from "utils/constFunctions";
import * as S from "../stepper/styles";

const PopupStepper = () => {
  const { t } = useTranslation();
  const [activeStep, setactiveStep] = useState(1);
  const [data, setData] = useState<Array<string>>(popupStepperHeading);
  const user = useSelector((state: RootState) => {
    return state.user.currentUser;
  });
  const currentStep = useSelector(getPopupCurrentStep);
  const dispatch = useDispatch();
  useEffect(() => {
    setactiveStep(currentStep);
  }, [currentStep]);

  return (
    <S.Container style={{ marginBottom: "30px" }}>
      <Stepper
        connector={<S.Connector />}
        activeStep={activeStep}
        alternativeLabel
      >
        {data.length > 0 &&
          data.map((heading: any, index: number) => (
            <Step key={heading} disabled={true}>
              <StepLabel
                onClick={() => {
                  if (index !== 1) return;
                  setactiveStep(index);
                  dispatch(setPopupCurrentStep(index));
                }}
              >
                {t(heading)}
              </StepLabel>
            </Step>
          ))}
      </Stepper>
    </S.Container>
  );
};

export default PopupStepper;
