import { updateUserInfo } from "api/auth";
import PopupSkeleton from "components/popups/popupSkeleton/popupSkeleton";
import { getCurrentUser, getLanguageShortCode } from "helpers/Selector";
import useWindowDimensions from "hooks/windowDimension";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "redux/popups/popups.actions";
import { POPUPS } from "redux/popups/popups.reducer";
import WelcomePopup from "./WelcomePopup";
import WelcomePopupMobile from "./WelcomePopupMobile/WelcomePopupMobile";

const WelcomeWebinar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { height, width } = useWindowDimensions();
  const currentUser = useSelector(getCurrentUser);
  const frame = "https://youtu.be/UGxqbY7v_l0";

  let [headingArr, setheadingArr] = useState("");
  let [listHeadArr, setlistHeadArr] = useState("");
  let [listTitles, setlistTitles]: any = useState(["", "", "", ""]);
  const languageCode = useSelector(getLanguageShortCode);
  useEffect(() => {
    setheadingArr(t("Let´s find your sustainable energy setup together"));
    setlistHeadArr(
      t(
        "We have send you a confirmation via mail so you can add the webinar to your calendar. To find the best energy setup for you we will go through the following steps:"
      )
    );
    setlistTitles([
      t("Planning"),
      t("Product Selection"),
      t("Image Upload"),
      t("Realization"),
    ]);
  }, [languageCode]);

  return (
    <PopupSkeleton
      open={true}
      handleClose={() => {
        dispatch(closePopup(POPUPS.WELCOME_WEBINAR));
      }}
      styleToDialog={{ zIndex: "110" }}
    >
      {width > 800 && (
        <WelcomePopup
          frame={frame}
          headingArr={headingArr}
          listHeadArr={listHeadArr}
          listTitles={listTitles}
        />
      )}
      {width <= 800 && (
        <WelcomePopupMobile
          frame={frame}
          listHeadArr={listHeadArr}
          listTitles={listTitles}
        />
      )}
    </PopupSkeleton>
  );
};

export default WelcomeWebinar;
