import BeenhereIcon from "@mui/icons-material/Beenhere";
import { Radio, Select } from "antd";
import DownArrow from "components/vectors/homePage/downArrow";
import UpArrow from "components/vectors/homePage/upArrow";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/store";
import * as S from "./styles";
const { Option } = Select;
const Description = ({ type }: any) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState("Digital");
  const [valueForDes, setValueForDes] = React.useState("");
  const ref: any = useRef();
  const [openDescription, setOpenDescription] = useState(false);
  const dispatch = useDispatch();
  const project = useSelector((state: RootState) => state.project);
  const onDescriptionChange = (value: any, type: string) => {
    dispatch({ type: "SET_PROJECT_DESCRIPTION", payload: { value, type } });
  };
  function handleChange(value: any) {
    dispatch({
      type: "SET_PROJECT_OPTION",
      payload: { value, type: "house_top" },
    });
  }
  const onChange: any = (e: any) => {
    setValue(e.target.value);
    dispatch({
      type: "SET_PROJECT_OPTION",
      payload: { value: e.target.value, type: "electric_meter" },
    });
  };
  const onChangeHandler = (e: any, type: string) => {
    if (e.target.value.length >= 500) {
      return toast.warning(t("Very long description is not allowed"));
    }
    setValueForDes(e.target.value);
    onDescriptionChange(e.target.value, type);
  };
  const containScroll1: any = () => {
    setTimeout(() => {
      document?.getElementById("tries")?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };
  useEffect(() => {
    if (
      project?.project_images?.[`${type}`]?.selectedOption ||
      project?.project_images?.[`${type}`]?.description ||
      project?.project_images?.electric_meter?.selectedOption
    ) {
      setOpenDescription(true);
    }
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <S.SubContainer1
        openDescription={openDescription}
        onClick={() => {
          setOpenDescription(!openDescription);
        }}
      >
        <S.MainText11>{t("Add description")}</S.MainText11>
        {openDescription ? (
          <S.ArrowContainer
            onClick={() => {
              setOpenDescription(false);
              document
                .getElementById("application-body-container")
                ?.scrollIntoView({ behavior: "smooth" });
            }}
          >
            <UpArrow />
          </S.ArrowContainer>
        ) : (
          <S.ArrowContainer
            onClick={() => {
              setOpenDescription(true);
              containScroll1();
            }}
          >
            <DownArrow />
          </S.ArrowContainer>
        )}
      </S.SubContainer1>
      {openDescription && (
        <>
          {type === "house_top" && (
            <div style={{ paddingLeft: "10px" }}>
              <S.MainText1
                style={{ fontSize: "14px", marginBottom: "initial" }}
              >
                {t("Building year of the roof")}
              </S.MainText1>
              <S.SubContainer112>
                <Select
                  defaultValue={`After 1980`}
                  value={
                    t(
                      project?.project_images?.house_top?.selectedOption.split(
                        " "
                      )[0]
                    ) +
                    " " +
                    project?.project_images?.house_top?.selectedOption.split(
                      " "
                    )[1]
                  }
                  style={{ width: 233 }}
                  onChange={handleChange}
                >
                  <Option value="After 1980">{`${t("After")} 1980`}</Option>
                  <Option value="After 1990">{`${t("After")} 1990`}</Option>
                  <Option value="After 2000">{`${t("After")} 2000`}</Option>
                  <Option value="After 2010">{`${t("After")} 2010`}</Option>
                  <Option value="After 2020">{`${t("After")} 2020`}</Option>
                </Select>
              </S.SubContainer112>

              <S.SubText3
                ref={ref}
                placeholder={t("Add your home description....")}
                onChange={(e) => onChangeHandler(e, "house_top")}
                value={project?.project_images?.house_top?.description}
              />
              {valueForDes === "" ? (
                ""
              ) : (
                <BeenhereIcon
                  style={{
                    position: "absolute",
                    bottom: "37px",
                    right: "25px",
                  }}
                />
              )}
            </div>
          )}
          {type === "house_bottom" && (
            <div style={{ paddingLeft: "10px" }}>
              {/* <div style={{ height: "83px" }}></div> */}

              <S.SubText4
                placeholder={t("Add your home description....")}
                onChange={(e) => onChangeHandler(e, "house_bottom")}
                value={project?.project_images?.house_bottom?.description}
              />
              {valueForDes === "" ? (
                ""
              ) : (
                <BeenhereIcon
                  style={{
                    position: "absolute",
                    bottom: "37px",
                    right: "15px",
                  }}
                />
              )}
            </div>
          )}
          {type === "electric_meter" && (
            <div style={{ paddingLeft: "10px" }}>
              <S.MainText1
                style={{ fontSize: "14px", marginBottom: "initial" }}
              >
                {t("Electric meter type")}
              </S.MainText1>
              <S.SubContainer112>
                <Radio.Group
                  onChange={onChange}
                  value={
                    project?.project_images?.electric_meter?.selectedOption
                  }
                  style={{ display: "flex", gap: "10px" }}
                >
                  <Radio value={"Analog"}>{t("Digital meter")}</Radio>
                  <Radio value={"Digital"}>{t("Analog meter")}</Radio>
                </Radio.Group>
              </S.SubContainer112>

              <S.SubText3
                placeholder={t("Add your elctric meter description....")}
                onChange={(e) => onChangeHandler(e, "electric_meter")}
                value={project?.project_images?.electric_meter?.description}
              />
              {valueForDes === "" ? (
                ""
              ) : (
                <BeenhereIcon
                  style={{
                    position: "absolute",
                    bottom: "37px",
                    right: "15px",
                  }}
                />
              )}
            </div>
          )}
        </>
      )}
      <div id={"tries"} />
    </div>
  );
};

export default Description;
