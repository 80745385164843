export const setCurrentUser = (currentUser: any) => ({
  type: "SET_CURRENT_USER",
  payload: currentUser,
});
export const setUserDetails = (currentUserDetails: any) => ({
  type: "SET_USER_DETAILS",
  payload: currentUserDetails,
});
export const actionSetIsAuthStateLoading = (flag: boolean) => ({
  type: "SET_AUTH_USER_LOADING",
  payload: flag,
});
