export const MuiStepIconOverrides = {
  styleOverrides: {
    root: {
      "& .MuiStepIcon-text": {
        baselineShift: -1,
        letterSpacing: 1,
      },

      "&.Mui-active": {
        color: "var(--primary-color)",
        transform: "scale(1.4)",
        zIndex: 100,
        "& .MuiStepIcon-text": {
          baselineShift: -1,
          letterSpacing: 0,
        },
      },
      "&.Mui-completed": {
        color: "var(--primary-color)",
        backgroundImage:
          "radial-gradient(var(--blue) 0%, var(--blue) 50%, transparent 50%)",
      },
    },
    active: {},
    completed: {},
  },
};
