import { CircularProgress } from "@mui/material";
import { Tooltip } from "antd";
import { t } from "i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import InfoIcon from "shared/InfoIcon";
import Description from "./Description";
import Sample from "./Sample";
import * as S from "./styles";
import UploadCardArea from "./UploadCardArea";
import UploadImage from "./UploadImage";
const UploadCard = ({ imagesList, setimagesList, text, type }: any) => {
  const [loading, setLoading] = useState(false);
  const total = useSelector(
    (state: RootState) => state?.project?.project_images?.[type]?.total
  );

  return (
    <S.MainContainer>
      {type === "house_top" && (
        <>
          <Tooltip
            placement="topRight"
            title={t("please upload pictures separately")}
          >
            <S.MainText>
              {text}
              <InfoIcon />
            </S.MainText>
          </Tooltip>
        </>
      )}
      <UploadCardArea
        type={type}
        setFiles={setimagesList}
        imagesList={imagesList}
        setLoading={setLoading}
      />
      {loading ? (
        <S.LoadContainer>
          <CircularProgress />
        </S.LoadContainer>
      ) : total.length > 0 ? (
        <UploadImage
          type={type}
          imagesList={imagesList}
          setFiles={setimagesList}
          setLoading={setLoading}
        />
      ) : (
        <Sample type={type} />
      )}
      <Description type={type} />
    </S.MainContainer>
  );
};

export default UploadCard;
