import styled from "styled-components/macro";
import Close from "./Close.svg";

const CloseButtonDiv = styled.div`
  position: relative;
  img {
    width: 30px;
  }
  &:hover {
    cursor: pointer;
    img {
      filter: contrast(200%);
    }
  }
`;

const CloseButton = (props: any) => {
  const { onClick } = props;
  return (
    <CloseButtonDiv>
      <img src={Close} onClick={onClick} />
    </CloseButtonDiv>
  );
};

export default CloseButton;
