import { WEB_CONSTANTS_KEYS } from "global-constants/web-constants";
import { getNumber } from "helpers/dispatchFunctions";
import { getSolarPanelQuantity } from "helpers/project-helper";
import { getSolarPanelProduction } from "helpers/solar-panel-production";
import store from "redux/store";
import { allComponentsCost } from "./all-components-cost";

export const getExtraCostForConstant = (constant: any) => {
  const state = store.getState();
  const proj = state.project;

  const priceKey = proj.isB2B ? "price_b2b" : "price_b2c";

  switch (constant.Type) {
    case WEB_CONSTANTS_KEYS.FIX_FOR_PROJECT:
      return getNumber(constant[priceKey]);

    case WEB_CONSTANTS_KEYS.DEPENDING_ON_KWH:
      const totalProd = getSolarPanelProduction();
      return (getNumber(constant[priceKey]) * totalProd) / 1000;

    case WEB_CONSTANTS_KEYS.FIX_PER_PANEL:
      const quantity = getSolarPanelQuantity(proj);
      return getNumber(constant[priceKey]) * quantity;

    case WEB_CONSTANTS_KEYS.PERCENTAGE_OF_PROJECT_COST:
      const cost = allComponentsCost(proj);
      return (getNumber(constant[priceKey]) * cost) / 100;
    default:
      alert("not found");
      return 0;
  }
};

export const additionalCostOfConstants = (project: any) => {
  let additionalCosts = 0;

  const constants = project?.webConstants?.constants;
  if (!constants) return 0;

  for (let constant of constants) {
    additionalCosts += getExtraCostForConstant(constant);
  }

  return Math.round(additionalCosts);
};
