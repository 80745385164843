import { makeStyles } from "@mui/styles";
import arrow from "components/vectors/Step4/arrow.svg";
import { t } from "i18next";
import {
  NextButton,
  PrevButton,
} from "pages/application/applicationSteps/step4/Installers/styles";

export const isCarAdded = (project: any): boolean => {
  let result = false;
  for (let index = 0; index < project.components.items.cars.length; index++) {
    if (
      project.components.items.cars[index]?.item?.hasOwnProperty("isLeasing")
    ) {
      if (project.components.items.cars[index]?.item?.isLeasing === true)
        return true;
    }
  }
  return result;
};

export const consumption_profiles: any = {
  1: {
    name: "Home Office - all day high consumption",
    self_supply: 0.6,
    short_title: "home office",
  },
  2: {
    name: "consumption in the morning and evening",
    self_supply: 0.3,
    short_title: "Morning and evening",
  },
  3: {
    name: "only in the evening",
    self_supply: 0.25,
    short_title: "Only evening",
  },
  4: {
    name: "Multi family house",
    self_supply: 0.6,
    short_title: "Multi family house",
  },
  5: {
    name: "Family with children in schooling age",

    self_supply: 0.5,
    short_title: "Family w. children",
  },
  6: {
    name: "Family with small children",

    self_supply: 0.55,
    short_title: "Family w. small children",
  },
  7: {
    name: "Single or two person household",

    self_supply: 0.55,
    short_title: "Single / two Person household",
  },
  8: {
    name: "Home Office / Retired home",
    self_supply: 0.55,
    short_title: "Home Office",
  },
  9: {
    name: "High energy consumption at night",
    self_supply: 0.55,
    short_title: "High conusmption at night",
  },
};

export const dropDownList = [
  {
    key: "Passivhaus 0.015 kW/m²",
    label: "Passiv home 0.015 kW/m²",
    value: 0.015,
  },
  {
    key: "Neubau nach EnEV 0.015 kW/m²",
    label: "Neu building according to EnEV 0.015 kW/m²",
    value: 0.015,
  },
  {
    key: "Neubau mit Standardwärmedämmung 0.06 kW/m²",
    label: "New building with standard heat insulation 0.06 kW/m²",
    value: 0.06,
  },
  {
    key: "Sanierter Altbau mit oder Neubau ohne Wärmedämmung 0.08 kW/m²",
    label:
      "Sanified old building or new building without insulation 0.08 kW/m²",
    value: 0.08,
  },
  {
    key: "Altbau ohne Wärmedämmung 0.12 kW/m²",
    label: "Old building without insulation 0.12 kW/m²",
    value: 0.12,
  },
];

export const dropDownListForBuldings = [
  {
    key: "Radiatoren (wandhängend)",
    label: "Radiators (wall mount)",
  },
  {
    key: "Radiatoren (frei stehend)",
    label: "Radiators (free standing)",
  },
  {
    key: "Flächenheizung (Fußboden-, Wand-, Deckenheizung)",
    label: "Panel heating (floor-, wall-, roof heating)",
  },
  {
    key: "Beides",
    label: "Both",
  },
];

export const returnStyle = (remember_length: number) => {
  return {
    width:
      remember_length == 4
        ? "95px"
        : remember_length == 5
        ? "100px"
        : remember_length == 6
        ? "107px"
        : remember_length == 7
        ? "113px"
        : remember_length == 8
        ? "118px"
        : remember_length == 9
        ? "124px"
        : remember_length == 10
        ? "129px"
        : remember_length == 11
        ? "134px"
        : "87px",
  };
};

export const returnStyleForKwhm2 = (remember_length: number) => {
  return {
    width:
      remember_length == 2
        ? "103px"
        : remember_length == 3
        ? "111px"
        : remember_length == 4
        ? "95px"
        : remember_length == 5
        ? "100px"
        : remember_length == 6
        ? "107px"
        : remember_length == 7
        ? "113px"
        : remember_length == 8
        ? "118px"
        : remember_length == 9
        ? "124px"
        : remember_length == 10
        ? "129px"
        : remember_length == 11
        ? "134px"
        : "100px",
  };
};

export const returnStyleForKm = (remember_length: number) => {
  return {
    width:
      remember_length == 4
        ? "85px"
        : remember_length == 5
        ? "90px"
        : remember_length == 6
        ? "97px"
        : remember_length == 7
        ? "103px"
        : remember_length == 8
        ? "108px"
        : remember_length == 9
        ? "114px"
        : remember_length == 10
        ? "119px"
        : remember_length == 11
        ? "124px"
        : "87px",
  };
};

export const returnStyleForKmHeat = (remember_length: number) => {
  return {
    width:
      remember_length == 4
        ? "85px"
        : remember_length == 5
        ? "90px"
        : remember_length == 6
        ? "97px"
        : remember_length == 7
        ? "103px"
        : remember_length == 8
        ? "108px"
        : remember_length == 9
        ? "114px"
        : remember_length == 10
        ? "119px"
        : remember_length == 11
        ? "124px"
        : "77px",
  };
};

export const returnStyleForM2 = (remember_length: number) => {
  return {
    width:
      remember_length == 2
        ? "67px"
        : remember_length == 3
        ? "78px"
        : remember_length == 6
        ? "87px"
        : remember_length == 7
        ? "103px"
        : remember_length == 8
        ? "108px"
        : remember_length == 9
        ? "114px"
        : remember_length == 10
        ? "119px"
        : remember_length == 11
        ? "124px"
        : "60px",
  };
};
export const returnStyleForSmallKwh = (remember_length: number) => {
  return {
    width:
      remember_length == 2
        ? "82px"
        : remember_length == 3
        ? "90px"
        : remember_length == 4
        ? "100px"
        : remember_length == 5
        ? "110px"
        : remember_length == 6
        ? "120px"
        : remember_length == 7
        ? "130px"
        : remember_length == 8
        ? "108px"
        : remember_length == 9
        ? "114px"
        : remember_length == 10
        ? "119px"
        : remember_length == 11
        ? "124px"
        : "87px",
  };
};
export const returnStyleForTwoNumber = (remember_length: number) => {
  return {
    width: remember_length == 1 ? "73px" : "83px",
  };
};

export function compare(a: any, b: any) {
  if (a.distance < b.distance) {
    return -1;
  }
  if (a.distance > b.distance) {
    return 1;
  }
  return 0;
}

export function compareSec(a: any, b: any) {
  if (a.admin_rating > b.admin_rating) {
    return -1;
  }
  if (a.admin_rating < b.admin_rating) {
    return 1;
  }
  return 0;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: "70vw",
    maxWidth: "810px",
    "@media (max-width: 560px)": {
      width: "328px",
      marginLeft: "16px",
    },
  },
  scroll_container: {
    display: "flex",
    overflow: "hidden",
    width: "810px",
    minWidth: "270px",
    "@media (max-width: 560px)": {
      width: "270x",
    },
  },
  arrow_icon: {
    fontSize: "50px",
    "&:hover": {
      cursor: "pointer",
    },
    "@media (max-width: 560px)": {
      fontSize: "25px",
    },
  },
}));

export function SampleNextArrow(props: any) {
  const { className, style, ownStyles, onClick } = props;
  const classes = useStyles();
  return (
    <NextButton
      onClick={onClick}
      style={{
        ...style,
        ...ownStyles,
        display: onClick === null ? "none" : "block",
        position: "absolute",
        right: window.innerWidth > 500 ? "-56px" : "-38px",
        zIndex: 500,
      }}
    >
      <img src={arrow} alt="" />
    </NextButton>
  );
}

export function SamplePrevArrow(props: any) {
  const { className, style, ownStyles, onClick } = props;
  const classes = useStyles();
  return (
    <PrevButton
      onClick={onClick}
      className={classes.arrow_icon}
      style={{
        ...style,
        ...ownStyles,
        display: onClick === null ? "none" : "block",
        position: "absolute",
        left: window.innerWidth > 500 ? "-56px" : "-38px",
        zIndex: 500,
      }}
    >
      <img src={arrow} alt="" />
    </PrevButton>
  );
}

export const generateSettings = (width: number) => {
  return {
    accessibility: true,
    speed: 600,
    slidesToShow: width >= 1000 ? 3 : width >= 750 ? 2 : 1,
    slidesToScroll: width >= 1000 ? 3 : width >= 750 ? 2 : 1,
    infinite: width >= 1000 ? true : false,
    dots: width >= 800 ? true : false,
    // afterChange: function (currentSlide) {
    // },
    beforeChange: function (prev: any, next: any) {},
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 3,
    //       slidesToScroll: 3,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 2,
    //       initialSlide: 2,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };
};
export const settings = {};

export const feed_In_Details: any = [
  {
    capacity: "<10",
    feed_in_tariff: 0.086,
    Full_Feed_in: 0.134,
    direct_sale_to_3rd_party: 0.09,
  },
  {
    capacity: "10<40",
    feed_in_tariff: 0.075,
    Full_Feed_in: 0.113,
    direct_sale_to_3rd_party: 0.085,
  },
  {
    capacity: ">40",
    feed_in_tariff: 0.062,
    Full_Feed_in: 0.113,
    direct_sale_to_3rd_party: 0.085,
  },
  {
    capacity: ">100",
    feed_in_tariff: 0.062,
    Full_Feed_in: 0.113,
    direct_sale_to_3rd_party: 0.085,
  },
  {
    capacity: ">300",
    feed_in_tariff: 0.062,
    Full_Feed_in: 0.107,
    direct_sale_to_3rd_party: 0.085,
  },
  {
    capacity: ">750",
    feed_in_tariff: 0.062,
    Full_Feed_in: 0.062,
    direct_sale_to_3rd_party: 0.085,
  },
];

export const whichPathToShow: Array<string> = [
  "home",
  "popular",
  "notification",
  "faq",
  "useroverview",
  "knowledgehub",
  "community/saved",
  "profile",
  "feedback",
  "payment",
];
export const whichPathToShow2: Array<string> = [
  "home",
  "popular",
  "notification",
  "faq",
  "useroverview",
  "knowledgehub",
  "community/saved",
  "profile",
  "feedback",
  "payment",
  "default",
  "custom",
  "saved",
];
export const returnIsPathIsThere = (path: string) => {
  let returnThing = false;
  whichPathToShow.map((data) => {
    if (path.includes(data)) {
      return (returnThing = true);
    }
  });
  return returnThing;
};
export const returnIsPathIsThere2 = (path: string) => {
  return whichPathToShow2.some((pathSubString) => path.includes(pathSubString));
};
export const filterArrayValue: Array<string> = [
  t("Folded plate roof"),
  t("Trapezoidal Profiles"),
  t("Gable roof"),
  t("Hip roof"),
  t("Tent roof"),
  t("Arched roof"),
  t("Flat roof"),
  t("Single pitch roof"),
  t("Half hipped roof"),
  t("Wall dormer"),
];
