import React from "react";

const DownArrow = (props: any) => {
  const { className, style } = props;

  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={{
        top: "calc(50% - 5px)",
        transition: "all 200ms",
        ...style,
      }}
    >
      <path
        d="M1.5 0L0 1.33333L7.49999 8L15 1.33336L13.5 2.74235e-05L7.50001 5.33332L1.5 0Z"
        fill="#2D4764"
      />
    </svg>
  );
};

export default DownArrow;
