import { getNumber } from "./dispatchFunctions";

/**
 * Return if project is B2C or not
 * @param proj Project
 * @returns true | false
 */
export const getIsProjectB2C = (proj: any) => !proj.isB2B;

/**
 * If user has entered_consumption it will return true or else it will return false
 * @param proj Project
 * @returns entered_consumption
 */
export const getIsYearlyConsumptionCustomEntered = (proj: any) => {
  if (getIsProjectB2C(proj)) {
    return proj.consumption_details.entered_consumption;
  } else {
    return proj.consumption_details_b2b.entered_consumption;
  }
};

export const getSolarPanelQuantity = (proj: any) => {
  return proj?.components?.items.solar_panel?.quantity;
};

export const getSolarPanelCapacity = (proj: any) => {
  return getNumber(proj?.components?.items?.solar_panel?.item?.capacity);
};
