// @ts-ignore
import { sendEmailForSavedProject_en } from "api/email/sentEmail";
import firebase, { db } from "firebase-global";
import * as geofire from "geofire-common";
import { costsAndSavings, energyDetails } from "helpers/costs";
import { buildFinancialObject } from "helpers/database-object-creators/financial-details-obj";
import { getCurrentUser, getProjectDetails } from "helpers/Selector";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { initialState as ProjectState } from "redux/project/initialState";
import { RootState } from "redux/store";
export const useProjectTools = () => {
  const { t } = useTranslation();
  let project = useSelector(getProjectDetails);
  const quote = useSelector((state: RootState) => state.quote);
  const currentUser = useSelector(getCurrentUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname } = location;

  const setLoading = (data: any) =>
    dispatch({ type: "SET_QUOTE_FEATCHING", payload: data });
  const setProject = (data: any) =>
    dispatch({ type: "SET_PROJECT", payload: data });
  const save = (
    direct_uid: any,
    redirect: any,
    isPayment: boolean,
    payment?: typeof ProjectState
  ) => {
    if (payment != null) {
      project = payment;
    }
    let slug = pathname.substring(13);
    if (project?.quoteStatus === "default") {
      // toast.error(<OpenInSave />);
      dispatch({ type: "SET_USER_ADRESS_STATUS", payload: true });
      return;
    }
    if (project?.quoteStatus === "notFound") {
      toast.error(
        t(
          "Please wait for sometime. We are creating the project for your address -Please check back after some time"
        )
      );
      return;
    }
    if (slug?.includes("saved")) {
    } else {
      setLoading(true);
    }
    const financialDetails = buildFinancialObject(project);
    const energysDetails = energyDetails(project);
    const costSaving = costsAndSavings(project);
    let versionFromUrl = slug?.substr(-2);
    if (currentUser || direct_uid) {
      if (!slug?.includes("saved")) {
        versionFromUrl = "v1";
      }
      const uid = currentUser?.uid || direct_uid;
      const docRef = db
        .collection("saved_projects")
        .doc(`${uid}${quote?.quote.qrn}${versionFromUrl}`);
      docRef
        .get()
        .then((doc) => {
          if (doc.exists) {
            try {
              docRef
                .set({
                  ...doc.data(),
                  project_details: {
                    ...project,
                    pid: uid + quote?.quote.qrn + versionFromUrl
                  },
                  uid: uid,
                  qrn: quote?.quote.qrn,
                  crn: quote?.quote?.crn,
                  pid: uid + quote?.quote.qrn + versionFromUrl,
                  projectImgUrl: quote?.quote?.img_url,
                  financialDetails,
                  energysDetails,
                  costSaving,
                  updatedData: {
                    roof_type: quote?.quote?.roof_type || "Flat roof",
                    roof_material: quote?.quote?.roof_material || "metal roof",
                    roof_tilt: quote?.quote?.roof_tilt || "45°",
                    roof_orientation:
                      quote?.quote?.roof_orientation || "South orientation"
                  },
                  version: versionFromUrl,
                  geohash: geofire.geohashForLocation([
                    parseFloat(
                      quote?.quote?.lat
                        ? quote?.quote?.lat
                        : quote?.quote?.visibleMap?.center?.lat
                    ),
                    parseFloat(
                      quote?.quote?.long
                        ? quote?.quote?.long
                        : quote?.quote?.visibleMap?.center?.lng
                    )
                  ]),
                  lat: quote?.quote?.lat
                    ? quote?.quote?.lat
                    : quote?.quote?.visibleMap?.center?.lat,
                  long: quote?.quote?.long
                    ? quote?.quote?.long
                    : quote?.quote?.visibleMap?.center?.lng,
                  updatedAt: firebase.firestore.FieldValue.serverTimestamp()
                })
                .then((doc) => {
                  setLoading(false);
                  if (slug?.includes("saved")) {
                  } else {
                    toast.success(t("Project succesfully saved"));
                  }
                  if (redirect) {
                    localStorage.clear();
                    const setUrl =
                      "/application/saved-" +
                      uid +
                      quote?.quote.qrn +
                      versionFromUrl;
                    localStorage.setItem("goBackUrl", setUrl);
                    dispatch({ type: "SET_BAK_TO_STEP_FOUR", payload: false });
                    navigate(
                      `/useroverview/${
                        uid + quote?.quote.qrn + versionFromUrl
                      }`,
                      {
                        state: {
                          qrn: quote?.quote.qrn,
                          projectId: uid + quote?.quote.qrn + versionFromUrl
                        }
                      }
                    );
                  } else {
                    if (
                      (!isPayment &&
                        window.location.pathname.includes("saved")) ||
                      window.location.pathname.includes("default")
                    ) {
                      navigate(
                        "/application/saved-" +
                          uid +
                          quote?.quote.qrn +
                          versionFromUrl
                      );
                    } else {
                      localStorage.setItem(
                        "goBackUrl",
                        "/application/saved-" +
                          uid +
                          quote?.quote.qrn +
                          `${versionFromUrl}`
                      );
                    }
                  }
                  // dispatch({ type: "SET_DIREACT_REDIRECT", payload: false });
                })
                .catch((e) => console.error(e.message));
            } catch (err) {
              console.log(err);
              console.log("Inside the useProjectTools");
              console.log(project);
              alert(err);
            }
          } else {
            db.collection("saved_projects")
              .where("version", "==", "v1")
              .orderBy("sortedDate", "desc")
              .limit(1)
              .get()
              .then((snapshot) => {
                snapshot.forEach((doc) => {
                  let beforeNumber = parseInt(
                    doc.data().short_id.split("-")[0]
                  );
                  docRef
                    .set({
                      project_details: {
                        ...project,
                        pid: uid + quote?.quote.qrn + versionFromUrl
                      },
                      uid: uid,
                      qrn: quote?.quote.qrn,
                      crn: quote?.quote?.crn,
                      short_id: `${beforeNumber + 1}-${versionFromUrl}`,
                      pid: uid + quote?.quote.qrn + versionFromUrl,
                      projectImgUrl: quote?.quote?.img_url,
                      financialDetails,
                      energysDetails,
                      costSaving,
                      updatedData: {
                        roof_type: quote?.quote?.roof_type || "Flat roof",
                        roof_material:
                          quote?.quote?.roof_material || "metal roof",
                        roof_tilt: quote?.quote?.roof_tilt || "45°",
                        roof_orientation:
                          quote?.quote?.roof_orientation || "South orientation"
                      },
                      version: versionFromUrl,
                      geohash: geofire.geohashForLocation([
                        parseFloat(
                          quote?.quote?.lat
                            ? quote?.quote?.lat
                            : quote?.quote?.visibleMap?.center?.lat
                        ),
                        parseFloat(
                          quote?.quote?.long
                            ? quote?.quote?.long
                            : quote?.quote?.visibleMap?.center?.lng
                        )
                      ]),
                      lat: quote?.quote?.lat
                        ? quote?.quote?.lat
                        : quote?.quote?.visibleMap?.center?.lat,
                      long: quote?.quote?.long
                        ? quote?.quote?.long
                        : quote?.quote?.visibleMap?.center?.lng,
                      createdAt:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      updatedAt:
                        firebase.firestore.FieldValue.serverTimestamp(),
                      sortedDate: new Date().getTime() / 1000
                    })
                    .then((doc) => {
                      if (slug?.includes("saved")) {
                      } else {
                        toast.success(t("Project succesfully saved"));
                        const letUrl =
                          "/application/saved-" +
                          uid +
                          quote?.quote.qrn +
                          versionFromUrl;
                        sendEmailForSavedProject_en(
                          project,
                          currentUser,
                          letUrl
                        );
                        setLoading(false);
                      }
                      if (redirect) {
                        localStorage.clear();
                        const setUrl =
                          "/application/saved-" +
                          uid +
                          quote?.quote.qrn +
                          versionFromUrl;
                        localStorage.setItem("goBackUrl", setUrl);
                        dispatch({
                          type: "SET_BAK_TO_STEP_FOUR",
                          payload: false
                        });
                        navigate(
                          `/useroverview/${
                            uid + quote?.quote.qrn + versionFromUrl
                          }`,
                          {
                            state: {
                              qrn: quote?.quote.qrn,
                              projectId: uid + quote?.quote.qrn + versionFromUrl
                            }
                          }
                        );
                      } else {
                        if (
                          (!isPayment &&
                            window.location.pathname.includes("saved")) ||
                          window.location.pathname.includes("default")
                        ) {
                          navigate(
                            "/application/saved-" +
                              uid +
                              quote?.quote.qrn +
                              versionFromUrl
                          );
                        } else {
                          localStorage.setItem(
                            "goBackUrl",
                            "/application/saved-" +
                              uid +
                              quote?.quote.qrn +
                              `${versionFromUrl}`
                          );
                        }
                      }
                      dispatch({
                        type: "SET_DIREACT_REDIRECT",
                        payload: false
                      });
                    })
                    .catch((e) => console.error(e.message));
                });
              })
              .catch((err) => console.log(err));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setLoading(false);
    }
  };

  const fetchConstants = () => {
    const country = "germany";
    db.collection("other_details/webConstants/germany")
      .get()
      .then((snap) => {
        let data: any = [];
        snap.forEach((doc) => {
          data.push(doc.data());
        });
        if (
          quote?.status === "default" ||
          quote?.status === "notFound" ||
          quote?.status === "matched"
        ) {
          setProject({
            ...project,
            quoteStatus: quote?.status,
            webConstants: { country: country, constants: data }
          });
          const url = window.location.href;
          if (url.includes("b2b") && url.includes("default")) {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2b" });
          } else {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2c" });
          }
        } else {
          setProject({
            ...project,
            webConstants: { country: country, constants: data }
          });
          const url = window.location.href;
          if (url.includes("b2b") && url.includes("default")) {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2b" });
          } else {
            dispatch({ type: "SET_PROJECTTYPE", payload: "b2c" });
          }
        }
      })
      .catch((e) => alert(e));
  };

  const fetchSingleConstants = () => {
    db.collection("constants")
      .doc("webConstants")
      .get()
      .then((doc) => {
        dispatch({ type: "SET_CALCULATION_CONSTANT", payload: doc.data() });
      });
  };

  const isWebinarLive = () => {
    return new Promise((resolve, reject) => {});
  };

  return { save, fetchConstants, fetchSingleConstants };
};
