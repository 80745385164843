import { CircularProgress, TextField } from "@mui/material";
import { logInWithEmailPassword } from "api/auth";
import MasterCheckbox from "components/atoms/MasterCheckbox";
import { getAuthPopup, getProjectDetails } from "helpers/Selector";
import { useProjectTools } from "hooks/useProjectTools";
import { Mixpanel } from "mixpanel";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setPopupData } from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import utilCssClasses from "styles/util.module.css";
import * as trackingEvents from "trackingEvents";
import AuthButtons from "../authModeButtons/authButtons";
import cssClasses from "./login.module.css";

const LoginForm = () => {
  const { t } = useTranslation();
  const disptach = useDispatch();
  const { save } = useProjectTools();
  const authPopupState = useSelector(getAuthPopup);
  const project = useSelector(getProjectDetails);
  const navigate = useNavigate();
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const submitHandler = async () => {
    setLoading(true);
    Mixpanel.track(trackingEvents.USER_LOGIN, {});
    await logInWithEmailPassword(loginForm, save, project, navigate);
    setLoading(false);
  };

  const updateForm = (target: any, value: any, prev: any) => {
    const newForm: any = { ...prev };

    if (target === "email") {
      newForm.email = value;
    } else if (target === "password") {
      newForm.password = value;
    }

    return newForm;
  };

  const goToSignUP = () => {
    let nextMode = AUTH_MODES.SIGN_UP;
    if (authPopupState.data.authMode == AUTH_MODES.WEBINAR_LOG_IN)
      nextMode = AUTH_MODES.WEBINAR_SIGN_UP;
    disptach(
      setPopupData(POPUPS.AUTH_POPUP, {
        authMode: nextMode,
      })
    );
  };

  return (
    <>
      <div className={cssClasses.loginContainer}>
        <TextField
          fullWidth
          id="email-form-field"
          label={t("Email")}
          variant="outlined"
          onChange={(e: any) => {
            setLoginForm((prev: any) =>
              updateForm("email", e.target.value, prev)
            );
          }}
        />

        <TextField
          fullWidth
          id="password-form-field"
          label={t("Password")}
          type="password"
          variant="outlined"
          onChange={(e: any) => {
            setLoginForm((prev: any) =>
              updateForm("password", e.target.value, prev)
            );
          }}
        />

        <div className={cssClasses.loginButtonRow}>
          <MasterCheckbox>{t("REMEMBER LOGIN")}</MasterCheckbox>
          <div
            className={utilCssClasses.link}
            onClick={() => {
              disptach(
                setPopupData(POPUPS.AUTH_POPUP, {
                  authMode: AUTH_MODES.FORGOT_PASSWORD,
                })
              );
            }}
          >
            {t("FORGOT PASSWORD")}
          </div>
        </div>
      </div>

      <button
        className={cssClasses.loginButton}
        onClick={submitHandler}
        disabled={loading}
      >
        {t("LOGIN")}
        {loading && (
          <CircularProgress size={20} className="circular-progress-custom" />
        )}
      </button>

      <AuthButtons />

      <div className={cssClasses.formFooter}>
        <div className={cssClasses.blueLinkText} onClick={goToSignUP}>
          {t("YOU DONT HAVE AN ACCOUNT")}
        </div>
        <div className={cssClasses.yellowLinkText} onClick={goToSignUP}>
          {t("REGISTER NOW")}
        </div>
      </div>
    </>
  );
};

export default LoginForm;
