import styled from "styled-components/macro";

interface MainHeaderItemProps {
  width: any;
  height: any;
  disabled: any;
}
export const MainContainer = styled.div<MainHeaderItemProps>`
  background: #ffd75d;
  border-radius: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props) => {
    if (props.width) {
      return props.width;
    }
  }};
  height: ${(props) => {
    if (props.width) {
      return props.height;
    }
  }};
  font-family: "Nunito";
  pointer-events: ${({ disabled }) => {
    if (disabled) {
      return "none";
    } else {
      return "auto";
    }
  }};
  cursor: ${({ disabled }) => {
    if (disabled) {
      return "none";
    } else {
      return "pointer";
    }
  }};
  opacity: ${({ disabled }) => {
    if (disabled) {
      return "0.7";
    } else {
      return "none";
    }
  }};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: #2d4764;
`;
const MainButton = ({ height, width, text, onClick, disabled }: any) => {
  return (
    <MainContainer
      height={height}
      width={width}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </MainContainer>
  );
};

export default MainButton;
