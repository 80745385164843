import { DealDetail, HubspotDeals } from "db-types/types";
import * as actionType from "./actions";

const INITIAL_STATE: HubspotDeals = {
  currentDeal: null,
  hubspotDeals: []
};

const hubspotDealsReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case actionType.SET_HUBSPOT_DEALS: {
      return {
        ...state,
        hubspotDeals: [...state.hubspotDeals, action.payload]
      };
    }
    case actionType.UPDATE_HUBSPOT_DEAL: {
      const deals = state.hubspotDeals.map((data: DealDetail) => {
        if (data.dealId === action.payload.id) {
          return action.payload.updatedDeal;
        } else {
          return { ...data };
        }
      });
      return {
        ...state,
        hubspotDeals: deals
      };
    }
    case actionType.SET_CURRENT_DEAL: {
      return {
        ...state,
        currentDeal: action.payload
      };
    }
    case actionType.SET_ALL_DEALS_FROM_DATABASE: {
      return {
        ...state,
        hubspotDeals: action.payload
      };
    }
    default:
      return state;
  }
};

export default hubspotDealsReducer;
