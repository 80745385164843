import axios from "axios";

export const getAdressResults = async (value: any) => {
  try {
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json/?access_token=pk.eyJ1IjoiY2hlbHVkbyIsImEiOiJjazRhMWY0eG8wMHFiM2ttdXk2cnEwMXNoIn0.qGONrbtgEqaTGD5beE7KBw&country=de&types=address&limit=5`;
    const result: any = await axios.get(url);
    return result;
  } catch (error) {
    console.log(error);
  }
};
