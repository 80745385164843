import { Button, Container, TextField } from "@mui/material";
import firebase from "firebase-global";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { closePopup, setPopupData } from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import styled from "styled-components/macro";
import { colors } from "utils/colors";
import { styles } from "../SignUpSignIn/signup.popup.styles";

const ForgotPasswordWrapper = styled("div")`
  width: 100%;
  .loginContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem !important;
    margin-inline: 2rem;
    align-self: stretch;
  }
  .loginButton {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 350px;
    height: 44px;
    background: var(--primary-color);
    border-radius: 5px;
    /* font-family: "Roboto"; */
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: var(--blue);
    cursor: pointer;
    margin-top: 20px;
    :hover {
      background: var(--primary-color);
    }
  }
`;

const ForgotPassword = () => {
  const { t } = useTranslation();
  const classes = styles();
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const forgetPassword = () => {
    if (email === "") {
      toast.error("Please enter your Email");
      return;
    }
    var actionCodeSettings = {
      url: window.location.origin,
      handleCodeInApp: true,
    };
    firebase
      .auth()
      .sendPasswordResetEmail(email, actionCodeSettings)
      .then((result) => {
        toast.success(`${t("Email message")}`);
        dispatch(closePopup(POPUPS.AUTH_POPUP));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <ForgotPasswordWrapper>
      <Container maxWidth="xs">
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className={classes.accountH1}>
            <span>{t("Enter your Registered Email")}</span>
          </h3>
        </div>
        <div style={{ color: "#fff" }} className="loginContainer">
          <TextField
            fullWidth
            id="email-form-field"
            label="Email"
            variant="outlined"
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            fullWidth
            variant="contained"
            className="loginButton"
            onClick={forgetPassword}
          >
            {t("Forget Password")}
          </Button>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h3 className={classes.accountH1}>
            <span>{t("Have an Account?")}</span>{" "}
            <span
              style={{
                color: colors.ctaSolid,
                textDecoration: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                dispatch(
                  setPopupData(POPUPS.AUTH_POPUP, {
                    authMode: AUTH_MODES.LOG_IN,
                  })
                );
              }}
            >
              {t("Login Now")}
            </span>
          </h3>
        </div>
      </Container>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
