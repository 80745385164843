import { defaultModelState } from 'prediction-model/utility/default-model-values';

export const initialState = {
	pid: 'project123',
	qrn: null as any,
	address: null as any,
	roofPitch: 30.0,
	isB2B: false,
	isNewUser: true,
	isProjectSet: false,
	project_type: 'b2c',
	autarky_level: 0,
	components: {
		items: {
			solar_panel: {
				item: null as any,
				quantity: 0,
				isExcludeCost: false as boolean,
				extended_warranty: false
			},
			inverter: {
				item: null as any,
				quantity: 0,
				isExcludeCost: false as boolean,
				extended_warranty: false
			},
			battery: {
				item: null as any,
				quantity: 0,
				isExcludeCost: false as boolean,
				extended_warranty: false,
				batteryKwh: null
			},
			wallbox: {
				item: null as any,
				quantity: 0,
				isExcludeCost: false as boolean,
				extended_warranty: false
			},
			heatpump: {
				item: null as any,
				quantity: 0,
				isExcludeCost: false as boolean,
				extended_warranty: false
			},
			cars: [] as any
		},
		additional_componenents: {
			mounting_system: { item: null as any, priced_per_kwp: true },
			lightning_protecter: { item: null as any, priced_per_kwp: false },
			data_visualizer: { item: null as any, priced_per_kwp: false },
			energy_meter: { item: null as any, priced_per_kwp: false },
			construction_stage: { item: null as any, priced_per_kwp: false }
		},
		additional_chargee: {
			installation: { item: null as any, priced_per_kwp: true },
			electro_installation: { item: null as any, priced_per_kwp: true },
			on_site_visit: { item: null as any, priced_per_kwp: false },
			documentation: { item: null as any, priced_per_kwp: false }
		},
		costs: {
			total_costs: 0
		}
	},
	profile: 'Green',
	project_images: {
		house_top: {
			type: 'house_top',
			total: [],
			title: {
				english: '1. House/roof area - detail images & description ',
				german: '1. Haus / Dachfläche - Detailbilder & Beschreibung'
			},
			options: [
				{ english: 'Before 1980', german: 'Vor 1998' },
				{ english: 'After 1980', german: 'nach 1980' },
				{ english: 'After 2010', german: 'nach 2010' }
			],
			selectedOption: 'Before 1980',
			selectedOptionGerman: 'Vor 1980',
			description: ''
		},
		house_bottom: {
			type: 'house_bottom',
			total: [],
			title: {
				english: '2. Roof from below - detail images & description',
				german: '2. Dach von unten - Detailbilder & Beschreibung'
			},
			options: [
				{ english: 'Before 1980', german: 'Vor 1980' },
				{ english: 'After 1980', german: 'nach 1980' },
				{ english: 'After 2010', german: 'nach 2010' }
			],
			selectedOption: 'Before 1980',
			selectedOptionGerman: 'Vor 1980',
			description: ''
		},
		electric_meter: {
			type: 'electric_meter',
			total: [],
			title: {
				english: '3. Electric meter - detail images & description',
				german: '3. Stromzähler - Detailbilder & Beschreibung '
			},
			options: [
				{
					value: 'Digital',
					english: 'Digital meter',
					german: 'digitales Messgerät'
				},
				{
					value: 'Analog',
					english: 'Analog meter',
					german: 'analoges Messgerät'
				}
			],
			selectedOption: 'Digital meter',
			selectedOptionGerman: 'digitales Messgerät',
			description: ''
		}
	} as any,
	components_parts: {
		heatPump: {
			consumption_heatpump: 0,
			total_area_to_be_heated: 100,
			energy_demand: 150,
			building_standard: 0.015,
			heat_distribution: 'Radiators (wall mount)',
			oil_gas_cost: 0.15,
			heating_capacity: 0,
			electricity_cost_heatpump: 0,
			heat_load_building: 0,
			blocking_times: 4,
			heatpump_dimension: 0,
			solarpower_for_heatpump: 50
		},
		wallBox: {
			ev_kilometers_travelled: 5000,
			consumption_per_100km: 18,
			solarpower_for_vehicle: 50,
			ev_yearly_consumption: 0,
			building_standard: 0.015,
			electricity_cost_per_100km: 18 * 0.3,
			gas_price_per_100km: 14
		}
	},
	total_steps_completed_index: 0,
	consumption: 0,
	energy_details: {
		total_production: '',
		consumption: '',
		feed_into_grid: ''
	},
	consumption_details: {
		entered_consumption: null as any,
		building_type: 'single',
		people: 1,
		yearly_kms: 10000,
		isHeatPump: false,
		isWaterHeating: false,
		isCarChanrgeDuringSunshine: false,
		electroMobilityType: 'type 3',
		consumption_profile: 1,
		price_per_kWh: 0.35,
		self_supply: 0.6
	},
	consumption_details_b2b: {
		entered_consumption: null as any,
		business_type: 'Office',
		isCarChanrgeDuringSunshine: false,
		no_of_employees: 0,
		kwa_per_employee: 0,
		electroMobilityType: 'type 3',
		yearly_kms: 10000,
		no_of_cars: 18,
		kwa_per_100km: 180,
		heatPumpType: 'type 3',
		surface_area: 1000,
		kwa_per_surface_area: 5,
		consumption_profile: 1,
		price_per_kWh: 0.35,
		self_supply: 0.8
	},
	costs_and_savings: {
		electricity_price: '0.3',
		estimated_yearly_price_increase: '5',
		electricity_cost_before: '',
		feedin_savings: '',
		total_savings: ''
	},
	finacing_datails: {
		total_loan_amount: '',
		duration_in_years: '',
		total_interest: '',
		total_financing_amount: '',
		yearly_financing_amount: ''
	},
	vendor: { suggested: [], selected: [], freeChoiceInstaller: false },
	payment: {
		status: 'pending', //pending, inProcess, done
		email: '',
		name: '',
		paymentIntent: null as any
	},
	expectedReturnTime: 25,
	increaseRateByBank: 2,
	isInverterDone: true,
	productionByBatterySimulation: 0,
	FeedInTariff: 0.068,
	yearlyConsumption: 3500,
	increseCapacityFromAdminPanle: 0,
	Ev_yearly_consumption: 0,
	selectedFeedInReason: 'feed_in_tariff',
	reduceProductionDueToShade: 0,
	quoteStatus: 'default',
	isFinancing: false,
	web_constants_calulation: {
		JAZ: '3',
		feedInTarrif: '0.0595',
		'heat pump_share': '80',
		'heat spring_share': '40'
	},
	model: {
		...defaultModelState
	},
	webConstants: {
		constants: [
			{
				title: 'SolarHub Margin',
				price_b2c: '10',
				Type: 'percentage of project cost',
				price_b2b: '10'
			},
			{
				Type: 'Fix for project',
				price_b2b: '1000',
				price_b2c: '1000',
				title: 'Construction stage'
			},
			{
				title: 'Documentation',
				price_b2c: '250',
				price_b2b: '200',
				Type: 'Fix for project'
			},
			{
				price_b2c: '280',
				Type: 'Fix for project',
				price_b2b: '114',
				title: 'Overvoltage protector DC Typ I+II, 2 MPPT'
			},
			{
				price_b2c: '180',
				title: 'registration with grid operator',
				Type: 'Fix for project',
				price_b2b: '180'
			},
			{
				title: 'on-site-visit',
				Type: 'Fix for project',
				price_b2c: '250',
				price_b2b: '210'
			},
			{
				price_b2c: '32',
				price_b2b: '32',
				title: 'Racking System for gable roof',
				Type: 'Fix per panel'
			},
			{
				price_b2c: '25',
				title: 'Roof hook/ cross hook',
				Type: 'Fix per panel',
				price_b2b: '25'
			},
			{
				price_b2c: '1',
				Type: 'percentage of project cost',
				price_b2b: '1',
				title: '5 year warranty - 1% of project fee'
			},
			{
				title: 'Current electricity price',
				price_b2b: '0.25',
				Type: 'Fix per panel',
				price_b2c: '0.3'
			},
			{
				price_b2b: '162',
				Type: 'Fix for project',
				title: 'Delivery',
				price_b2c: '65'
			},
			{
				title: 'electro installation (incl. material)',
				Type: 'Fix for project',
				price_b2b: '1000',
				price_b2c: '1500'
			},
			{
				price_b2c: '0.0595',
				Type: 'Fix for project',
				price_b2b: '0.0595',
				title: 'Feed-in-tariff'
			},
			{
				price_b2b: '65',
				title: 'Installation',
				Type: 'Fix per panel',
				price_b2c: '65'
			},
			{
				Type: 'Fix for project',
				price_b2c: '600',
				price_b2b: '550',
				title: 'SMA Sunny Home Manager 2.0 (inkl. Energy Meter)'
			},
			{
				Type: 'Fix for project',
				title: 'Setup data visualizer SMA Sunny Portal',
				price_b2b: '97',
				price_b2c: '120'
			}
		] as any,
		country: 'germany'
	}
};

export type Project = typeof initialState;
