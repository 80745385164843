import { GoogleHandler } from "api/auth";
import { getProjectDetails } from "helpers/Selector";
import { useProjectTools } from "hooks/useProjectTools";
import { Mixpanel } from "mixpanel";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { AUTH_MODES } from "redux/popups/popups.reducer";
import { RootState } from "redux/store";
import * as trackingEvents from "trackingEvents";
import cssClasses from "./authButtons.module.css";
import Google from "./images/Google Logo.png";

const AuthButtons = ({
  setIsGoogleLogIn,
  setSubtitle,
  googleValidation,
  isGoogleLogin
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { save } = useProjectTools();
  const project = useSelector(getProjectDetails);
  const authPopupData = useSelector(
    (state: RootState) => state.popups.AUTH_POPUP
  );
  const logInWithGoogle = () => {
    if (
      authPopupData.data.authMode == AUTH_MODES.SIGN_UP ||
      authPopupData.data.authMode == AUTH_MODES.WEBINAR_SIGN_UP
    ) {
      setIsGoogleLogIn(true);
      setSubtitle(t("TEXT FOR GOOGLE LOGIN"));
      if (isGoogleLogin && googleValidation()) {
        return;
      }
      if (isGoogleLogin) {
        GoogleHandler("google.com", navigate, project, save);
        Mixpanel.track(trackingEvents.USER_LOGIN, {});
      }
    } else {
      Mixpanel.track(trackingEvents.USER_LOGIN, {});
      GoogleHandler("google.com", navigate, project, save);
    }
  };

  return (
    <div className={cssClasses.buttonContainer}>
      {/* <div className={[cssClasses.authButton, cssClasses.microsoft].join(" ")}>
        <div className={cssClasses.btnImgText}>
          <img src={Microsoft} alt="" />
          <div>Login with Microsoft</div>
        </div>
      </div> */}
      {/* <div className={[cssClasses.authButton, cssClasses.google].join(" ")}>
        <div className={cssClasses.btnImgText}>
          <img src={Apple} alt="" />
          <div>Login with Apple</div>
        </div>
      </div> */}

      {/* <div className={[cssClasses.authButton, cssClasses.google].join(" ")}>
        <div className={cssClasses.btnImgText}>
          <img src={Google} alt="" />
          <div onClick={logInWithGoogle}>{t("Login with Google")}</div>
        </div>
      </div> */}
      {/* <div className={[cssClasses.authButton, cssClasses.linkedIn].join(" ")}>
        <div className={cssClasses.btnImgText}>
          <img src={Linkedln} alt="" />
          <div>Login with LinkedIn</div>
        </div>
      </div> */}
    </div>
  );
};

export default AuthButtons;
