import { JoinedWebinar } from 'db-types/types';

export const setWebinars = (webinars: any) => ({
	type: 'SET_WEBINARS',
	payload: webinars
});

export const setSelectedWebinarDate = (dateAndTime: any) => ({
	type: 'SET_SELECTED_WEBINAR_DATE',
	payload: dateAndTime
});

export const setSelectedWebinarId = (webinarId: any) => ({
	type: 'SET_SELECTED_WEBINAR_ID',
	payload: webinarId
});

export const actionJoinWebinar = (shouldJoin: boolean) => ({
	type: 'JOIN_WEBINAR',
	payload: shouldJoin
});

export const setJoinedWebinar = (data: Array<JoinedWebinar>) => ({
	type: 'SET_JOINED_WEBINARS',
	payload: data
});

export const setLastJoinedWebinar = (data: JoinedWebinar) => ({
	type: 'SET_LAST_JOINED_WEBINAR',
	payload: data
});
