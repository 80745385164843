import meter1 from "assets/step3/meter1.png";
import meter2 from "assets/step3/meter2.png";
import { useTranslation } from "react-i18next";
import House1 from "../../../../../components/vectors/step3/House1.png";
import House2 from "../../../../../components/vectors/step3/House2.png";
import * as S from "./styles";
const Sample = ({ type }: any) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginTop: "10px" }}>
      <S.MainText1>{t("Sample images")}</S.MainText1>
      <S.SubContainer>
        <img src={type !== "electric_meter" ? House1 : meter1} />
        <img src={type !== "electric_meter" ? House2 : meter2} />
      </S.SubContainer>
      <S.ColContain />
    </div>
  );
};

export default Sample;
