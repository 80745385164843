import {
  getUserPreferredLanguageShort,
  LanguagesShortCodes,
} from "./utility/preferredLanguage";

const INITIAL_STATE = {
  language: getUserPreferredLanguageShort() as LanguagesShortCodes,
  isLoading: true,
  quoteFeatching: false,
  isProjectSaved: false,
  dummyProjectCount: 1,
  whichStyleForMap: true,
  isUserSelectingAdress: false,
  isDireactToSaveProject: true,
  isComingFromUserOverView: false,
  projectConfiguration: null,
  isTopicDeletedOrEdited: false,
  isPostDeletedOrEditedOrCreated: false,
  isStagingUser: false,
  goOnStepFour: false,
  lastSolarQuantity: false,
  isProjectSelectedAndFeatched: false,
  isSolarPanelMessageShow: false,
  previosSelfValue: 0.6,
  isQuoteIsFeatched: true,
  isCustomerReferral: false,
  isDireactSearch: {
    flag: false,
    category_id: null,
    direactId: null,
    title: "",
  },
  isDireactCategorySearch: {
    category_id: null,
    title: null,
  },
  customAutarkyChange: 0,
};

const languageReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.payload as LanguagesShortCodes,
      };
    case "SET_IS_PROJECT_SELECTED_AND_FEATCHED":
      return {
        ...state,
        isProjectSelectedAndFeatched: action.payload,
      };
    case "DUMMYPROJECT_PROJECT_ADDED":
      return {
        ...state,
        dummyProjectCount: state.dummyProjectCount + 1,
      };
    case "SET_IS_SOLAR_PANEL_MESSAGE_SHOW":
      return {
        ...state,
        isSolarPanelMessageShow: action.payload,
      };
    case "SET_DIREACT_SEARCH":
      return {
        ...state,
        isDireactSearch: action.payload,
      };
    case "SET_DIREACT_CATEGORY_SEARCH":
      return {
        ...state,
        isDireactCategorySearch: action.payload,
      };
    case "SET_IS_QUOTE_FEATCHED":
      return {
        ...state,
        isQuoteIsFeatched: action.payload,
      };
    case "SET_COMING_OVERVIEW":
      return {
        ...state,
        isComingFromUserOverView: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        isLoading: action.payload,
      };
    case "SET_USER_SELECTING_ADDRESS":
      return {
        ...state,
        isUserSelectingAdress: action.payload,
      };
    case "SET_PROJECT_SAVED_STATUS":
      return {
        ...state,
        isProjectSaved: action.payload,
      };
    case "SET_STYLE_FOR_MAP":
      return {
        ...state,
        whichStyleForMap: action.payload,
      };
    case "SET_DISCORUSE_STATE":
      return {
        ...state,
        isTopicDeletedOrEdited: !state.isTopicDeletedOrEdited,
      };
    case "SET_USER_PASSWORD":
      return {
        ...state,
        isStagingUser: action.payload,
      };
    case "SET_DISCORUSE_STATE_FOR_POST":
      return {
        ...state,
        isPostDeletedOrEditedOrCreated: !state.isPostDeletedOrEditedOrCreated,
      };
    case "SET_PROJECT_CONFIGURATION":
      return {
        ...state,
        projectConfiguration: action.payload,
      };
    case "SET_BAK_TO_STEP_FOUR":
      return {
        ...state,
        goOnStepFour: action.payload,
      };
    case "SET_QUOTE_FEATCHING":
      if (action.payload) console.time("[timers-full-app]");
      else console.timeEnd("[timers-full-app]");

      return {
        ...state,
        quoteFeatching: action.payload,
      };
    case "SET_DIREACT_REDIRECT":
      return {
        ...state,
        isDireactToSaveProject: action.payload,
      };
    case "SET_PREV_SELF_VALUE":
      return {
        ...state,
        previosSelfValue: action.payload,
      };
    case "SET_CUSTOMER_REFERRAL":
      return {
        ...state,
        isCustomerReferral: action.payload,
      };
    case "SET_LAST_PANEL_QUATITY":
      return {
        ...state,
        lastSolarQuantity: action.payload,
      };
    case "CUSTOM_AUTARKY_CHANGE":
      return {
        ...state,
        customAutarkyChange: state.customAutarkyChange + 1,
      };
    default:
      return state;
  }
};

export default languageReducer;
