import { useCallback, useEffect, useState } from "react";

import solarImage from "assets/images/solarImage.png";
import Logo from "components/vectors/header/logo";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closePopup, setPopupData } from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import { RootState } from "redux/store";
import ForgotPassword from "../forgotPassword/ForgotPassword";
import PopupSkeleton from "../popupSkeleton/popupSkeleton";
import cssClasses from "./auth.module.css";
import LoginForm from "./authForms/login";
import SignUpPopup from "./authForms/signup";
import ResetPassword from "./ResetPassword";

const AuthPopup = () => {
  const { t } = useTranslation();
  const disptach = useDispatch();

  const authPopupData = useSelector(
    (state: RootState) => state.popups.AUTH_POPUP
  );

  const [authMode, setAuthMode] = useState(AUTH_MODES.SIGN_UP);
  const [title, setTitle]: [any, any] = useState();
  const [subtitle, setSubtitle] = useState();

  const generateTitle = useCallback((title) => {
    return (
      <>
        <span className={cssClasses.redDot}></span>
        {title + " " + t("and") + " " + t("JOIN OUR WEBINAR")}
      </>
    );
  }, []);

  useEffect(() => {
    const mode = authPopupData.data.authMode;
    setAuthMode(mode);

    if (mode == AUTH_MODES.WEBINAR_SIGN_UP) {
      setTitle(generateTitle(t("REGISTER")));
      setSubtitle(t("USERREGISTER TEXT SERVING TEXT"));
    } else if (mode == AUTH_MODES.WEBINAR_LOG_IN) {
      setTitle(generateTitle(t("LOGIN")));
      setSubtitle(t("USERREGISTER TEXT SERVING TEXT"));
    } else {
      setTitle(t("SAVE YOUR SOLARPROJECT"));
      setSubtitle(t("YOUR PATH TO YOUR OWN ENERGY"));
    }
  }, [authPopupData.data.authMode]);

  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search);
    if (query.get("mode") === "resetPassword") {
      disptach(
        setPopupData(POPUPS.AUTH_POPUP, {
          authMode: AUTH_MODES.RESET_PASSWORD
        })
      );
    }
  }, []);

  return (
    <PopupSkeleton
      handleClose={() => {
        disptach(closePopup(POPUPS.AUTH_POPUP));
      }}
      open={true}
      styless={{ height: "100%", maxHeight: "fit-content" }}
      styleToDialog={{ zIndex: 2000 }}
    >
      <div className={cssClasses.popupContainer}>
        <div className={cssClasses.popupImage}>
          <img src={solarImage} />
        </div>

        <div className={cssClasses.popupFormContainer}>
          <div className={cssClasses.formHeader}>
            <Logo />
          </div>
          <div className={cssClasses.formTitle}>{title}</div>
          <div className={cssClasses.formSubtitle}>{subtitle}</div>
          <input type="text" style={{ width: "500px" }} />
          {authMode == AUTH_MODES.RESET_PASSWORD && <ResetPassword />}

          {(authMode == AUTH_MODES.LOG_IN ||
            authMode == AUTH_MODES.WEBINAR_LOG_IN) && <LoginForm />}

          {(authMode == AUTH_MODES.SIGN_UP ||
            authMode == AUTH_MODES.WEBINAR_SIGN_UP) && (
            <SignUpPopup setSubtitle={setSubtitle} />
          )}

          {authMode == AUTH_MODES.FORGOT_PASSWORD && <ForgotPassword />}
        </div>
      </div>
    </PopupSkeleton>
  );
};

export default AuthPopup;
