import PopupStepper from "components/molecules/popupStepper/PopupStepper";
import {
  getCurrentUser,
  getElectricMeterImages,
  getHouseTopImages,
  getPopupCurrentStep,
} from "helpers/Selector";
import ApplicationStep3 from "pages/application/applicationSteps/step3/application.step3";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { closePopup } from "redux/popups/popups.actions";
import { POPUPS } from "redux/popups/popups.reducer";
import PopupSkeleton from "../popupSkeleton/popupSkeleton";
import Next from "assets/webinar/Next.svg";
import Prev from "assets/webinar/Prev.svg";
import Info from "./Info.svg";
import {
  NextStep,
  PrevStep,
  Step3Container,
  StepperWrapper,
  WebinarProjectDetailsWrapper,
} from "./webinarProjectDetailsStyles";
import { db } from "firebase-global";
import { Webinar } from "db-types/types";
import { joinLiveWebinar } from "utils/webinarHelperFunctions";
import { updateUserInfo } from "api/auth";

const WebinarProjectDetails = (props: any) => {
  // selectors
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentStep = useSelector(getPopupCurrentStep);
  const currentUser = useSelector(getCurrentUser);
  const houseImages = useSelector(getHouseTopImages);
  const electricMeterImages = useSelector(getElectricMeterImages);
  //state of component
  const [currentStepComponent, setcurrentStepComponent]: any = useState(null);
  const handleClose = () => {
    db.collection("webinar").onSnapshot((snapshots) => {
      let data: Webinar[] = [];
      snapshots.forEach((doc) => {
        const webinar = doc.data() as Webinar;

        const millisIn3Hours = 3 * 60 * 60 * 1000;
        const webinarIsPast3Hours =
          new Date().getTime() - webinar.date.toMillis() > millisIn3Hours;
        if (webinarIsPast3Hours) return;
        if (!webinar.live) return;
        data.push(doc.data() as Webinar);
      });
      data = data.sort(
        (x: Webinar, y: Webinar) =>
          x.date.toDate().getTime() - y.date.toDate().getTime()
      );
      const selectedWebinar = data.find((w) => w.id == data[0].id);
      if (!selectedWebinar) {
        console.error(
          "Selected webinar is null....This is the bug in the system"
        );
        dispatch(closePopup(POPUPS.WEBINAR_PROJECT_DETAILS));
        dispatch(closePopup(POPUPS.WELCOME_WEBINAR));
        return;
      }
      const webinarStarted =
        new Date().getTime() > selectedWebinar.date.toMillis();
      if (webinarStarted) {
        joinLiveWebinar();
      }
      dispatch(closePopup(POPUPS.WELCOME_WEBINAR));
      dispatch(closePopup(POPUPS.WEBINAR_PROJECT_DETAILS));
    });
    updateUserInfo(
      {
        uid: currentUser?.uid,
        needToShowWebinarWelcome: false,
      },
      ""
    );
  };

  useEffect(() => {
    switch (currentStep) {
      case 1:
        setcurrentStepComponent(
          <Step3Container>
            <div className="headers">
              <div className="make_flex">
                <div className="head">{t("Upload your images")}</div>
                <img src={Info} alt="" />
              </div>
              <div className="paragraph">
                {t(
                  "The more information we have upfront the easier it is for us to plan a detailed version of your roof."
                )}
              </div>
            </div>
            <ApplicationStep3 />
            {houseImages.length || electricMeterImages.length ? (
              ""
            ) : (
              <div className="uploadImageButton" onClick={handleClose}>
                {t("open project")}
              </div>
            )}
          </Step3Container>
        );
        break;
      default:
        break;
    }
  }, [currentStep]);

  return (
    <PopupSkeleton
      handleClose={handleClose}
      open={true}
      styless={{ position: "relative" }}
    >
      {/* <PrevStep>
        <img src={Prev} alt="" />
      </PrevStep>
      <NextStep>
        <img src={Next} alt="" />
      </NextStep> */}
      <WebinarProjectDetailsWrapper>
        <PopupStepper />
        <StepperWrapper>{currentStepComponent}</StepperWrapper>
      </WebinarProjectDetailsWrapper>
    </PopupSkeleton>
  );
};

export default WebinarProjectDetails;
