import { DealDetail, UpdateDealData } from "db-types/types";

export const SET_HUBSPOT_DEALS = "SET_HUBSPOT_DEALS";
export const SET_CURRENT_DEAL = "SET_CURRENT_DEAL";
export const SET_ALL_DEALS_FROM_DATABASE = "SET_ALL_DEALS_FROM_DATABASE";
export const UPDATE_HUBSPOT_DEAL = "UPDATE_HUBSPOT_DEAL";

export const setHubspotDeals = (dealData: DealDetail) => {
  return {
    type: SET_HUBSPOT_DEALS,
    payload: dealData
  };
};

export const setCurrnetDeal = (dealData: DealDetail) => {
  return {
    type: SET_CURRENT_DEAL,
    payload: dealData
  };
};

export const setAllDealsFromDataBase = (dealData: Array<DealDetail>) => {
  return {
    type: SET_ALL_DEALS_FROM_DATABASE,
    payload: dealData
  };
};

export const updateHubspotDealDetails = (data: UpdateDealData) => {
  return {
    type: UPDATE_HUBSPOT_DEAL,
    payload: data
  };
};
