import { getTotalProjectCost } from "helpers/project-costs/total-project-cost";
import { getYearlyFinanceCost } from "helpers/project-costs/yearly-financial-cost";

/**
 * Is a support function to provide financial detail object
 * for the given project
 * @param project Project
 * @returns financial object acc. to database
 */
export const buildFinancialObject = (project: any) => {
  return {
    total_project_cost: getTotalProjectCost(project),
    duration_in_years: project?.finacing_datails?.duration_in_years,
    total_financing_amount: getYearlyFinanceCost(project) * 12,
    yearly_financing_amount: getYearlyFinanceCost(project),
  };
};
