const DEFAULT_LANGUAGE = "English";
const ALLOWED_LANGS = ["English", "German", "Spanish"];

export type LanguagesShortCodes = "de" | "en" | "es";

export const isPreferredLangSetAndValid = () => {
  let preferredLang: any = localStorage.getItem("preferredLanguage");
  if (ALLOWED_LANGS.includes(preferredLang)) return true;
  return false;
};

export const getUserPreferredLanguage = () => {
  let preferredLang: any = localStorage.getItem("preferredLanguage");
  if (!ALLOWED_LANGS.includes(preferredLang)) preferredLang = DEFAULT_LANGUAGE;
  return preferredLang;
};

export const getUserPreferredLanguageShort = (): LanguagesShortCodes => {
  let preferredLang: any = localStorage.getItem("preferredLanguage");
  if (!ALLOWED_LANGS.includes(preferredLang)) preferredLang = DEFAULT_LANGUAGE;

  if (preferredLang == "English") return "en";
  else if (preferredLang == "German") return "de";
  else if (preferredLang == "Spanish") return "es";
  return "en";
};
