import { makeStyles } from "@mui/styles";
import { getLanguageShortCode } from "helpers/Selector";
import { t } from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PopupSkeleton from "../popupSkeleton/popupSkeleton";
import { detailsPlanData2 } from "./cardsData";
import DetailsPlan from "./detailplan/DetailsPlan";
import { CheckoutWrapper } from "./styles";

const useStyles: any = makeStyles((theme: any) => ({
  dialog: {
    "& .MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        width: "100%",
        margin: 0,
        borderTopRightRadius: "22px",
        borderTopLeftRadius: "22px",
      },
    },
  },

  root: {
    width: "100%",
    maxWidth: "1051px",
    maxHeight: "675px",
    height: "675px",
    overflowY: "auto",
  },
  closeButton: {
    display: "flex",
    justifyContent: "flex-end",
    paddingRight: "10px",
    paddingTop: "10px",
    width: "100%",
  },
}));
function getDetailsPlanData(): Array<detailsPlanData2> {
  return [
    {
      price: t("60 €"),
      titile: t("3D-Simulation"),
      description: t("PLAN_DESCRIPTION_1"),
      bulletPoint: [
        t("3D-Simulation"),
        t("Selection of optimal components"),
        t("Take part in solar webinars"),
        t("Access to solar learning"),
      ],
      index: 0,
    },
    {
      price: t("200 €"),
      titile: t("Energy consulting"),
      description: t("PLAN_DESCRIPTION_2"),
      bulletPoint: [
        t("Everything from 3D-simulation"),
        t("Online consulting"),
        t("1 hour phone + chat support"),
        t("Installer search"),
      ],
      index: 1,
    },
    {
      price: t("750 €"),
      titile: t("Realization"),
      description: t("PLAN_DESCRIPTION_3"),
      bulletPoint: [
        t("Check installer offers"),
        t("Check energy setup"),
        t("Energy and tax consultation*"),
        t("Grid enrollment"),
      ],
      index: 2,
    },
  ];
}
const Checkout = ({ setOpen, open, closeThis }: any) => {
  const { t } = useTranslation();
  const classes = useCallback(useStyles, [])();
  const languageCode = useSelector(getLanguageShortCode);
  const [dataOfPaywall, setDataOfPaywall] = useState(getDetailsPlanData());
  useEffect(() => {
    setDataOfPaywall(getDetailsPlanData());
  }, [languageCode]);
  const handleClose = () => {
    if (closeThis) {
      closeThis(false);
    }
    setOpen(false);
  };
  return (
    <PopupSkeleton
      handleClose={handleClose}
      open={open}
      style={{ zIndex: 1000 }}
      maxWidth={false}
    >
      <CheckoutWrapper>
        <div className="checkoutMain">
          <div className="hading_work">
            <div className="heading_text1">
              {t("SolarHub - die digitale Energieberatung")}
            </div>
            <div className="heading_text2">
              {t("Choose your preferred option")}
            </div>
          </div>
          <div className="detailPlan_mainContainer">
            {dataOfPaywall.map((data: detailsPlanData2, key: number) => {
              return <DetailsPlan data={data} key={key} />;
            })}
          </div>
          <div
            className="sampleProjectButton"
            onClick={() => {
              if (closeThis) {
                closeThis(false);
              }
              setOpen(false);
            }}
          >
            {t("Play around with sample Project")}
          </div>
        </div>
      </CheckoutWrapper>
    </PopupSkeleton>
  );
};

export default Checkout;
