import { ChakraProvider } from "@chakra-ui/react";
import { createTheme } from "@mui/material";
import { responsiveFontSizes, ThemeProvider } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { COMETCHAT_CONSTANTS } from "comet_const";
import AuthPopup from "components/popups/auth/auth";
import InstallerRequestSuccessfull from "components/popups/InstallerRequestSuccessfull/InstallerRequestSuccessfull";
import WebinarPopup from "components/popups/webinarPopup/WebinarPopup";
import WebinarProjectDetails from "components/popups/webinarProjectDetails/webinarProjectDetails";
import WelcomeWebinar from "components/popups/welcome/WelcomeWebinar/WelcomeDescription";
import WelcomePopupNoAddress from "components/popups/welcome/welcomeWithNoAddress/welcomeWithNoAddress";
import { buildingConsumptionsB2B } from "data/building-consumption";
import firebase, { db } from "firebase-global";
import { B2bFilter } from "helperMethod";
import { getAllProducts, getPopups, getProjectDetails } from "helpers/Selector";
import useWindowDimensions from "hooks/windowDimension";
import { Mixpanel } from "mixpanel";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as chatActions from "redux/chat/chat.action";
import { actionSetLanguage } from "redux/language/language.actions";
import { isPreferredLangSetAndValid } from "redux/language/utility/preferredLanguage";
import { closePopup } from "redux/popups/popups.actions";
import { POPUPS } from "redux/popups/popups.reducer";
import { featchAllProducts } from "redux/products/product.action";
import { RootState } from "redux/store";
import {
  actionSetIsAuthStateLoading,
  setCurrentUser,
  setUserDetails
} from "redux/user/user.actions";
import Routers from "router";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { themeOptions } from "theme-overrides";
import * as trackingEvents from "trackingEvents";
import Context from "./AppContext";
import chakraCustomTheme from "./chakra-theme-custom";

let materialCustomTheme = createTheme(themeOptions);
materialCustomTheme = responsiveFontSizes(materialCustomTheme);

/**
 * @NOTICE : material theme must be removed at the end of optimization phase
 * */
const mergedTheme = deepmerge(materialCustomTheme, chakraCustomTheme);

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const quote = useSelector((state: RootState) => state.quote.quote);
  const project = useSelector(getProjectDetails);
  const location = useLocation();
  const popups = useSelector(getPopups);
  const allProducts = useSelector(getAllProducts);
  const { width, height } = useWindowDimensions();
  const [cometChat, setCometChat] = useState<any>(null);

  const setConsumptionDetails = (data: any) =>
    dispatch({ type: "SET_CONSUMPTION_DETAILS", payload: data });
  const setYearlyConsumption = (data: any) => {
    dispatch({ type: "SET_YEARLY_CONSUMPTION", payload: data });
  };
  /**
   * @description: Initialises the comet chat when user changes
   */
  useEffect(() => {
    const notLoggedIn = !user?.currentUser;
    if (notLoggedIn) return;

    initCometChat();
    console.count("[comet-chat] init");
  }, [user?.currentUser]);

  /**
   * @description : User authentication
   */
  useEffect(() => {
    dispatch(actionSetIsAuthStateLoading(true));
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        dispatch(setCurrentUser(user));
        db.collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            dispatch(setUserDetails(doc.data()));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        dispatch(setCurrentUser(null));
        dispatch({ type: "CURRENT_USER_NOT_LOGGD_IN", payload: "not Found" });
      }

      dispatch(actionSetIsAuthStateLoading(false));
    });
  }, [setCurrentUser]);

  /**
   * @description : Get country IP and translate according to it only if no user preference is there
   */
  useEffect(() => {
    Mixpanel.track(trackingEvents.USER_COMES_ON_STEP1, {});
    if (isPreferredLangSetAndValid()) return;

    fetch("https://ipinfo.io/?token=7340f1287afe2a")
      .then((res) => res.json())
      .then((data) => {
        const country = data.country;
        if (country === "DE") {
          dispatch(actionSetLanguage("de"));
        }
      })
      .catch((e) => JSON.stringify(e));
  }, []);
  /**
   * init comet chat.
   */
  const initCometChat = async () => {
    const { CometChat } = await import("@cometchat-pro/chat");
    const appID = COMETCHAT_CONSTANTS.APP_ID;
    const region = COMETCHAT_CONSTANTS.REGION;

    const appSetting = new CometChat.AppSettingsBuilder()
      .subscribePresenceForAllUsers()
      .setRegion(region)
      .build();
    CometChat.init(appID, appSetting).then(
      () => {
        if (CometChat.setSource) {
          CometChat.setSource("ui-kit", "web", "reactjs");
        }
        setCometChat(() => CometChat);

        const uid = user?.currentUser.uid;
        const name = user?.currentUser.displayName;
        const avatar =
          user?.currentUser.photoURL ||
          "https://img.favpng.com/25/13/19/samsung-galaxy-a8-a8-user-login-telephone-avatar-png-favpng-dqKEPfX7hPbc6SMVUCteANKwj.jpg";

        dispatch(chatActions.cometChatInit(true));
        dispatch(chatActions.myCometChat(uid, name, avatar));
      },
      (error) => {
        // @TODO : ERROR HANDLING
      }
    );
  };
  const setProductToRedux = () => {
    db.collection("products")
      .get()
      .then((snapshot: any) => {
        let data: any = [];
        snapshot.forEach((doc: any) => {
          data.push(doc.data());
        });
        dispatch(featchAllProducts(data));
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!allProducts.length) {
      setProductToRedux();
    }
  }, []);

  useEffect(() => {
    if (quote.qrn === "60ebee279eee013e34e64130") {
      return;
    }
    if (location.pathname.includes("saved")) {
      return;
    }
    let bool: boolean = B2bFilter.includes(quote.building_type);
    let whereToMadeUpdate = bool
      ? "consumption_details_b2b"
      : "consumption_details";
    let filedType = !bool ? "building_type" : "business_type";
    let showBuldingType: any;
    if (bool) {
      if (quote.building_type === "Industrial") {
        showBuldingType = "Manufacturer";
      } else if (quote.building_type === "Administration") {
        showBuldingType = "Administration";
      } else if (quote.building_type === "Agriculture") {
        showBuldingType = "Agriculture";
      }
    } else {
      if (quote.building_type === "Single-Family Building") {
        showBuldingType = "single";
      } else if (quote.building_type === "Multi-Family Building") {
        showBuldingType = "multi";
      } else if (quote.building_type === "") {
        showBuldingType = "multi";
      }
    }
    const consumption_details =
      filedType === "building_type"
        ? project?.consumption_details
        : project?.consumption_details_b2b;
    if (filedType === "business_type") {
      dispatch({
        type: "SET_PROJECT_TYPE",
        payload: { isB2B: true, project_type: "b2b" }
      });
      const consumption_details = project?.consumption_details_b2b;
      if (
        consumption_details.no_of_employees ===
          buildingConsumptionsB2B[consumption_details[filedType]]
            .no_of_employees ||
        consumption_details.no_of_employees === 0
      ) {
        setConsumptionDetails({
          ...consumption_details,
          business_type: consumption_details[filedType],
          no_of_employees:
            buildingConsumptionsB2B[showBuldingType].no_of_employees,
          kwa_per_employee:
            buildingConsumptionsB2B[showBuldingType].kwa_per_employee
        });
      }
      if (
        consumption_details.kwa_per_employee ===
          buildingConsumptionsB2B[showBuldingType].kwa_per_employee ||
        consumption_details.kwa_per_employee === 0
      ) {
        setConsumptionDetails({
          ...consumption_details,
          business_type: showBuldingType,
          kwa_per_employee:
            buildingConsumptionsB2B[showBuldingType].kwa_per_employee,
          no_of_employees:
            buildingConsumptionsB2B[showBuldingType].no_of_employees
        });
      }
    } else {
      setConsumptionDetails({
        ...consumption_details,
        building_type: showBuldingType
      });
    }
    dispatch({
      type: "UPDATE_CONSUMPTION_PROFILE",
      payload: { whereToMadeUpdate, filedType, showBuldingType }
    });
  }, [quote.building_type, location]);

  return (
    <Context.Provider value={{ cometChat }}>
      <ThemeProvider theme={mergedTheme}>
        <ChakraProvider theme={mergedTheme} resetCSS>
          <Routers />
          <ToastContainer />
          {popups.INSTALLER_CONFIRMATION.open && (
            <InstallerRequestSuccessfull></InstallerRequestSuccessfull>
          )}

          {popups.AUTH_POPUP.open && <AuthPopup></AuthPopup>}
          {popups.WELCOME_WEBINAR.open && <WelcomeWebinar></WelcomeWebinar>}
          {popups.WEBINAR_POPUP.open && <WebinarPopup></WebinarPopup>}
          {popups.WELCOME_ADDRESS.open && (
            <WelcomePopupNoAddress
              open={popups.WELCOME_ADDRESS.open}
              setOpen={() => dispatch(closePopup(POPUPS.WELCOME_ADDRESS))}
            ></WelcomePopupNoAddress>
          )}
          {popups.WEBINAR_PROJECT_DETAILS.open && (
            <WebinarProjectDetails></WebinarProjectDetails>
          )}
        </ChakraProvider>
      </ThemeProvider>
    </Context.Provider>
  );
};

export default App;
// sar
