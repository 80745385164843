import { Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CloseButton from "components/atoms/closeButton";
import { useCallback } from "react";

const useStyles: any = makeStyles((theme: any) => ({
  dialog: {
    "& .MuiPaper-root": {
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        bottom: 0,
        right: 0,
        left: 0,
        width: "100%",
        margin: 0,
        borderTopRightRadius: "22px",
        borderTopLeftRadius: "22px",
      },
    },
  },

  root: {
    width: "100%",
    maxWidth: "1160px",
    maxHeight: "min(90vh, 843px)",
    overflowY: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
    },
  },
  closeButton: {
    position: "absolute",
    zIndex: 1,
    right: "15px",
    top: "10px",
    [theme.breakpoints.down("sm")]: {
      right: "13px",
      top: "10px",
    },
  },
}));

/**
 *
 * @param handleClose : will be called when dialog will be closed by "x" button
 * @param open : wheter to show dialog or not
 * @param children : whatever that comes inside the dialog in jsx form
 * @param styless : inline styles for root component
 * @returns
 */
const PopupSkeleton = ({
  open,
  handleClose,
  children,
  styless,
  styleToDialog,
}: any) => {
  const classes = useCallback(useStyles, [])();

  return (
    <Dialog
      disableEnforceFocus
      onClose={handleClose}
      open={open}
      maxWidth={false}
      className={classes.dialog}
      style={styleToDialog}
    >
      <div className={classes.closeButton}>
        <CloseButton onClick={handleClose} />
      </div>
      <div className={classes.root} style={styless}>
        {children}
      </div>
    </Dialog>
  );
};

export default PopupSkeleton;
