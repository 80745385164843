import { sendEmailForNewVersion } from "api/email/sentEmail";
import firebase, { db } from "firebase-global";
import * as geofire from "geofire-common";
import { buildFinancialObject } from "helpers/database-object-creators/financial-details-obj";
import i18n from "i18n/config";
import { toast } from "react-toastify";
import store from "../redux/store";
import { costsAndSavings, energyDetails } from "./costs";

function reverseString(str: any) {
  return str.split("").reverse().join("");
}

export function parseLocaleNumber(stringNumber: any, locale: any) {
  if (typeof stringNumber === "number") stringNumber = stringNumber.toString();
  var thousandSeparator = Intl.NumberFormat(locale)
    .format(11111)
    .replace(/\p{Number}/gu, "");
  var decimalSeparator = Intl.NumberFormat(locale)
    .format(1.1)
    .replace(/\p{Number}/gu, "");

  return parseFloat(
    stringNumber
      .replace(new RegExp("\\" + thousandSeparator, "g"), "")
      .replace(new RegExp("\\" + decimalSeparator), ".")
  );
}

export function commaDotHandler(val: any) {
  const type = store.getState().global.language;
  let temp = String(val);
  //   if (temp.length === 0) return "";
  if (type === "de") {
    let ind: any = -1;
    let sar: any = "";
    let ans: any = "";
    for (let i = 0; i < temp.length; i++) {
      if (temp[i] === ".") {
        ind = i;
        break;
      }
      sar += temp[i];
    }
    var match: any = sar.match(/\d/g);
    if (!match) return "";
    var matches: any = sar.match(/\d/g).join("");
    let c = 0;
    for (let i = matches.length - 1; i >= 0; i--) {
      c = c + 1;
      ans += matches[i];
      if (c % 3 == 0 && i != 0) ans += ".";
    }
    ans = reverseString(ans);
    if (ind == -1) return ans;
    if (ind != -1) ans += ",";
    for (let i = ind + 1; i < temp.length; i++) {
      ans += temp[i];
    }
    return ans;
  }
  let ind: any = -1;
  let sar: any = "";
  let ans: any = "";
  for (let i = 0; i < temp.length; i++) {
    if (temp[i] === ".") {
      ind = i;
      break;
    }
    sar += temp[i];
  }
  var match: any = sar.match(/\d/g);
  if (!match) return "";
  var matches: any = sar.match(/\d/g).join("");
  let c = 0;
  for (let i = matches.length - 1; i >= 0; i--) {
    c = c + 1;
    ans += matches[i];
    if (c % 3 == 0 && i != 0) ans += ",";
  }
  ans = reverseString(ans);
  if (ind === -1) {
    return ans;
  }
  if (ind != -1) ans += ".";
  for (let i = ind + 1; i < temp.length; i++) {
    ans += temp[i];
  }
  return ans;
}
export default commaDotHandler;

export const getNumber = (x: any) => parseFloat(x ? x : 0);

export const calc_func = (
  electricity_cost_before: any,
  estimated_yearly_price_increase: any,
  expectedReturnTime: any
) => {
  var temp = electricity_cost_before;
  var total_cost = electricity_cost_before;
  for (let i = 1; i < getNumber(expectedReturnTime); i++) {
    const this_years_extra =
      (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100;
    temp = temp + this_years_extra;
    total_cost = total_cost + temp;
  }
  return Math.round(total_cost);
};

export const calc_elec_cost_ev_over_the_years = (
  estimated_yearly_price_increase: any,
  expectedReturnTime: number,
  electricity_cost_wallBox: any
) => {
  var temp = electricity_cost_wallBox; //5
  var total_cost = electricity_cost_wallBox; //5
  for (let i = 1; i < expectedReturnTime; i++) {
    const this_years_extra =
      (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100; //5 -> 5% --> 0.05
    temp = temp + this_years_extra; // 5.05=5+0.05
    total_cost = total_cost + temp; // 10.05= 5.05 + 5
  }
  return Math.round(total_cost);
};

export const calc_elec_cost_heatpump_over_the_years = (
  estimated_yearly_price_increase: any,
  expectedReturnTime: number,
  electricity_cost_heatpump: any
) => {
  var temp = electricity_cost_heatpump;
  var total_cost = electricity_cost_heatpump;
  for (let i = 1; i < expectedReturnTime; i++) {
    const this_years_extra =
      (parseFloat(estimated_yearly_price_increase) * parseFloat(temp)) / 100;
    temp = temp + this_years_extra;
    total_cost = total_cost + temp;
  }
  return Math.round(total_cost);
};

export const calc_amortisation_time = (
  electricity_cost_before: any,
  estimated_yearly_price_increase: any,
  saving_mobility_bill: any,
  feedin_savings: any,
  saving_gas_oil_bill: any,
  heat_pump_grid_cost: any,
  consumption_ev_from_grid_cost: any,
  acutal_rate: any,
  total_check: any
) => {
  var elec_cost_before = electricity_cost_before;
  var plus_cost =
    elec_cost_before +
    saving_mobility_bill +
    feedin_savings +
    saving_gas_oil_bill;
  var minus_cost =
    -heat_pump_grid_cost + consumption_ev_from_grid_cost + acutal_rate;
  var total_cost = plus_cost - minus_cost;
  var year = 0;
  for (let i = 0; i < 1000; i++) {
    const this_years_extra_for_elec_cost_before =
      (parseFloat(estimated_yearly_price_increase) *
        parseFloat(elec_cost_before)) /
      100;
    const this_years_extra_for_elec_heat_grid_cost =
      (parseFloat(estimated_yearly_price_increase) *
        parseFloat(heat_pump_grid_cost)) /
      100;
    const this_years_extra_for_elec_consumption_ev_grid_cost =
      (parseFloat(estimated_yearly_price_increase) *
        parseFloat(consumption_ev_from_grid_cost)) /
      100;
    elec_cost_before = elec_cost_before + this_years_extra_for_elec_cost_before;
    heat_pump_grid_cost =
      heat_pump_grid_cost + this_years_extra_for_elec_heat_grid_cost;
    consumption_ev_from_grid_cost =
      consumption_ev_from_grid_cost +
      this_years_extra_for_elec_consumption_ev_grid_cost;
    let plus_cost =
      elec_cost_before +
      saving_mobility_bill +
      feedin_savings +
      saving_gas_oil_bill;
    let minus_cost =
      -heat_pump_grid_cost + consumption_ev_from_grid_cost + acutal_rate;
    let temp_cost = plus_cost - minus_cost;
    total_cost = total_cost + temp_cost;
    // if (total_cost > 0) {
    //   year = i + 1;
    //   return year;
    // }
  }
  return year;
};

export const amortisation_time = (
  totoal_profit_over_the_yars: any,
  cruuentYear: any,
  investment_const: any
) => {
  const yearly_profit = totoal_profit_over_the_yars / 100;
  const Amortisation_time = investment_const / yearly_profit;
  return Math.round(Amortisation_time);
};

export const add_new_config = (
  project: any,
  pathname: any,
  setLoading: any,
  currentUser: any,
  savedprojectsVersion: any,
  quote: any,
  navigate: any,
  isNavigate: Boolean,
  slider1: any,
  divRef: any,
  dispatch: any
) => {
  let slug = pathname.substring(13);
  if (project?.quoteStatus === "default") {
    toast.error(i18n.t("Something went wrong"));
    return;
  }
  if (project?.quoteStatus === "notFound") {
    toast.error(
      i18n.t(
        "Please wait for sometime. We are creating the project for your address -Please check back after some time"
      ),
      {
        className: "toast-message-layout",
      }
    );
    return;
  }
  if (slug?.includes("saved")) {
  } else {
    setLoading(true);
  }

  const financialDetails = buildFinancialObject(project);

  const energysDetails = energyDetails(project);
  const costSaving = costsAndSavings(project);
  const rigthVersion = savedprojectsVersion.sort(function (a: any, b: any) {
    return (
      parseInt(b.version.replace("v", "")) -
      parseInt(a.version.replace("v", ""))
    );
  });
  if (currentUser) {
    const uid = currentUser?.uid;
    let versionLength: any;
    if (savedprojectsVersion?.length === 0) {
      versionLength = 2;
    } else {
      versionLength = parseInt(rigthVersion[0].version.replace("v", "")) + 1;
    }
    const docRef = db
      .collection("saved_projects")
      .doc(`${uid}${quote?.quote.qrn}v${versionLength}`);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          docRef
            .set({
              project_details: {
                ...project,
                pid: uid + quote?.quote.qrn + `v${versionLength}`,
              },
              uid: uid,
              qrn: quote?.quote.qrn,
              crn: quote?.quote?.crn,
              pid: uid + quote?.quote.qrn + `v${versionLength}`,
              projectImgUrl: quote?.quote?.img_url,
              financialDetails,
              energysDetails,
              costSaving,
              updatedData: {
                roof_type: quote?.quote?.roof_type || "Flat roof",
                roof_material: quote?.quote?.roof_material || "metal roof",
                roof_tilt: quote?.quote?.roof_tilt || "45°",
                roof_orientation:
                  quote?.quote?.roof_orientation || "South orientation",
              },
              version: `v${versionLength}`,
              geohash: geofire.geohashForLocation([
                parseFloat(quote?.quote?.lat),
                parseFloat(quote?.quote.long),
              ]),
              lat: quote?.quote?.lat,
              long: quote?.quote?.long,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            })
            .then(() => {
              toast.success(
                i18n.t("Solar Configuration new version created successfully")
              );
              const letUrl =
                "/application/saved-" +
                uid +
                quote?.quote.qrn +
                `v${versionLength}`;
              sendEmailForNewVersion(
                project,
                currentUser,
                letUrl,
                `v${versionLength}`
              );
              dispatch({ type: "DUMMYPROJECT_PROJECT_ADDED" });
              if (slider1) {
                slider1.current.slickGoTo(savedprojectsVersion.length + 1);
              }
              if (divRef) {
                divRef.current.scrollIntoView({ behavior: "smooth" });
              }
              if (isNavigate) {
                if (
                  window.location.pathname.includes("saved") ||
                  window.location.pathname.includes("default")
                ) {
                  navigate(
                    "/application/saved-" +
                      uid +
                      quote?.quote.qrn +
                      `v${versionLength}`
                  );
                } else {
                  localStorage.setItem(
                    "goBackUrl",
                    "/application/saved-" +
                      uid +
                      quote?.quote.qrn +
                      `v${versionLength}`
                  );
                }
              }
              if (slug?.includes("saved")) {
              } else {
                toast.success(i18n.t("Project succesfully saved"));
                setLoading(false);
              }
            })
            .catch((e) => console.error(e.message));
        } else {
          db.collection("saved_projects")
            .where("qrn", "==", quote?.quote.qrn)
            .where("uid", "==", currentUser.uid)
            .get()
            .then((snapshots: any) => {
              const data: any = [];
              snapshots.forEach((doc: any) => {
                data.push(doc.data());
              });
              let beforeNumber = parseInt(data[0].short_id.split("-")[0]);
              docRef
                .set({
                  project_details: {
                    ...project,
                    pid: uid + quote?.quote.qrn + `v${versionLength}`,
                  },
                  uid: uid,
                  qrn: quote?.quote.qrn,
                  crn: quote?.quote?.crn,
                  pid: uid + quote?.quote.qrn + `v${versionLength}`,
                  short_id: `${beforeNumber}-v${versionLength}`,
                  projectImgUrl: quote?.quote?.img_url,
                  financialDetails,
                  energysDetails,
                  costSaving,
                  updatedData: {
                    roof_type: quote?.quote?.roof_type || "Flat roof",
                    roof_material: quote?.quote?.roof_material || "metal roof",
                    roof_tilt: quote?.quote?.roof_tilt || "45°",
                    roof_orientation:
                      quote?.quote?.roof_orientation || "South orientation",
                  },
                  version: `v${versionLength}`,
                  geohash: geofire.geohashForLocation([
                    parseFloat(quote?.quote?.lat),
                    parseFloat(quote?.quote.long),
                  ]),
                  lat: quote?.quote?.lat,
                  long: quote?.quote?.long,
                  createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                  updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
                  sortedDate: new Date().getTime() / 1000,
                })
                .then(() => {
                  toast.success(
                    i18n.t(
                      "Solar Configuration new version created successfully"
                    )
                  );
                  const letUrl =
                    "/application/saved-" +
                    uid +
                    quote?.quote.qrn +
                    `v${versionLength}`;
                  sendEmailForNewVersion(
                    project,
                    currentUser,
                    letUrl,
                    `v${versionLength}`
                  );
                  dispatch({ type: "DUMMYPROJECT_PROJECT_ADDED" });
                  if (slider1) {
                    slider1.current.slickGoTo(savedprojectsVersion.length + 1);
                  }
                  if (divRef) {
                    divRef.current.scrollIntoView({ behavior: "smooth" });
                  }
                  if (isNavigate) {
                    if (
                      window.location.pathname.includes("saved") ||
                      window.location.pathname.includes("default")
                    ) {
                      navigate(
                        "/application/saved-" +
                          uid +
                          quote?.quote.qrn +
                          `v${versionLength}`
                      );
                    } else {
                      localStorage.setItem(
                        "goBackUrl",
                        "/application/saved-" +
                          uid +
                          quote?.quote.qrn +
                          `v${versionLength}`
                      );
                    }
                  }
                  if (slug?.includes("saved")) {
                  } else {
                    sendEmailForNewVersion(
                      project,
                      currentUser,
                      letUrl,
                      `v${versionLength}`
                    );
                    setLoading(false);
                  }
                })
                .catch((e) => console.error(e.message));
            })
            .catch((e: any) => console.log(e));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    setLoading(false);
  }
};
