import { getBuildingConsumption } from "./building-consumption";
import { getEVConsumption } from "./ev-consumption";
import { getHeatpumpConsumption } from "./heatpump-consumption";
import { getWaterHeatingConsumption } from "./water-heating-consumption";

export const getYearlyConsumption = (project: any) => {
  const evCons = getEVConsumption(project);
  const heatpumpCons = getHeatpumpConsumption(project);
  const householdCons = getBuildingConsumption(project);
  const waterHeatingCons = getWaterHeatingConsumption(project);

  const total = evCons + heatpumpCons + householdCons + waterHeatingCons;
  return total;
};
