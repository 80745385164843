import { RootState } from "redux/store";

//header selector
export const getCurrentUser = (state: RootState) => state.user.currentUser;
export const getUserDetails = (state: RootState) => state.user.userDetails;
export const getMapAdress = (state: RootState) => state.user.mapadress;
export const getLanguageShortCode = (state: RootState) => state.global.language;

//step1 selecor
export const getProjectDetails = (state: RootState) => state.project;
export const getQuote = (state: RootState) => state?.quote;
export const getCustomerRefferalStatus = (state: RootState) =>
  state?.global?.isCustomerReferral;
export const getReferralCode = (state: RootState) =>
  state?.notification?.refferal?.referral_code;
export const getMyCredit = (state: RootState) => state?.user?.referral_credit;
export const getMyLastVisitedProperty = (state: RootState) =>
  state?.user?.lastLogOfUser;

// webinar
export const getStateWebinars = (state: RootState) => state?.webinar.webinars;
export const getStateWebinarJoined = (state: RootState) =>
  state?.webinar.joinedWebinar;
export const getSelectedWebiars = (state: RootState) =>
  state?.webinar.selectedWebinarId;
export const getWebinarPopupDetails = (state: RootState) =>
  state.popups.WEBINAR_POPUP.data;
export const getJoinedWebinarList = (state: RootState) =>
  state.webinar.joinedWebinars;
export const getLastJoinedWebinarDetails = (state: RootState) =>
  state.webinar.lastJoinedWebinar;
// popup selector
export const getPopups = (state: RootState) => state.popups;
export const getAuthPopup = (state: RootState) => state.popups.AUTH_POPUP;
export const getWelcomeWebinarPopup = (state: RootState) =>
  state.popups.WEBINAR_POPUP;

// application selectors
export const getStateCurrentStep = (state: RootState) =>
  state.application.currentStep;
export const getPopupCurrentStep = (state: RootState) =>
  state.application.popupCurrentStep;
export const getProdDataForBatterySimulation = (state: RootState) =>
  state.project.productionByBatterySimulation;
export const getAllProducts = (state: RootState) => state.product.products;

// get project images details
export const getHouseTopImages = (state: RootState) =>
  state.project.project_images?.house_top?.total;
export const getHouseBottomImages = (state: RootState) =>
  state.project.project_images?.house_bottom?.total;
export const getElectricMeterImages = (state: RootState) =>
  state.project.project_images?.electric_meter?.total;
export const getProjectAddress = (state: RootState) =>
  state.project.address?.complete;

//model
export const getHouseHoldModel = (state: RootState) =>
  state.project.model.household;
export const getEvModel = (state: RootState) => state.project.model.ev;
export const getHeatPumpModel = (state: RootState) =>
  state.project.model.heatpump;
export const getHeatingRodModel = (state: RootState) =>
  state.project.model.heatingRod;

//hubspot deal

export const getCurrentDeal = (state: RootState) =>
  state.hubspotDeals.currentDeal;
export const getAllDeals = (state: RootState) =>
  state.hubspotDeals.hubspotDeals;
