import axios from "axios";
import { generalFormat } from "helpers/dateFormatHelper";
import store from "redux/store";
import { TESTING_BACKEND_URL } from "utils/urls";

const emailData: any = Object.freeze({
  savedProject: {
    en: 2,
    de: 3,
    es: 3
  },
  newVersion: {
    en: 18,
    de: 17,
    es: 17
  },
  webinarSignup: {
    en: 7,
    de: 11,
    es: 11
  },
  installerRequest: {
    en: 20,
    de: 19,
    es: 19
  },
  installerRequested: {
    en: 14,
    de: 4,
    es: 4
  },
  newLayoutRealized: {
    en: 5,
    de: 10,
    es: 10
  },
  requestNewLayout: {
    en: 21,
    de: 21,
    es: 21
  },
  freeChoiceInstaller: {
    en: 22,
    de: 22,
    es: 22
  },
  passwordChanged: {
    en: 15,
    de: 9,
    es: 9
  }
});

const returnPropertiesForProducts = (productName: string, project: any) => {
  let productRef = project?.components?.items?.[productName]?.item?.properties;
  let propertiesList = [];
  for (const key in productRef) {
    propertiesList.push(key);
  }
  return propertiesList;
};

const gavePropertiesInString = (project: any) => {
  const solar_panel_properties = returnPropertiesForProducts(
    project,
    "solar_panel"
  );
  const inverter_properties = returnPropertiesForProducts(project, "inverter");
  const battery_properties = returnPropertiesForProducts(project, "battery");
  const heatpump_properties = returnPropertiesForProducts(project, "heatpump");
  const wallbox_properties = returnPropertiesForProducts(project, "wallbox");

  return {
    solar_panel_properties: solar_panel_properties?.join() || "",
    inverter_properties: inverter_properties?.join() || "",
    battery_properties: battery_properties?.join() || "",
    heatpump_properties: heatpump_properties?.join() || "",
    wallbox_properties: wallbox_properties?.join() || ""
  };
};

export const sendMainToUserOnPasswordSuccessfullyChnaged = async (
  email: string
) => {
  const languageShortCode = store.getState().global?.language;

  try {
    const templateId = emailData.passwordChanged[languageShortCode];
    const params = {
      project_link: `${window.origin}`
    };
    const sendData = {
      userEmail: email,
      userName: email,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailForSavedProject_en = async (
  project: any,
  currentUser: any,
  letUrl: string
) => {
  try {
    const languageShortCode = store?.getState().global?.language;

    const solar_panel_capacity =
      project?.components?.items?.solar_panel?.item?.capacity;
    let solar_panel_quantity =
      project?.components?.items?.solar_panel?.quantity;
    const system_cacity = (solar_panel_capacity * solar_panel_quantity) / 1000;
    let templateId = emailData.savedProject[languageShortCode];

    const pdf_url = "https://solarhub-pdf.web.app/?pid=";
    const pdf_link = `${pdf_url}${project.pid}&isEnglish=${
      languageShortCode === "en" ? true : false
    }`;

    const params = {
      name: currentUser?.displayName,
      Addr1: project?.address?.complete,
      system_size: `${system_cacity} kWatt`,
      configuration_option: project?.profile,
      configuration_message:
        "Maximale Modulanzahl - maximale Energieproduktion",
      project_link: `${window.origin}${letUrl}`,
      Panel_amount: Math.round(
        project?.components?.items?.solar_panel?.quantity
      ),
      Inverter_amount: Math.round(
        project?.components?.items?.inverter?.quantity
      ),
      Battery_amount: Math.round(project?.components?.items?.battery?.quantity),
      Heatpump_amount: Math.round(
        project?.components?.items?.heatpump?.quantity
      ),
      pdf_link,
      Wallbox_amount: Math.round(project?.components?.items?.wallbox?.quantity),
      Panel_item: project?.components?.items?.solar_panel?.item?.name,
      Inverter_item: project?.components?.items?.inverter?.item?.name,
      Battery_item: project?.components?.items?.battery?.item?.name,
      Wallbox_item: project?.components?.items?.wallbox?.item?.name,
      Heatpump_item: project?.components?.items?.heatpump?.item?.name,
      solar_properties: gavePropertiesInString(project).solar_panel_properties,
      inverter_properties: gavePropertiesInString(project).inverter_properties,
      battery_properties: gavePropertiesInString(project).battery_properties,
      wallbox_properties: gavePropertiesInString(project).wallbox_properties,
      heatpump_properties: gavePropertiesInString(project).heatpump_properties
    };
    const sendData = {
      userEmail: currentUser?.email,
      userName: currentUser?.displayName,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailForNewVersion = async (
  project: any,
  currentUser: any,
  letUrl: string,
  version: string
) => {
  try {
    const solar_panel_capacity =
      project?.components?.items?.solar_panel?.item?.capacity;
    let solar_panel_quantity =
      project?.components?.items?.solar_panel?.quantity;
    const system_cacity = (solar_panel_capacity * solar_panel_quantity) / 1000;

    const languageShortCode = store.getState().global?.language;
    let templateId = emailData.newVersion[languageShortCode];

    const pdf_url = "https://solarhub-pdf.web.app/?pid=";
    const pdf_link = `${pdf_url}${project.pid}&isEnglish=${
      languageShortCode === "en" ? true : false
    }`;
    console.log(`${window.origin}${letUrl}`, "`${window.origin}${letUrl}`");
    const params = {
      name: currentUser.displayName,
      Addr1: project?.address?.complete,
      version: version,
      system_size: `${system_cacity} kWatt`,
      configuration_option: project?.profile,
      configuration_message:
        "Maximale Modulanzahl - maximale Energieproduktion",
      project_link: `https://app.solarhub24.de${letUrl}`,
      Panel_amount: Math.round(
        project?.components?.items?.solar_panel?.quantity
      ),
      Inverter_amount: Math.round(
        project?.components?.items?.inverter?.quantity
      ),
      Battery_amount: Math.round(project?.components?.items?.battery?.quantity),
      Heatpump_amount: Math.round(
        project?.components?.items?.heatpump?.quantity
      ),
      pdf_link,
      Wallbox_amount: Math.round(project?.components?.items?.wallbox?.quantity),
      Panel_item: project?.components?.items?.solar_panel?.item?.name,
      Inverter_item: project?.components?.items?.inverter?.item?.name,
      Battery_item: project?.components?.items?.battery?.item?.name,
      Wallbox_item: project?.components?.items?.wallbox?.item?.name,
      Heatpump_item: project?.components?.items?.heatpump?.item?.name,
      solar_properties: gavePropertiesInString(project).solar_panel_properties,
      inverter_properties: gavePropertiesInString(project).inverter_properties,
      battery_properties: gavePropertiesInString(project).battery_properties,
      wallbox_properties: gavePropertiesInString(project).wallbox_properties,
      heatpump_properties: gavePropertiesInString(project).heatpump_properties
    };
    const sendData = {
      userEmail: currentUser.email,
      userName: currentUser.displayName,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const sentSignUpWebinarMail = async (userDetails: any) => {
  const state = store.getState();
  const webinarTimeAndDate = state.webinar.selectedWebinarDateTime;
  const getThreeHoursExtraDate = () => {
    const newDate = new Date(webinarTimeAndDate);
    newDate.setHours(newDate.getHours() + 3);
    return new Date(newDate).toISOString().replace(/-|:|\.\d\d\d/g, "");
  };
  const showCorrectDate = generalFormat(new Date(webinarTimeAndDate));
  const returnUrlForCalendar = () => {
    if (webinarTimeAndDate) {
      const iosFormatedDate = new Date(webinarTimeAndDate)
        .toISOString()
        .replace(/-|:|\.\d\d\d/g, "");
      const baseUrl = `https://calendar.google.com/calendar/r/eventedit?`;
      const text = "Join our webinar";
      const details = `join the webinar via this link. https://app.solarhub24.de/application/webinar`;
      const dates = `${iosFormatedDate}/${getThreeHoursExtraDate()}`;
      const url = `${baseUrl}text=${text}&details=${details}&dates=${dates}&`;
      let encodedUrl = encodeURI(url);
      return encodedUrl;
    } else {
      return null;
    }
  };
  try {
    const languageShortCode = store.getState().global?.language;
    let templateId = emailData.webinarSignup[languageShortCode];
    const sendData = {
      userEmail: userDetails.email,
      userName: userDetails.fullName,
      params: {
        name: userDetails.fullName,
        webinarTimeAndDate: showCorrectDate,
        calendar_link: returnUrlForCalendar()
      }
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailToInstallerPartner = async (
  project: any,
  currentUser: any,
  installerData: any
) => {
  try {
    const solar_panel_capacity =
      project?.components?.items?.solar_panel?.item?.capacity;
    let solar_panel_quantity =
      project?.components?.items?.solar_panel?.quantity;
    const system_cacity = (solar_panel_capacity * solar_panel_quantity) / 1000;
    const languageShortCode = store.getState().global?.language;
    let templateId = emailData.installerRequest[languageShortCode];
    const params = {
      name: installerData?.company_name,
      Addr1: project?.address?.complete,
      system_size: `${system_cacity} kWatt`,
      configuration_option: project?.profile,
      configuration_message:
        "Maximale Modulanzahl - maximale Energieproduktion",
      Panel_amount: Math.round(
        project?.components?.items?.solar_panel?.quantity
      ),
      Inverter_amount: Math.round(
        project?.components?.items?.inverter?.quantity
      ),
      Battery_amount: Math.round(project?.components?.items?.battery?.quantity),
      Heatpump_amount: Math.round(
        project?.components?.items?.heatpump?.quantity
      ),
      Wallbox_amount: Math.round(project?.components?.items?.wallbox?.quantity),
      Panel_item: project?.components?.items?.solar_panel?.item?.name,
      Inverter_item: project?.components?.items?.inverter?.item?.name,
      Battery_item: project?.components?.items?.battery?.item?.name,
      Wallbox_item: project?.components?.items?.wallbox?.item?.name,
      Heatpump_item: project?.components?.items?.heatpump?.item?.name,
      solar_properties: gavePropertiesInString(project).solar_panel_properties,
      inverter_properties: gavePropertiesInString(project).inverter_properties,
      battery_properties: gavePropertiesInString(project).battery_properties,
      wallbox_properties: gavePropertiesInString(project).wallbox_properties,
      heatpump_properties: gavePropertiesInString(project).heatpump_properties
    };
    const sendData = {
      userEmail: "partner@solarhub24.de",
      userName: currentUser.displayName,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};
export const sendEmailToSelectedInstallerToUser = async (
  project: any,
  currentUser: any,
  installerData: any
) => {
  try {
    const solar_panel_capacity =
      project?.components?.items?.solar_panel?.item?.capacity;
    let solar_panel_quantity =
      project?.components?.items?.solar_panel?.quantity;
    const system_cacity = (solar_panel_capacity * solar_panel_quantity) / 1000;
    const languageShortCode = store.getState().global?.language;
    let templateId = emailData.installerRequested[languageShortCode];
    const params = {
      name: currentUser.displayName,
      Addr1: project?.address?.complete,
      system_size: `${system_cacity} kWatt`,
      project_link: `${window.origin}/application/saved-${project.pid}`,
      configuration_option: project?.profile,
      configuration_message:
        "Maximale Modulanzahl - maximale Energieproduktion",
      Panel_amount: Math.round(
        project?.components?.items?.solar_panel?.quantity
      ),
      installer_image_link: installerData?.image_url,
      installer_name: installerData?.company_name,
      installer_adress: installerData?.address,
      Inverter_amount: Math.round(
        project?.components?.items?.inverter?.quantity
      ),
      Battery_amount: Math.round(project?.components?.items?.battery?.quantity),
      Heatpump_amount: Math.round(
        project?.components?.items?.heatpump?.quantity
      ),
      Wallbox_amount: Math.round(project?.components?.items?.wallbox?.quantity),
      Panel_item: project?.components?.items?.solar_panel?.item?.name,
      Inverter_item: project?.components?.items?.inverter?.item?.name,
      Battery_item: project?.components?.items?.battery?.item?.name,
      Wallbox_item: project?.components?.items?.wallbox?.item?.name,
      Heatpump_item: project?.components?.items?.heatpump?.item?.name,
      solar_properties: gavePropertiesInString(project).solar_panel_properties,
      inverter_properties: gavePropertiesInString(project).inverter_properties,
      battery_properties: gavePropertiesInString(project).battery_properties,
      wallbox_properties: gavePropertiesInString(project).wallbox_properties,
      heatpump_properties: gavePropertiesInString(project).heatpump_properties
    };
    const sendData = {
      userEmail: currentUser.email,
      userName: currentUser.displayName,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};
export const sendEmailToUserWhenQuoteInOurSystem = async (
  project: any,
  currentUser: any,
  addr1: string
) => {
  try {
    const solar_panel_capacity =
      project?.components?.items?.solar_panel?.item?.capacity;
    let solar_panel_quantity =
      project?.components?.items?.solar_panel?.quantity;
    const system_cacity = (solar_panel_capacity * solar_panel_quantity) / 1000;
    const languageShortCode = store.getState().global?.language;
    let templateId = emailData.newLayoutRealized[languageShortCode];
    const params = {
      name: currentUser.displayName,
      Addr1: addr1,
      system_size: `${system_cacity} kWatt`,
      configuration_option: project?.profile,
      configuration_message:
        "Maximale Modulanzahl - maximale Energieproduktion",
      project_link: `${window.origin}/application/default-${addr1}`,
      Panel_amount: Math.round(
        project?.components?.items?.solar_panel?.quantity
      ),
      Inverter_amount: Math.round(
        project?.components?.items?.inverter?.quantity
      ),
      Battery_amount: Math.round(project?.components?.items?.battery?.quantity),
      Heatpump_amount: Math.round(
        project?.components?.items?.heatpump?.quantity
      ),
      Wallbox_amount: Math.round(project?.components?.items?.wallbox?.quantity),
      Panel_item: project?.components?.items?.solar_panel?.item?.name,
      Inverter_item: project?.components?.items?.inverter?.item?.name,
      Battery_item: project?.components?.items?.battery?.item?.name,
      Wallbox_item: project?.components?.items?.wallbox?.item?.name,
      Heatpump_item: project?.components?.items?.heatpump?.item?.name,
      solar_properties: gavePropertiesInString(project).solar_panel_properties,
      inverter_properties: gavePropertiesInString(project).inverter_properties,
      battery_properties: gavePropertiesInString(project).battery_properties,
      wallbox_properties: gavePropertiesInString(project).wallbox_properties,
      heatpump_properties: gavePropertiesInString(project).heatpump_properties
    };
    const sendData = {
      userEmail: currentUser.email,
      userName: currentUser.displayName,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};
export const sentEmailToSolarhubWhenUserRequestNewLaylout = async (
  project: any,
  currentUser: any
) => {
  try {
    const solar_panel_capacity =
      project?.components?.items?.solar_panel?.item?.capacity;
    let solar_panel_quantity =
      project?.components?.items?.solar_panel?.quantity;
    const system_cacity = (solar_panel_capacity * solar_panel_quantity) / 1000;
    const languageShortCode = store.getState().global?.language;
    let templateId = emailData.requestNewLayout[languageShortCode];
    const params = {
      name: currentUser.displayName,
      Addr1: project?.address?.complete,
      email: currentUser.email,
      uid: currentUser.uid,
      system_size: `${system_cacity} kWatt`,
      configuration_option: project?.profile,
      configuration_message:
        "Maximale Modulanzahl - maximale Energieproduktion",
      project_link: `${window.origin}/application/default-${project?.address?.complete}`,
      Panel_amount: Math.round(
        project?.components?.items?.solar_panel?.quantity
      ),
      Inverter_amount: Math.round(
        project?.components?.items?.inverter?.quantity
      ),
      Battery_amount: Math.round(project?.components?.items?.battery?.quantity),
      Heatpump_amount: Math.round(
        project?.components?.items?.heatpump?.quantity
      ),
      Wallbox_amount: Math.round(project?.components?.items?.wallbox?.quantity),
      Panel_item: project?.components?.items?.solar_panel?.item?.name,
      Inverter_item: project?.components?.items?.inverter?.item?.name,
      Battery_item: project?.components?.items?.battery?.item?.name,
      Wallbox_item: project?.components?.items?.wallbox?.item?.name,
      Heatpump_item: project?.components?.items?.heatpump?.item?.name,
      solar_properties: gavePropertiesInString(project).solar_panel_properties,
      inverter_properties: gavePropertiesInString(project).inverter_properties,
      battery_properties: gavePropertiesInString(project).battery_properties,
      wallbox_properties: gavePropertiesInString(project).wallbox_properties,
      heatpump_properties: gavePropertiesInString(project).heatpump_properties
    };
    const sendData = {
      userEmail: "daniel@solarhub24.de",
      userName: currentUser.displayName,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};

export const sendEmailForFreeChoiceInstaller = async (
  project: any,
  currentUser: any
) => {
  try {
    const solar_panel_capacity =
      project?.components?.items?.solar_panel?.item?.capacity;
    let solar_panel_quantity =
      project?.components?.items?.solar_panel?.quantity;
    const system_cacity = (solar_panel_capacity * solar_panel_quantity) / 1000;
    const languageShortCode = store.getState().global?.language;
    let templateId = emailData.freeChoiceInstaller[languageShortCode];
    const params = {
      name: currentUser.displayName,
      Addr1: project?.address?.complete,
      uid: currentUser.uid,
      pid: project?.pid,
      email: currentUser.email,
      system_size: `${system_cacity} kWatt`,
      configuration_option: project?.profile,
      configuration_message:
        "Maximale Modulanzahl - maximale Energieproduktion",
      Panel_amount: Math.round(
        project?.components?.items?.solar_panel?.quantity
      ),
      project_link: `${window.origin}/application/saved-${project?.pid}?admin=true`,
      Inverter_amount: Math.round(
        project?.components?.items?.inverter?.quantity
      ),
      Battery_amount: Math.round(project?.components?.items?.battery?.quantity),
      Heatpump_amount: Math.round(
        project?.components?.items?.heatpump?.quantity
      ),
      Wallbox_amount: Math.round(project?.components?.items?.wallbox?.quantity),
      Panel_item: project?.components?.items?.solar_panel?.item?.name,
      Inverter_item: project?.components?.items?.inverter?.item?.name,
      Battery_item: project?.components?.items?.battery?.item?.name,
      Wallbox_item: project?.components?.items?.wallbox?.item?.name,
      Heatpump_item: project?.components?.items?.heatpump?.item?.name,
      solar_properties: gavePropertiesInString(project).solar_panel_properties,
      inverter_properties: gavePropertiesInString(project).inverter_properties,
      battery_properties: gavePropertiesInString(project).battery_properties,
      wallbox_properties: gavePropertiesInString(project).wallbox_properties,
      heatpump_properties: gavePropertiesInString(project).heatpump_properties
    };
    const sendData = {
      userEmail: "daniel@solarhub24.de",
      userName: currentUser.displayName,
      params: params
    };
    const result = await axios.post(
      `${TESTING_BACKEND_URL}sendmail/${templateId}`,
      sendData
    );
    if (result.status === 201) {
    }
  } catch (error) {
    console.log(error);
  }
};
