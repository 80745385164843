import React from "react";

const Cross = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.809208 0.810324C0.936516 0.683017 1.14298 0.683017 1.27034 0.810324L2.99944 2.53942L4.72854 0.810324C4.85585 0.683017 5.06231 0.683017 5.18968 0.810324C5.31698 0.937688 5.31698 1.14409 5.18968 1.27145L3.46057 3.00055L5.18968 4.72964C5.31698 4.85695 5.31698 5.06341 5.18968 5.19077C5.06237 5.31808 4.85591 5.31808 4.72854 5.19077L2.99944 3.46168L1.27034 5.19077C1.14303 5.31808 0.936573 5.31813 0.809208 5.19077C0.681901 5.06346 0.681901 4.857 0.809208 4.72964L2.53831 3.00055L0.809208 1.27145C0.681901 1.14415 0.681901 0.937688 0.809208 0.810324Z"
        fill="#666666"
      />
    </svg>
  );
};

export default Cross;
