import useWindowDimensions from "hooks/windowDimension";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import genUseStyles from "./MiddlePath.styles";
const MiddlePath = () => {
  const { t } = useTranslation();
  const languageCode = useSelector((state: RootState) => state.global.language);
  const userAdressStatus = useSelector(
    (state: RootState) => state.user.isUserAdressEnterd
  );

  const { width, height } = useWindowDimensions();

  const classes = useCallback(genUseStyles(), [])();
  const [star, setStar] = useState(4.5);
  const handleOnChage = (value: any) => {
    setStar(value);
  };

  const [subHeading, setSubHeading] = useState("");
  const [headingText, setHeadingText] = useState("");
  useEffect(() => {
    if (userAdressStatus) {
      setHeadingText(t("Please select an adress to save your project"));
    } else {
      setHeadingText(t("Welcome to solarhub"));
    }
    setSubHeading(
      t(
        "With our Solar configurator you can learn about all options for your solar project."
      )
    );
  }, [languageCode, userAdressStatus]);

  const [text, setText] = useState("");
  useEffect(() => {
    if (width > 600) {
      setText(
        t(
          "To use your own data please enter your adress. In the next step our solar experts will create a preliminary design concept for your PV project. Or simply play around with the sample data to understand the options for a solar project."
        )
      );
    } else {
      setText(
        t(
          "Please enter your address first, in the next step our solar experts will create a design concept for your roof. Or simply play around with the sample data to understand the options for a solar project."
        )
      );
    }
  }, [width, languageCode]);

  return (
    <div className={classes.root}>
      <h1 className={classes.heading}>{headingText}</h1>
      <div className={classes.subHeading}>{subHeading}</div>
      <div className={classes.text}>{text}</div>
    </div>
  );
};

export default MiddlePath;
