const INITIAL_STATE = {
  currentUser: null,
  isAuthStateLoading: false,
  isUserAdressEnterd: false,
  geometry: null,
  username: null,
  mapadress: null,
  referral_credit: [],
  lastLogOfUser: {
    lastProjectId: "undefined",
    lastVisitedAddress: "undefined",
  },
  userDetails: null,
};

const userReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "SET_CURRENT_USER":
      return {
        ...state,
        currentUser: action.payload,
      };
    case "SET_USER_ADRESS_STATUS":
      return {
        ...state,
        isUserAdressEnterd: action.payload,
      };
    case "SET_USER_DETAILS":
      return {
        ...state,
        userDetails: action.payload,
      };
    case "SET_USERNAME_DISCOURSE_PASSWORD":
      return {
        ...state,
        username: action.payload.username,
      };
    case "SET_USER_LAST_LOG_OF_PROJECT":
      return {
        ...state,
        lastLogOfUser: action.payload,
      };
    case "SET_GEO_CORDINENTS_IF_NO_QUOTE":
      return {
        ...state,
        geometry: action.payload,
      };
    case "SET_MAPADDRESS_IF_NO_QUOTE":
      return {
        ...state,
        mapadress: action.payload,
      };
    case "SET_REFERRAL_CREDIT":
      return {
        ...state,
        referral_credit: action.payload,
      };
    case "SET_AUTH_USER_LOADING":
      return {
        ...state,
        isAuthStateLoading: action.payload,
      };
    default:
      return state;
  }
};

export default userReducer;
