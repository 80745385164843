import React from "react";
import { MainContainer } from "./styles";
const SmallCard = ({ text, setOpen }: any) => {
  return (
    <MainContainer
      onClick={() => {
        setOpen(true);
      }}
    >
      {text}
    </MainContainer>
  );
};

export default SmallCard;
