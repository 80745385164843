const INITIAL_STATE = {
  waiting_invitaion: [],
  accepted_invitaion: [],
  pending_invitaion: [],
  refferal: {
    address: [],
    createdAt: null,
    referral_code: "",
    uid: "",
  },
  currentUserIsNotLoggedIn: null,
  flag: false,
  short_id: "",
};

const notificationReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "SET_WAITING_INVITAION":
      return {
        ...state,
        waiting_invitaion: action.payload,
      };
    case "CURRENT_USER_NOT_LOGGD_IN":
      return {
        ...state,
        currentUserIsNotLoggedIn: action.payload,
      };
    case "SET_SHORT_ID": {
      return {
        ...state,
        short_id: action.payload,
      };
    }
    case "SET_ALL_INVITAION":
      return {
        ...state,
        waiting_invitaion: action.payload.waiting_invitaion,
        accepted_invitaion: action.payload.accepted_invitaion,
        pending_invitaion: action.payload.pending_invitaion,
      };
    case "SET_ACCEDPTED_INVITAION":
      return {
        ...state,
        accepted_invitaion: action.payload,
      };
    case "SET_PENDING_INVITAION":
      return {
        ...state,
        pending_invitaion: action.payload,
      };
    case "OPEN_MODAL":
      return {
        ...state,
        flag: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        flag: false,
      };
    case "SET_REFFERAL_DETAILS":
      return {
        ...state,
        refferal: action.payload,
      };
    case "SET_REFFERAL_ADDRESS":
      return {
        ...state,
        refferal: { ...state.refferal, address: action.payload },
      };
    default:
      return state;
  }
};

export default notificationReducer;
