import MainButton from "components/atoms/MainButton";
import { db } from "firebase-global";
import {
  getCurrentUser,
  getPopups,
  getProjectDetails,
  getQuote,
  getStateCurrentStep
} from "helpers/Selector";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { setCurrentStep } from "redux/application/application.action";
import { openPopup, setPopupData } from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import { RootState } from "redux/store";
import SmallCard from "./SmallCard";
import { BigContainer } from "./UploadCard.tsx/styles";
import UploadCard from "./UploadCard.tsx/UploadCard";
const ApplicationStep3 = () => {
  const { t } = useTranslation();
  const [houseImages, setHouseImages] = useState<Array<any>>([]);
  const [insidehouseImages, setinsideHouseImages] = useState([]);
  const [meterImages, setMeterImages] = useState([]);
  const total = useSelector(
    (state: RootState) => state?.project?.project_images?.house_top?.total
  );
  const user = useSelector((state: RootState) => {
    return state.user.currentUser;
  });
  const dispatch = useDispatch();
  const project = useSelector(getProjectDetails);
  const quote = useSelector(getQuote);
  const currentUser = useSelector(getCurrentUser);
  const popups = useSelector(getPopups);
  const location = useLocation();
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const currentStep = useSelector(getStateCurrentStep);
  useEffect(() => {
    if (user === null && !location.pathname.includes("custom")) {
      dispatch(openPopup(POPUPS.AUTH_POPUP));
      dispatch(
        setPopupData(POPUPS.AUTH_POPUP, {
          authMode: AUTH_MODES.LOG_IN
        })
      );
    }
  }, [user]);

  useEffect(() => {
    if (!quote.quote.qrn) return;
    if (!currentUser) return;
    if (project.pid.length <= 6) return;
    db.collection("saved_projects")
      .where("uid", "==", currentUser.uid)
      .where("qrn", "==", quote?.quote?.qrn)
      .get()
      .then((snapshot) => {
        let data: any = [];
        snapshot.forEach((doc) => {
          data.push(doc.data());
        });
        data.sort((a: any, b: any) => (a.version < b.version ? -1 : 1));
        data.forEach((currDoc: any) => {
          db.collection("saved_projects")
            .doc(currDoc?.pid)
            .set({
              ...currDoc,
              project_details: {
                ...currDoc.project_details,
                project_images: {
                  ...project?.project_images
                }
              }
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [project?.project_images]);

  useEffect(() => {
    if (
      project?.project_images?.house_bottom?.description !== "" ||
      project?.project_images?.total?.length
    ) {
      setOpen1(true);
    }
    if (
      project?.project_images?.electric_meter?.selectedOption !==
        "Digital meter" ||
      project?.project_images?.electric_meter?.description !== "" ||
      project?.project_images?.total?.length
    ) {
      setOpen2(true);
    }
  }, []);
  return (
    <BigContainer>
      <div className="cards">
        <UploadCard
          imagesList={houseImages}
          setimagesList={setHouseImages}
          text={t("House/roof area details")}
          type="house_top"
        />
        {open1 ? (
          <UploadCard
            imagesList={insidehouseImages}
            setimagesList={setinsideHouseImages}
            text={t("Roof from below details")}
            type="house_bottom"
          />
        ) : (
          <SmallCard text={t("Roof from below details")} setOpen={setOpen1} />
        )}
        {open2 ? (
          <UploadCard
            imagesList={meterImages}
            setimagesList={setMeterImages}
            text={t("Electric meter details")}
            type="electric_meter"
          />
        ) : (
          <SmallCard text={t("Electric meter details")} setOpen={setOpen2} />
        )}
      </div>
      {!popups.WEBINAR_PROJECT_DETAILS.open && (
        <MainButton
          text={`${t("NEXT STEP")}`}
          onClick={() => {
            dispatch(setCurrentStep(currentStep + 1));
          }}
          width={"230px"}
          height="40px"
        />
      )}
    </BigContainer>
  );
};

export default ApplicationStep3;
// sarthak
