import styled from "styled-components/macro";
export const CheckoutWithPaymentWrapper = styled("div")`
  .mainContainer {
    height: 710px;
    width: 1083px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .part_left {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    height: 100%;
    .work {
      height: 115px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .part_right {
    width: 50%;
  }
`;
