import { sentSignUpWebinarMail } from 'api/email/sentEmail';
import axios from 'axios';
import { JoinedWebinar, Webinar } from 'db-types/types';
import { db } from 'firebase-global';
import { openPopup, setPopupData } from 'redux/popups/popups.actions';
import { POPUPS } from 'redux/popups/popups.reducer';
import store from 'redux/store';
import {
	setJoinedWebinar,
	setLastJoinedWebinar
} from 'redux/webinar/webinar.actions';
import { PRODUCTION_BACKEND_URL } from 'utils/urls';
import { openWebinarConfirmationPopup } from 'utils/webinarHelperFunctions';

export const registerUserInZoomWebinar = async (
	userDetail: any,
	lastJoinedWebinar: JoinedWebinar
) => {
	console.log('Zoom registation calling', userDetail);
	try {
		const fullNameArray = userDetail?.fullName
			? userDetail?.fullName?.split(' ')
			: [];
		const firstName = fullNameArray.length ? fullNameArray[0] : '';
		const lastName = fullNameArray.length ? fullNameArray[1] : '';
		const registerData = {
			first_name: firstName || '',
			last_name: lastName || '',
			email: userDetail?.email,
			address: userDetail?.address || ''
		};
		const result = await axios.post(
			`${PRODUCTION_BACKEND_URL}register/webinar/${lastJoinedWebinar.meetingNumber}`,
			registerData
		);
		console.log(result, 'result');
	} catch (error) {
		console.log(error);
	}
};

const updateJoinedWebinarColletion = (
	joinedWebinarData: any,
	lastJoinedWebinar: JoinedWebinar,
	userDetail: any,
	isOpenPopup: boolean
) => {
	console.log(joinedWebinarData, '[webinar] joinedWebinarData');
	console.log(userDetail?.uid, '[webinar] userDetail?.uid');
	db.collection('joinedWebinars')
		.doc(userDetail?.uid)
		.set({ ...joinedWebinarData, lastJoinedWebinar })
		.then(() => {
			console.log('[webinnar] inside then blcok');
			sentSignUpWebinarMail(userDetail);
			registerUserInZoomWebinar(userDetail, lastJoinedWebinar);
			if (isOpenPopup) {
				openWebinarConfirmationPopup();
			}
		})
		.catch((err) => {
			console.log(err, '[webinnar] inside catch blcok');
		});
};

export const registerInJoinedWebinar = (
	selectedWebinarId: string,
	userDetail: any,
	isOpenPopup = false
) => {
	console.log(selectedWebinarId, '[webinar] selectedWebinarId zoom');
	if (!selectedWebinarId) return;

	const allWebinars = store.getState().webinar.webinars;
	const newSelectedWebinar = allWebinars.find(
		(w: Webinar) => w.id == selectedWebinarId
	);
	const docRef = db
		.collection('joinedWebinars')
		.where('uid', '==', userDetail?.uid);

	docRef.get().then((snapshot) => {
		let joinedWebinarData: any = [];
		snapshot.forEach((doc) => {
			joinedWebinarData.push(doc.data());
		});

		if (joinedWebinarData.length) {
			const webinarArray = joinedWebinarData[0]?.selectedWebinars;
			const isSameWebinar = webinarArray.find(
				(data: any) => data?.id === selectedWebinarId
			);
			const lastJoinedWebinar = {
				...newSelectedWebinar,
				address: userDetail?.address || ''
			};

			if (isSameWebinar?.hasOwnProperty('id')) {
				if (isOpenPopup) {
					openWebinarConfirmationPopup();
				}
				return;
			}
			joinedWebinarData[0]?.selectedWebinars.push({
				...newSelectedWebinar,
				address: userDetail?.address || ''
			});
			updateJoinedWebinarColletion(
				joinedWebinarData[0],
				lastJoinedWebinar,
				userDetail,
				isOpenPopup
			);
			store.dispatch(setJoinedWebinar(joinedWebinarData[0].selectedWebinars));
			store.dispatch(setLastJoinedWebinar(lastJoinedWebinar));
		} else {
			const newSingupWebinarData = {
				uid: userDetail?.uid,
				selectedWebinars: [
					{ ...newSelectedWebinar, address: userDetail?.address || '' }
				]
			};
			const lastJoinedWebinar = {
				...newSelectedWebinar,
				address: userDetail?.address || ''
			};

			updateJoinedWebinarColletion(
				newSingupWebinarData,
				lastJoinedWebinar,
				userDetail,
				isOpenPopup
			);
			store.dispatch(
				setJoinedWebinar([
					{ ...newSelectedWebinar, address: userDetail?.address || '' }
				])
			);
			store.dispatch(setLastJoinedWebinar(lastJoinedWebinar));
		}
	});
};
