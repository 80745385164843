import { db } from "firebase-global";
import { getNumber } from "helpers/HeplerMothods";
import { call, put, takeLatest } from "redux-saga/effects";

function featchRoofPitch(quote: any) {
  return quote?.quote?.roof_tilt ? quote?.quote?.roof_tilt : "25";
}
// Code for fetching quote details of saved project & updating Redux
export function* setProjectAsync(action: any) {
  function fetchQuoteStartAsync() {
    console.log(action, "[ quote fetchQuoteStartAsync action");
    return new Promise((resolve, reject) => {
      const data = [];
      db.collection("quotes")
        .where("qrn", "==", action.payload.qrn)
        .get()
        .then((snap) => {
          snap.forEach((doc) => {
            const temp = doc.data();
            if (temp) {
              console.log(action, "[ quote resolved action");
              resolve(temp);
            } else {
              reject(new Error("Quote does not exist"));
            }
          });
        });
    });
  }

  try {
    console.log(action, "[ quote quote action");

    console.time("[quote start]");
    //@ts-ignore
    const data = yield call(fetchQuoteStartAsync);
    console.timeEnd("[quote end]");
    let projectData;
    if (action.payload?.components?.items?.hasOwnProperty("cars")) {
      projectData = { ...action.payload };
    } else {
      projectData = {
        ...action.payload,
        components: {
          ...action.payload.components,
          items: {
            ...action.payload.components?.items,
            cars: []
          }
        }
      };
    }
    console.log(projectData, "[ quote resolved action");
    yield put({ type: "FETCH_QUOTE_SUCCESS", payload: data });
    yield put({ type: "SET_PROJECT", payload: projectData });
    yield put({ type: "SET_COMING_OVERVIEW", payload: false });
    yield put({ type: "SET_QUOTE_FEATCHING", payload: false });
    yield put({ type: "SET_IS_PROJECT_SELECTED_AND_FEATCHED", payload: true });
    yield put({ type: "SET_DIREACT_REDIRECT", payload: false });
  } catch (e: any) {
    console.log(e, "[ quote quote error");

    yield put({ type: "FETCH_QUOTE_FAILURE", payload: e.message });
    yield put({ type: "SET_QUOTE_FEATCHING", payload: false });
  }
}

//Code for adding a new project to Redux which has not been saved before
//Get product details by product IDS (Module & Inverter)
export function* addProjectAsync(action: any) {
  const quote = action.payload;
  function fetchSoiarPanelAsync() {
    return new Promise((resolve, reject) => {
      db.collection("products")
        .doc(quote.quote.module_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject(new Error("Module not found"));
          }
        })
        .catch((e) => {
          reject(new Error(e.message));
        });
    });
  }

  function fetchInverterAsync() {
    return new Promise((resolve, reject) => {
      db.collection("products")
        .doc(quote.quote.inverter_id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve(doc.data());
          } else {
            reject(new Error("Installer not found"));
          }
        })
        .catch((e) => {
          reject(new Error(e.message));
        });
    });
  }

  try {
    //@ts-ignore
    const solarPanel = yield call(fetchSoiarPanelAsync);
    //@ts-ignore
    const inverter = yield call(fetchInverterAsync);
    //@ts-ignore
    const roofPitch = featchRoofPitch(quote);
    yield put({
      type: "ADD_COMPONENT",
      payload: {
        item: solarPanel,
        quantity: getNumber(quote.quote.module_quantity),
        cost:
          getNumber(quote.quote.module_quantity) *
          getNumber(solarPanel.price_b2b)
      }
    });
    yield put({
      type: "ADD_COMPONENT",
      payload: { item: inverter, quantity: 1, cost: 350 }
    });
    yield put({
      type: "SET_ROOFPITCH",
      payload: roofPitch.replace(/\D/g, "")
    });
    yield put({ type: "SET_DIREACT_REDIRECT", payload: false });
    yield put({
      type: "SET_QRN",
      payload: quote.quote.qrn
    });
    yield put({ type: "SET_QUOTE_FEATCHING", payload: false });
  } catch (e: any) {
    console.error("error occured", e.message);
    yield put({ type: "SET_QUOTE_FEATCHING", payload: false });
    yield put({ type: "SET_DIREACT_REDIRECT", payload: false });
  }
}

export function* setProjectStart() {
  yield takeLatest("SET_PROJECT_ASYNC", setProjectAsync);
  yield takeLatest("ADD_PROJECT_ASYNC", addProjectAsync);
}
