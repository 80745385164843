import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    primary: "#ffd75d",
    secondary: "#2d4764",
    lightblack: "#666666",
    lightBlueBackground: "#EDF5FFAA",
    darkBlue : {
      500: "#2d4764",
    },
    yellow:{
      500:"#ffd75d"
    }
  },
  fonts: {
    body: "Nunito, sans-serif",
    heading: "Nunito, sans-serif",
    mono: "Menlo, monospace",
  },
});

export default theme;
