import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { getUserPreferredLanguageShort } from "redux/language/utility/preferredLanguage";
import deTranslations from "./de/translation.json";
import enTranslations from "./en/translation.json";
import esTranslations from "./es/translation.json";

export const resources = {
  en: {
    translations: enTranslations,
  },
  de: {
    translations: deTranslations,
  },
  es: {
    translations: esTranslations,
  },
} as const;

i18n.use(initReactI18next).init({
  lng: getUserPreferredLanguageShort(),
  ns: ["translations"],
  defaultNS: "translations",
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  saveMissing: true,
  parseMissingKeyHandler: (key: string, returnValueByI18n: string) => {
    console.error("Cannot found key in translation file, here is the data :", {
      key,
      returnValueByI18n,
    });
    return key;
  },
  resources,
  keySeparator: false,
});

export default i18n;
