import React from "react";

const UpArrow = () => {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 8L15 6.66667L7.50001 6.5567e-07L-1.16566e-07 6.66665L1.5 7.99997L7.49998 2.66668L13.5 8Z"
        fill="#2D4764"
      />
    </svg>
  );
};

export default UpArrow;
