import * as loadash from 'lodash';

export const POPUPS = {
	INSTALLER_CONFIRMATION: 'INSTALLER_CONFIRMATION',
	AUTH_POPUP: 'AUTH_POPUP',
	WEBINAR_POPUP: 'WEBINAR_POPUP',
	WELCOME_WEBINAR: 'WELCOME_WEBINAR',
	WELCOME_ADDRESS: 'WELCOME_ADDRESS',
	WEBINAR_PROJECT_DETAILS: 'WEBINAR_PROJECT_DETAILS',
	HUBSPOT_MEETING_POPUP: 'HUBSPOT_MEETING_POPUP'
};

export const AUTH_MODES = {
	SIGN_UP: 'SIGN_UP',
	LOG_IN: 'LOG_IN',
	WEBINAR_SIGN_UP: 'WEBINAR_SIGN_UP',
	WEBINAR_LOG_IN: 'WEBINAR_LOG_IN',
	FORGOT_PASSWORD: 'FORGOT_PASSWORD',
	RESET_PASSWORD: 'RESET_PASSWORD'
};

const INITIAL_STATE = {
	INSTALLER_CONFIRMATION: {
		open: false,
		data: {
			noOfTimesShown: 0
		}
	},
	AUTH_POPUP: {
		open: false,
		data: {
			authMode: AUTH_MODES.SIGN_UP
		}
	},
	WEBINAR_POPUP: {
		open: false,
		data: {
			title: '',
			heading: '',
			button_text: '',
			extraContent: '',
			changeWebinar: false
		}
	},
	WELCOME_WEBINAR: {
		open: false,
		data: {
			button_text: 'Select option'
		}
	},
	WELCOME_ADDRESS: {
		open: false,
		data: {}
	},
	WEBINAR_PROJECT_DETAILS: {
		open: false,
		data: {}
	},
	HUBSPOT_MEETING_POPUP: {
		open: false,
		data: {}
	}
};

const popupReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case 'SET_DATA':
			return updateData(state, action);
		case 'SET_VISIBILITY':
			return updateVisibility(state, action);
		default:
			return state;
	}
};

const doesPopupKeyExists = (key: string) => {
	const result = POPUPS.hasOwnProperty(key);
	if (!result) {
		console.error('Cannot found popup key : ', key);
	}
	return result;
};

const updateData = (state: any, action: any): typeof INITIAL_STATE => {
	const popupKey = action.payload.popupKey;
	if (!doesPopupKeyExists(popupKey)) return state;

	const newState = loadash.cloneDeep(state);
	// @ts-ignore
	newState[popupKey].data = action.payload.data;
	return newState;
};

const updateVisibility = (state: any, action: any): typeof INITIAL_STATE => {
	const popupKey: string = action.payload.popupKey;
	if (!POPUPS.hasOwnProperty(action.payload.popupKey)) return state;

	const newState = loadash.cloneDeep(state);
	// @ts-ignore
	newState[popupKey].open = action.payload.open;
	return newState;
};

export default popupReducer;
