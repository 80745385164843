import firebase, { db } from "firebase-global";

const genrateReferralCode = () => {
  let r = (Math.random() + 1).toString(36).substring(2);
  return r;
};

export const firebaseProjectId = {
  stage: "solarhub-44450 sd",
  prod: "solarhub-production"
};

export const genrateReferralAndStoreInDatabase = (
  dispatch: any,
  uid: string
) => {
  const pushObj = {
    uid: uid,
    address: [],
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    referral_code: genrateReferralCode()
  };
  const docRef = db.collection("referral").doc(uid);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
      } else {
        docRef
          .set(pushObj)
          .then(() => {
            dispatch({ type: "SET_REFFERAL_DETAILS", payload: pushObj });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const forwardToTheSavedProject = (shord_id: string, navigate: any) => {
  db.collection("saved_projects")
    .where("short_id", "==", shord_id)
    .get()
    .then((snapshot) => {
      let data: any = [];
      snapshot.forEach((doc) => {
        data.push(doc.data());
      });
      if (data.length > 0) {
        const pid = data[0]?.pid;
        navigate(`/application/custom-${pid}`);
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
export const setReferralDetails = (dispatch: any, uid: string) => {
  const docRef = db.collection("referral").doc(uid);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        dispatch({ type: "SET_REFFERAL_DETAILS", payload: doc.data() });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const updateAddressForReferral = (
  dispatch: any,
  uid: string,
  address: string
) => {
  const docRef = db.collection("referral").doc(uid);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        if (!doc.data()?.address?.includes(address)) {
          docRef
            .set({
              ...doc.data(),
              address: [...doc.data()?.address, address]
            })
            .then(() => {
              dispatch({
                type: "SET_REFFERAL_ADDRESS",
                payload: [...doc.data()?.address, address]
              });
            })
            .catch((error) => {
              console.log(error);
            });
        }
        // dispatch({ type: "SET_REFFERAL_DETAILS", payload: doc.data() });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

interface Filter {
  pid: string;
}
export const updateUserDetailsDatabase = (uid: string, pid: string) => {
  let docRef = db.collection("users").doc(uid);
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        let dataToStore = doc.data()?.referral_credit
          ? doc.data()?.referral_credit
          : [];
        const result = dataToStore.filter((data: Filter) => data.pid === pid);
        if (result.length === 0) {
          docRef
            .set({
              ...doc.data(),
              referral_credit: [
                ...dataToStore,
                {
                  pid: pid
                }
              ]
            })
            .then(() => {})
            .catch((err) => console.log(err));
        }
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const writeUserLastLog = (uid: string, project: any) => {
  if (uid) {
    let docRef = db.collection("lastLogOfUser").doc(uid);
    docRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          docRef
            .set({
              lastProjectId:
                project?.pid && project?.pid !== "project123"
                  ? `/application/saved-${project?.pid}`
                  : doc.data()?.lastProjectId
                  ? doc.data()?.lastProjectId
                  : null,
              lastVisitedAddress: project?.address?.complete
                ? `/application/${project?.project_type}/default-${project?.address?.complete}`
                : null
            })
            .then(() => {})
            .catch((err) => console.log(err));
        } else {
          docRef
            .set({
              lastProjectId:
                project?.pid && project?.pid !== "project123"
                  ? window.location.pathname
                  : null,
              lastVisitedAddress: project?.address?.complete
                ? `/application/${project?.project_type}/default-${project?.address?.complete}`
                : null
            })
            .then(() => {})
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
};

export const returnUserLastLogDetails = async (uid: string) => {
  const result = await db
    .collection("lastLogOfUser")
    .doc(uid)
    .get()
    .then((doc: any) => {
      return doc.data();
    })
    .catch((error: any) => {
      return null;
    });
  return result;
};
