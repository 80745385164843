import styled from "styled-components/macro";

export const MainContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  background: #ffffff;
  padding-top: 2rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  .popup-header {
    width: 100%;
    display: flex;
    justify-content: right;
    padding: 10px 10px;
  }
`;

export const Heading = styled.div`
  font-family: "Nunito";
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #2d4764;
`;

export const PopupMainContent = styled.div`
  display: flex;
  padding: 0 0;
  flex-direction: column;
`;

export const PopupText = styled.div`
  padding: 0 20px 10px;
`;

export const PopupHeading = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
  width: 100%;
`;

export const PopupList = styled.ul`
  width: 100%;
  li {
    font-family: "Nunito";
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
    margin: 10px 0 12px 0;
    background: #f9f9f9;
    border-radius: 12px;
    padding: 18px 0 11px 23px;
    &:first-of-type {
      margin-top: 20px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
    }
  }
`;

export const PopupFrame = styled.div`
  padding: 4px 30px 18px 30px;
  width: 100%;
  display: flex;
  gap: 17.51px;
  align-items: center;
  flex-direction: column;
  iframe {
    width: 100%;
    min-height: 200px;
    aspect-ratio: 2;
    border-radius: 22px;
    margin: 0 auto;
  }

  @media (max-width: 400px) {
    padding: 4px 15px 18px;
  }
`;

interface ButtonProps {
  direction: String;
  display: Number;
}
interface DotProps {
  isActive: boolean;
}

export const SliderButton = styled.button<ButtonProps>`
  height: fit-content;
  width: fit-content;
  border: ${(props: any) =>
    props.direction === "right" ? "0" : "1px solid #2D4764"};
  box-sizing: border-box;
  border-radius: 36px;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
  color: #2d4764;
  padding: 12px 30px;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  background: ${(props: any) =>
    props.direction === "right" ? "#FFD75D" : "#ffffff"};
  display: ${(props: any) => (props.display > 0 ? "block" : "none")};
`;

export const Pagination = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  gap: 5px;
`;

export const Dots = styled.div<DotProps>`
  width: 7px;
  height: 7px;
  background: ${(props: any) => (props.isActive ? "#FFCB2D" : "#E2E2E2")};
  border-radius: 50%;
  cursor: pointer;
`;

export const FrameFooter = styled.div`
  margin: 0;
  padding: 16px 10px 22px;
  height: fit-content;
  width: 100%;
  position: sticky;
  bottom: 0;
  background: #f9f9f9;
  filter: drop-shadow(0px 4px 23px rgba(0, 0, 0, 0.25));

  div {
    max-width: 350px;
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    gap: 10px;
  }
`;
