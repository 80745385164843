import { Elements } from "@stripe/react-stripe-js";
import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ApplicationLoader from "skeleton-loaders/ApplicationLoader";
import { promise } from "utils/utilsfunc";
const Utilities = lazy(() => import("utils/utilities"));
const FeedBack = lazy(() => import("pages/feedback/FeedBack"));
const Payment = lazy(
  () => import("pages/application/applicationSteps/step4/payment/Payment")
);
const CommunityRoutes = lazy(
  () => import("components/community/partials/routes")
);
const Application = lazy(() => import("pages/application/application"));
const Loader = lazy(() => import("components/loader/loader"));
const UserOverView = lazy(() => import("components/useroverview/UserOverView"));
const Routers = () => {
  return (
    <>
      <Routes>
        <Route
          path="/utilities"
          element={
            <Suspense fallback={<div>Loading...</div>}>
              <Utilities />
            </Suspense>
          }
        />
        <Route path="/feedback" element={null}></Route>
        <Route
          path="/community/*"
          element={
            <Suspense fallback={<div>loading...</div>}>
              <CommunityRoutes />
            </Suspense>
          }
        ></Route>
        <Route path="/selectDay" element={null} />
        <Route
          path="/useroverview/:projectId"
          element={
            <Suspense fallback={<div>loading...</div>}>
              <UserOverView />
            </Suspense>
          }
        />
        <Route
          path="/feedback/:projectId"
          element={
            <Suspense fallback={<div>loading...</div>}>
              <FeedBack />
            </Suspense>
          }
        />
        <Route path="/admin" element={null} />
        <Route path="/login" element={null} />
        <Route
          path="/userdashboard"
          element={<Elements stripe={promise}></Elements>}
        />
        <Route
          path="/payment"
          element={
            <Elements stripe={promise}>
              <Suspense fallback={<div>loading...</div>}>
                <Payment />
              </Suspense>
            </Elements>
          }
        />
        <Route
          path="/application/*"
          element={
            <Elements stripe={promise}>
              <Suspense fallback={<ApplicationLoader />}>
                <Application />
              </Suspense>
            </Elements>
          }
        />
        <Route
          element={
            <Suspense fallback={<div>loading...</div>}>
              <Loader />
            </Suspense>
          }
        />
        <Route path="/" element={<Navigate replace to="/application" />} />
      </Routes>
    </>
  );
};

export default Routers;
