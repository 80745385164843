import Checkout from "components/popups/checkout/Checkout";
import { useProjectTools } from "hooks/useProjectTools";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { openPopup } from "redux/popups/popups.actions";
import { POPUPS } from "redux/popups/popups.reducer";
import { RootState } from "redux/store";
import { welcomeWebinarButtonText } from "utils/keys";
import { convertToEmbedYoutubeLink } from "../../../../../utils/utilsfunc";
import {
  FrameFooter,
  MainContainer,
  PopupFrame,
  PopupHeading,
  PopupList,
  PopupMainContent,
  PopupText,
  SliderButton,
} from "./styles";

const WelcomePopupMobile = ({
  frame,

  listHeadArr,

  listTitles,
}: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { save } = useProjectTools();
  let mainContainer: any;
  const [open2, setOpen2] = useState(false);
  const scrollToTop = () => {
    mainContainer.parentElement.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const getButtonName = useSelector(
    (state: RootState) => state.popups.WELCOME_WEBINAR.data.button_text
  );
  return (
    <MainContainer
      ref={(element) => {
        mainContainer = element;
      }}
    >
      <PopupMainContent>
        <PopupFrame>
          <iframe
            width="100%"
            height="100%"
            src={convertToEmbedYoutubeLink(frame)}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </PopupFrame>
        <PopupText>
          <PopupHeading>{listHeadArr}</PopupHeading>
          <PopupList>
            <li>
              {listTitles[0]}
              <br /> <span>{t("PLANNING ROOF")}</span>
            </li>
            <li>
              {listTitles[1]}
              <br /> <span>{t("INFORMATION OF PLANNING")} </span>
            </li>
            <li>
              {listTitles[2]}
              <br /> <span>{t("CHOOSE THING")}</span>
            </li>
            <li>
              {listTitles[3]}
              <br /> <span>{t("BEST COMPONENTS")}</span>
            </li>
          </PopupList>
        </PopupText>
      </PopupMainContent>
      <FrameFooter>
        <div>
          <SliderButton
            direction="right"
            display={1}
            onClick={() => {
              if (getButtonName === welcomeWebinarButtonText.SELECT_OPTION) {
                setOpen2(true);
              } else {
                setTimeout(() => {
                  save(null, null, false);
                }, 2000);
                dispatch(openPopup(POPUPS.WEBINAR_PROJECT_DETAILS));
              }
            }}
          >
            {t(`${getButtonName}`)}
          </SliderButton>
        </div>
      </FrameFooter>
      <Checkout setOpen={setOpen2} open={open2} />
    </MainContainer>
  );
};

export default WelcomePopupMobile;
