export interface HubspotStagesTypes {
  label: string;
  id: string;
}
interface HubspotOwnerTypes {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  userId: number;
  createdAt: string;
  updatedAt: string;
  archived: boolean;
}
export const hubspotStages: Array<HubspotStagesTypes> = [
  {
    label: "Kontaktanfrage",
    id: "appointmentscheduled"
  },
  {
    label: "Erstes Gespräch geführt / Mail geschickt (in Prüfung)",
    id: "35098117"
  },
  {
    label: "on hold (Kommune & Bürger)",
    id: "14254172"
  },
  {
    label: "Kommune Präsentation geplant [Termin vereinbart]",
    id: "31106353"
  },
  {
    label: "Kommune Angebotsphase [nach Präsentation]",
    id: "9444396"
  },
  {
    label: "Projekt gekauft (Kommune & Bürger)",
    id: "closedwon"
  },
  {
    label: "Abgeschlossen und verloren (Kommune & Bürger)",
    id: "closedlost"
  },
  {
    label: "PV Austausch/Beratung [aktiver Kontakt]",
    id: "31106354"
  },
  {
    label: "PV Anfrage Angebot [muss eingeholt werden]",
    id: "31106355"
  },
  {
    label: "PV Angebot versendet [Entscheidung offen]",
    id: "24717443"
  }
];

export const hubSpotOwnerDetails: Array<HubspotOwnerTypes> = [
  {
    id: "64468879",
    email: "david@sunmiguel.de",
    firstName: "David",
    lastName: "Wolf",
    userId: 18748414,
    createdAt: "2021-03-09T11:53:52.268Z",
    updatedAt: "2022-08-03T18:39:43.319Z",
    archived: false
  },
  {
    id: "124167183",
    email: "daniel@solarhub24.de",
    firstName: "Daniel",
    lastName: "Watz",
    userId: 27446202,
    createdAt: "2021-11-26T18:02:21.107Z",
    updatedAt: "2022-08-04T12:24:44.169Z",
    archived: false
  },
  {
    id: "197620887",
    email: "rushilpatel2311@gmail.com",
    firstName: "",
    lastName: "",
    userId: 45709465,
    createdAt: "2022-06-27T11:28:14.628Z",
    updatedAt: "2022-06-27T11:28:14.628Z",
    archived: false
  },
  {
    id: "200372818",
    email: "ssaurabh8778@gmail.com",
    firstName: "Saurabh",
    lastName: "Sharma",
    userId: 45822211,
    createdAt: "2022-07-04T08:47:48.470Z",
    updatedAt: "2022-10-03T05:54:10.640Z",
    archived: false
  },
  {
    id: "239796339",
    email: "experte@solarhub24.de",
    firstName: "Experte",
    lastName: "SolarHub",
    userId: 47257536,
    createdAt: "2022-10-04T09:11:54.955Z",
    updatedAt: "2022-10-04T09:12:48.732Z",
    archived: false
  }
];

export const NOTES: TaskType = {
  IMAGE_UPLOAD:
    "Bitte prüfe die bereitgestellten Bilder auf Vollständigkeit. Falls nicht vollständig → Nachfragen",
  INSTALLER_REQUEST:
    "Bitte Anfrage prüfen und an Installateur weiterleiten. Falls Fragen offen → Nachfragen",
  NEW_LAYOUT_REQUEST:
    "Bitte Kunden anrufen oder E-Mail schreiben und Fragen welches Layout gewünscht wird."
};

export const TASK_TITLE: TaskType = {
  IMAGE_UPLOAD: "Bilder prüfen",
  INSTALLER_REQUEST: "Anfrage Installateur prüfen",
  NEW_LAYOUT_REQUEST: "Anfrage neues Layout"
};

export const TASK_TYPE: TaskType = {
  IMAGE_UPLOAD: "IMAGE_UPLOAD",
  INSTALLER_REQUEST: "INSTALLER_REQUEST",
  NEW_LAYOUT_REQUEST: "NEW_LAYOUT_REQUEST"
};

export interface TaskType {
  IMAGE_UPLOAD: string;
  INSTALLER_REQUEST: string;
  NEW_LAYOUT_REQUEST: string;
}
