import React from "react";

const Info = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.49556 0C3.80401 0 0 3.80401 0 8.49556C0 13.1871 3.80401 16.9918 8.49556 16.9918C13.1871 16.9918 16.9918 13.1871 16.9918 8.49556C16.9918 3.80401 13.1871 0 8.49556 0ZM10.2641 13.167C9.82685 13.3396 9.47875 13.4705 9.21766 13.5611C8.9573 13.6517 8.65451 13.697 8.30999 13.697C7.78064 13.697 7.36852 13.5676 7.07508 13.3094C6.78163 13.0512 6.63563 12.7239 6.63563 12.3262C6.63563 12.1715 6.64641 12.0133 6.66799 11.8522C6.69029 11.6911 6.72553 11.5099 6.77372 11.3063L7.32105 9.37302C7.36924 9.18746 7.41096 9.01124 7.44404 8.84726C7.47713 8.68184 7.49295 8.53008 7.49295 8.39199C7.49295 8.14601 7.44188 7.9734 7.34047 7.8763C7.23762 7.7792 7.04415 7.73173 6.75574 7.73173C6.61477 7.73173 6.46948 7.75259 6.3206 7.79646C6.17316 7.84178 6.04514 7.88277 5.94013 7.92305L6.08469 7.32753C6.44287 7.18152 6.78594 7.05638 7.11319 6.95281C7.44044 6.8478 7.74971 6.79601 8.041 6.79601C8.56676 6.79601 8.97241 6.92404 9.25794 7.17721C9.54204 7.43109 9.68516 7.76122 9.68516 8.16687C9.68516 8.25102 9.6751 8.39918 9.65568 8.61063C9.63626 8.82281 9.59958 9.01628 9.54635 9.19393L9.00189 11.1215C8.9573 11.2761 8.91775 11.453 8.88178 11.6508C8.84654 11.8486 8.82928 11.9997 8.82928 12.1011C8.82928 12.3571 8.8861 12.5319 9.00118 12.6247C9.11481 12.7174 9.31404 12.7642 9.59598 12.7642C9.72904 12.7642 9.87792 12.7405 10.0462 12.6944C10.2131 12.6484 10.3339 12.6074 10.4101 12.5722L10.2641 13.167ZM10.1678 5.34317C9.91388 5.57907 9.60821 5.69703 9.25075 5.69703C8.89401 5.69703 8.58618 5.57907 8.33013 5.34317C8.07553 5.10726 7.94678 4.82029 7.94678 4.48512C7.94678 4.15068 8.07625 3.86299 8.33013 3.62492C8.58618 3.38614 8.89401 3.26747 9.25075 3.26747C9.60821 3.26747 9.9146 3.38614 10.1678 3.62492C10.4217 3.86299 10.549 4.15068 10.549 4.48512C10.549 4.82101 10.4217 5.10726 10.1678 5.34317Z"
        fill="#BFBFBF"
      />
    </svg>
  );
};

export default Info;
