/**
 * consumption dataset based on single | multi building & each row
 * having non-water-heating, water-heating consumption in that order
 */
export const buildingConsumptionsB2C: any = {
  single: [
    [3500, 2750],
    [3600, 4400],
    [4300, 5600],
    [5000, 6200],
    [5500, 6700],
    [6000, 7200],
    [6500, 7700],
    [7000, 8200],
    [7500, 8700],
    [8000, 9200],
  ],
  multi: [
    [1600, 2300],
    [2500, 3500],
    [2900, 4500],
    [3800, 5500],
    [4300, 6300],
    [4800, 6800],
    [5300, 7300],
    [5800, 7800],
    [6300, 8300],
    [6800, 8800],
  ],
};
