//import { Backdrop } from "@material-ui/core";
import { Select } from "antd";
import Logo from "components/vectors/header/logo";
import { JoinedWebinar, Webinar } from "db-types/types";
import { db } from "firebase-global";
import { generalFormat } from "helpers/dateFormatHelper";
import {
  getCurrentUser,
  getElectricMeterImages,
  getHouseTopImages,
  getJoinedWebinarList,
  getLastJoinedWebinarDetails,
  getProjectAddress,
  getUserDetails,
  getWebinarPopupDetails
} from "helpers/Selector";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  closePopup,
  openPopup,
  setPopupData
} from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import {
  setLastJoinedWebinar,
  setSelectedWebinarDate
} from "redux/webinar/webinar.actions";
import styled from "styled-components/macro";
import { timeBreaker } from "utils/utilsfunc";
import {
  joinLiveWebinar,
  openWebinarConfirmationPopup,
  updateLastJoinedWebinarColletion
} from "utils/webinarHelperFunctions";
import PopupSkeleton from "../popupSkeleton/popupSkeleton";
import { useProjectTools } from "hooks/useProjectTools";
import persons from "assets/chartTabs/persons.svg";
import useWindowDimensions from "hooks/windowDimension";
import { RootState } from "redux/store";
import { toast } from "react-toastify";
import { registerInJoinedWebinar } from "api/zoom/zoom";

const { Option } = Select;

const Container = styled.div`
  display: flex;
  width: min(100%, 937px);
  max-width: 90%;
  background: white;
  padding: 3rem 1rem;
  margin: auto;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 500px) {
    padding: 0rem 0rem;
    height: 597px;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  .ant-picker-input input::placeholder {
    @media (max-width: 500px) {
      font-size: 15px !important;
    }
    font-size: 25px !important;
  }
  .ant-select-selection__placeholder {
    color: blue;
  }
  .ant-select-selection-placeholder {
    font-size: 17px !important;
    font-weight: 600;
    color: #9397a4 !important;
  }
  img {
    padding: 1rem;
  }
  .logo {
    display: flex;
  }
  .title {
    font-size: 18px;
    font-weight: 700;
    color: #2d4764;
    margin-top: 54px;
    @media (max-width: 500px) {
      margin-top: 26px;
    }
  }
  .title_2 {
    font-size: 18px;
    font-weight: 700;
    color: #2d4764;
    padding-bottom: 15px;
  }
  .small_heading {
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    color: #2d4764;
    margin-bottom: 30px;
  }
  .small_heading_2 {
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    margin-top: 24px;
    color: #2d4764;
    width: 100%;
  }
  .webinar-time {
    font-weight: 700;
    font-size: 18px;
    color: #2d4764;
  }
  .timer-countdown {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 17px;
    margin-bottom: 17px;
    div {
      text-align: center;
      color: #2d4764;
      font-weight: 600;
      font-size: 14px;
      line-height: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-direction: column;
    }
  }
  .webinar-text {
    text-decoration: underline;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    text-align: center;
    color: #2d4764;
    cursor: pointer;
  }
  .google-login {
    font-weight: 600;
    box-shadow: none;
    border: 1px solid black;
    background: white;
    color: #2d3748;
    margin-top: 32px;
  }
  .show_date {
    height: 40px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    padding-inline: 20px;
    font-weight: 700;
    font-size: 18px;
    line-height: 34px;
    color: #2d4764;
    @media (max-width: 500px) {
      font-size: 14px;
    }
  }
  .register-button {
    width: min(320px, 100%);
    text-align: center;
    color: #2d4764;
    font-size: 16px;
    font-weight: 700;
    background: #ffd75d;
    border-radius: 20px;
    padding: 10px;
    margin-top: 17px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 500px) {
      margin-top: 50px;
    }
  }

  .webinar-button {
    text-align: center;
    color: #2d4764;
    font-size: 16px;
    font-weight: 700;
    background: #ffd75d;
    padding: 10px;
    margin-top: 32px;
    margin-bottom: 20px;
    border-radius: 20px;
    padding-inline: 50px;
    @media (max-width: 500px) {
      padding-inline: 20px;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .login {
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 88px;
    margin-top: 12px;
    .yellow {
      color: #ffbf1b;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    background: #f9f9f9;
    border: 1px solid #e2e2e2;
    border-radius: 150px;
  }

  .ant-select-selection-item {
    font-family: Nunito;
    font-weight: 700;
    color: var(--blue);
    font-size: 18px;
    @media (max-width: 500px) {
      font-size: 15px;
    }
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 4px 11px;
  }
  .ant-select-selector {
  }
  .ant-select-arrow {
    display: inline-block;
    color: inherit;
    font-style: normal;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 43%;
    right: 10px;
    display: flex;
    align-items: center;
    margin-top: -3px;
    color: rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 1;
    text-align: center;
    pointer-events: none;
    border-radius: 50%;
    padding: 5px;
    padding-right: 0px;
    :hover {
      background: #ececec;
    }
  }

  .ant-select-selector {
    border: 1px solid #e2e2e2 !important;
    box-sizing: border-box !important;
    border-radius: 20px !important;
  }
`;

const WebinarPopup = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const webinarDetails = useSelector(getWebinarPopupDetails);
  const projectAddress = useSelector(getProjectAddress);
  const useDetails = useSelector(getUserDetails);
  const joinedWebinars = useSelector(getJoinedWebinarList);
  const lastJoinedWebinar = useSelector(getLastJoinedWebinarDetails);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const { save } = useProjectTools();
  const { width } = useWindowDimensions();
  const [allWebinars, setAllWebinars]: [Webinar[], any] = useState([]);
  const [selectedWebinarId, setSelectedWebinarId]: [string, any] = useState("");
  const houseImages = useSelector(getHouseTopImages);
  const electricMeterImages = useSelector(getElectricMeterImages);
  const [timerCountdown, settimerCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });
  const currentUser = useSelector(getCurrentUser);

  useEffect(() => {
    const unsubscribeListener = db
      .collection("webinar")
      .onSnapshot((snapshots) => {
        let data: Webinar[] = [];
        snapshots.forEach((doc) => {
          const webinar = doc.data() as Webinar;

          const millisIn3Hours = 3 * 60 * 60 * 1000;
          const webinarIsPast3Hours =
            new Date().getTime() - webinar.date.toMillis() > millisIn3Hours;
          if (webinarIsPast3Hours) return;
          if (!webinar.live) return;

          data.push(doc.data() as Webinar);
        });

        data = data.sort(
          (x: Webinar, y: Webinar) =>
            x.date.toDate().getTime() - y.date.toDate().getTime()
        );
        setAllWebinars(data);
        // setSelectedWebinarId(data[0].id);
      });

    return () => {
      unsubscribeListener();
    };
  }, []);

  useEffect(() => {
    if (!allWebinars.length) return;
    let webinarId = allWebinars[0].id;
    if (useDetails && typeof useDetails === "object") {
      if (useDetails?.webinarId) {
        webinarId = useDetails?.webinarId;
      }
      const firstSelectedWebinarId = webinarId;
      let webinar: Webinar | undefined = allWebinars.find(
        (web: Webinar) => web.id == firstSelectedWebinarId
      );

      const webinarDate = webinar?.date.toDate();
      dispatch(setSelectedWebinarDate(webinarDate));
      dispatch({
        type: "SET_SELECTED_WEBINAR_ID",
        payload: firstSelectedWebinarId
      });

      const diff = moment().diff(webinarDate);

      clearAllIntervals();
      if (diff > 0) {
        settimerCountdown({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0
        });
      } else {
        setInterval(() => {
          countDownHandler(webinarDate);
        }, 1000);
      }
    }
  }, [useDetails, allWebinars]);

  const clearAllIntervals = () => {
    // Get a reference to the last interval + 1
    const interval_id = window.setInterval(function () {},
    Number.MAX_SAFE_INTEGER);

    // Clear any timeout/interval up to that id
    for (let i = 1; i < interval_id; i++) {
      window.clearInterval(i);
    }
  };

  const countDownHandler = (webinarDate: any) => {
    const diff = moment().diff(webinarDate);

    const { seconds, minutes, hours, days } = timeBreaker(
      Math.abs(diff / 1000).toFixed(0)
    );

    if (seconds == 0 && minutes == 0 && hours == 0 && days == 0) {
      clearAllIntervals();
    }

    settimerCountdown({
      days: days,
      hours: hours,
      minutes: minutes,
      seconds: seconds
    });
  };
  const joinWebinarHandler = () => {
    const selectedWebinar = allWebinars.find(
      (w) => w.id == lastJoinedWebinar?.id
    );
    if (!selectedWebinar) {
      console.error(
        "Selected webinar is null....This is the bug in the system"
      );
      dispatch(closePopup(POPUPS.WEBINAR_POPUP));
      return;
    }

    const webinarStarted =
      new Date().getTime() > selectedWebinar.date.toMillis();
    if (webinarStarted) {
      if (userDetails.hasOwnProperty("needToShowWebinarWelcome")) {
        if (!userDetails?.needToShowWebinarWelcome) {
          joinLiveWebinar();
          dispatch(closePopup(POPUPS.WEBINAR_POPUP));
        }
      } else if (projectAddress) {
        if (webinarDetails.extraContent !== "") {
          save(null, null, false);
          dispatch(
            setPopupData(POPUPS.WELCOME_WEBINAR, {
              button_text: "Start Configuration"
            })
          );
          dispatch(openPopup(POPUPS.WELCOME_WEBINAR));
          dispatch(closePopup(POPUPS.WEBINAR_POPUP));
        } else {
          save(null, null, false);
          openWebinarConfirmationPopup();
          dispatch(openPopup(POPUPS.WEBINAR_POPUP));
        }
      } else {
        dispatch(closePopup(POPUPS.WEBINAR_POPUP));
        dispatch(openPopup(POPUPS.WELCOME_ADDRESS));
      }
    } else {
      if (
        userDetails.hasOwnProperty("needToShowWebinarWelcome") &&
        webinarStarted
      ) {
        if (!userDetails?.needToShowWebinarWelcome) {
          joinLiveWebinar();
          dispatch(closePopup(POPUPS.WEBINAR_POPUP));
        }
      } else {
        if (webinarDetails.extraContent !== "") {
          if (houseImages.length && electricMeterImages.length) {
            dispatch(closePopup(POPUPS.WEBINAR_POPUP));
            return;
          }
          save(null, null, false);
          dispatch(
            setPopupData(POPUPS.WELCOME_WEBINAR, {
              button_text: "Start Configuration"
            })
          );
          dispatch(openPopup(POPUPS.WELCOME_WEBINAR));
          dispatch(closePopup(POPUPS.WEBINAR_POPUP));
        } else if (projectAddress) {
          save(null, null, false);
          openWebinarConfirmationPopup();
          dispatch(openPopup(POPUPS.WEBINAR_POPUP));
        } else {
          dispatch(closePopup(POPUPS.WEBINAR_POPUP));
          dispatch(openPopup(POPUPS.WELCOME_ADDRESS));
        }
      }
    }
  };

  const openAuthPopup = (mode: string) => {
    dispatch(openPopup(POPUPS.AUTH_POPUP));
    dispatch(
      setPopupData(POPUPS.AUTH_POPUP, {
        authMode: mode
      })
    );
  };

  const handleChangeWebinar = () => {
    setSelectedWebinarId("");
    dispatch(
      setPopupData(POPUPS.WEBINAR_POPUP, {
        title:
          "Please feel free to choose from our upcoming Events Your perfect start to your new energy system",
        heading: "SolarHub Solarwebinar",
        button_text: currentUser ? "Join" : "REGISTER",
        extraContent: "",
        changeWebinar: true
      })
    );
    dispatch(openPopup(POPUPS.WEBINAR_POPUP));
  };

  const changeWebinarHandler = () => {
    if (!selectedWebinarId) return toast.warn(t("choose a webinar"));
    registerInJoinedWebinar(selectedWebinarId, useDetails, true);
    dispatch(closePopup(POPUPS.WEBINAR_POPUP));
  };

  return (
    <PopupSkeleton
      open={true}
      handleClose={() => {
        dispatch(closePopup(POPUPS.WEBINAR_POPUP));
      }}
      styless={{ height: "fit-content" }}
    >
      <div style={{ width: "min(100vw, 937px)", paddingTop: "2rem" }}>
        <Container>
          <div className="logo">
            <Logo />
            {/* <img src={finalLogo} alt="img" />
          <img src={finalText} style={{ marginLeft: "15px" }} alt="" /> */}
          </div>
          {webinarDetails && (
            <div className="title">{t(`${webinarDetails?.heading}`)}</div>
          )}
          {webinarDetails && (
            <div
              className="small_heading"
              style={{
                width:
                  webinarDetails.extraContent === ""
                    ? width > 500
                      ? "57%"
                      : "100%"
                    : "92%"
              }}
            >
              {t(`${webinarDetails?.title}`)}
            </div>
          )}
          <img src={persons} alt="" />
          {/* <div className="webinar-time">{webinar_time}</div> */}
          <div className="title_2">{t("Live Webinar startet in")}</div>
          {selectedWebinarId !== "" && (
            <div className="timer-countdown">
              <div className="days">
                <div>{t("Days")}</div>
                <div>{timerCountdown.days}</div>
              </div>
              <div className="hours">
                <div>{t("Hours")}</div>
                <div>{timerCountdown.hours}</div>
              </div>
              <div className="minutes">
                <div>{t("Minutes")}</div>
                <div>{timerCountdown.minutes}</div>
              </div>
              <div className="seconds">
                <div>{t("Seconds")}</div>
                <div>{timerCountdown.seconds}</div>
              </div>
            </div>
          )}
          {webinarDetails.extraContent === ""
            ? allWebinars?.length > 0 && (
                <Select
                  style={{
                    minWidth: "319px",
                    height: 39
                  }}
                  onChange={(value) => {
                    let webinar: Webinar | undefined = allWebinars.find(
                      (web: Webinar) => web.id == value
                    );

                    const webinarDate = webinar?.date.toDate();
                    dispatch(setSelectedWebinarDate(webinarDate));
                    dispatch({
                      type: "SET_SELECTED_WEBINAR_ID",
                      payload: value
                    });
                    setSelectedWebinarId(value);
                    const diff = moment().diff(webinarDate);

                    clearAllIntervals();
                    if (diff > 0) {
                      settimerCountdown({
                        days: 0,
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                      });
                    } else {
                      setInterval(() => {
                        countDownHandler(webinarDate);
                      }, 1000);
                    }
                  }}
                  placeholder={t("Choose your webinar")}
                >
                  {allWebinars.map((web: any) => {
                    return (
                      <Option key={web.id} value={web.id}>
                        {generalFormat(web.date.toDate())} - {web?.city}
                      </Option>
                    );
                  })}
                </Select>
              )
            : joinedWebinars?.length > 0 && (
                <Select
                  style={{
                    minWidth: "319px",
                    height: 39
                  }}
                  onChange={(value) => {
                    const webinar: JoinedWebinar = joinedWebinars.find(
                      (web: JoinedWebinar) => web.id == value
                    );

                    dispatch(setLastJoinedWebinar(webinar));
                    updateLastJoinedWebinarColletion(webinar);
                    const webinarDate = webinar?.date.toDate();

                    const diff = moment().diff(webinarDate);

                    clearAllIntervals();
                    if (diff > 0) {
                      settimerCountdown({
                        days: 0,
                        hours: 0,
                        minutes: 0,
                        seconds: 0
                      });
                    } else {
                      setInterval(() => {
                        countDownHandler(webinarDate);
                      }, 1000);
                    }
                  }}
                  placeholder={t(
                    "Select webinar from your registered webinar list"
                  )}
                  defaultValue={lastJoinedWebinar?.id}
                  disabled={joinedWebinars?.length === 1}
                >
                  {joinedWebinars.map((web: any) => {
                    return (
                      <Option key={web.id} value={web.id}>
                        {generalFormat(web.date.toDate())} - {web?.city}
                      </Option>
                    );
                  })}
                </Select>
              )}
          {webinarDetails.extraContent && (
            <div className="small_heading_2">
              {t(`${webinarDetails.extraContent}`)}
            </div>
          )}
          {!currentUser && webinarDetails.extraContent === "" ? (
            <Fragment>
              <div
                className="register-button"
                onClick={() => {
                  if (selectedWebinarId === "") {
                    return toast.warn(t("choose a webinar"));
                  }
                  openAuthPopup(AUTH_MODES.WEBINAR_SIGN_UP);
                }}
              >
                {t(`${webinarDetails.button_text}`)}
              </div>
              <div
                className="login"
                onClick={() => {
                  openAuthPopup(AUTH_MODES.WEBINAR_LOG_IN);
                }}
              >
                <span className="yellow">{t("Already registered")}? </span>
                <u>{t("LOGIN")}</u>
              </div>
            </Fragment>
          ) : (
            <div
              className="webinar-button"
              onClick={
                webinarDetails?.changeWebinar
                  ? changeWebinarHandler
                  : joinWebinarHandler
              }
            >
              {t(`${webinarDetails.button_text}`)}
            </div>
          )}
          {webinarDetails.extraContent && (
            <div className="webinar-text" onClick={handleChangeWebinar}>
              {t("change the webinar")}
            </div>
          )}
        </Container>
      </div>
    </PopupSkeleton>
  );
};

export default WebinarPopup;
