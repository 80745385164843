import mixpanel from "mixpanel-browser";
mixpanel.init("92ef5099a05c217bbd92df5571dc6de0");

var base_url = window.location.origin;
const env_check = () => {
  if (base_url === "https://app.solarhub24.de") {
    return true;
  } else return false;
};

let actions = {
  identify: (id: any) => {
    if (env_check()) mixpanel.identify(id);
  },
  alias: (id: any) => {
    if (env_check()) mixpanel.alias(id);
  },
  track: (name: any, props: any) => {
    if (env_check()) mixpanel.track(name, props);
  },
  people: {
    set: (props: any) => {
      if (env_check()) mixpanel.people.set(props);
    },
  },
};

export let Mixpanel = actions;
