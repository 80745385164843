const INITIAL_STATE = {
    products:[]
  };
  
  const productReducer = (state = INITIAL_STATE, action: any) => {
    switch (action.type) {
      case "FEATCH_ALL_PRODCUT": 
      return {
        ...state,
        products:action.payload
      }
      default:
        return state;
    }
  };
  
  export default productReducer;
  