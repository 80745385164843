import { additionalCostOfConstants } from "./additional-constants-cost";
import { allComponentsCost } from "./all-components-cost";

/**
 * Calculates the total cost of the project by formula :
 * total_cost = components_cost + addition_cost_by_constants;
 *
 * @returns total cost of the project
 */
export const getTotalProjectCost = (proj: any) => {
  return allComponentsCost(proj) + additionalCostOfConstants(proj);
};
