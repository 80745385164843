import { Checkbox } from "@chakra-ui/react";

interface masterChakBoxProps {
  onChange?: any;
  checked?: any;
  children?: any;
}
const MasterCheckbox = ({
  onChange,
  children,
  checked,
}: masterChakBoxProps) => {
  return (
    <Checkbox
      onChange={onChange}
      checked={checked}
      colorScheme="yellow"
      _focus={{
        boxShadow: "none",
      }}
    >
      {children}
    </Checkbox>
  );
};

export default MasterCheckbox;
