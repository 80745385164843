import { Button } from "@mui/material";
import { getAdressResults } from "api/adressSelector";
import { updateAddressForReferral } from "api/FirebaseHelperMethods";
import location from "assets/noAdressPopup/location.svg";
import RemoveButtonSVG from "assets/remove_button.svg";
import search from "assets/search.svg";
import { db } from "firebase-global";
import { B2bFilter } from "helperMethod";
import { getCurrentUser, getPopups, getReferralCode } from "helpers/Selector";
import useWindowDimensions from "hooks/windowDimension";
import * as trackingEvents from "trackingEvents";
import { Mixpanel } from "mixpanel";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { RootState } from "redux/store";
import { parseReverseGeo, useKeyPress } from "./keyPress";
import { SearchSelectorWrapper } from "./styles";
import { openWebinarConfirmationPopup } from "utils/webinarHelperFunctions";

const AddressSelector = ({
	address,
	setAddress,
	setOpen,
	isFromSignup,
	cutomerRefrraStatus,
	handleClose
}: any) => {
	const { t } = useTranslation();
	const [apiResults, setApiResults] = useState<Array<any>>([]);
	const [selected, setSelected] = useState<any>(undefined);
	const referralCode = useSelector(getReferralCode);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const popups = useSelector(getPopups);
	const currentUser = useSelector(getCurrentUser);
	const downPress = useKeyPress("ArrowDown");
	const upPress = useKeyPress("ArrowUp");
	const enterPress = useKeyPress("Enter");
	const [cursor, setCursor] = useState(0);
	const [hovered, setHovered] = useState(undefined);

	useEffect(() => {
		if (apiResults.length && downPress) {
			setCursor((prevState) =>
				prevState < apiResults.length - 1 ? prevState + 1 : prevState
			);
			const result: any = parseReverseGeo(
				apiResults[cursor + 1 === apiResults.length ? cursor : cursor + 1]
			);
			setAddress(result.complete);
			setSelected(result);
		}
	}, [downPress]);
	const moveToProject = (queryAddress: string, type: string, text: string) => {
		Mixpanel.track(trackingEvents.USER_ENTERED_HIS_ADDRESS, {});
		db.collection("quotes")
			.where("addr1", "==", queryAddress)
			.get()
			.then((snapshot) => {
				let data: any = [];
				snapshot.forEach((docs) => {
					data.push(docs.data());
				});
				if (data.length > 0) {
					if (B2bFilter.includes(data[0].building_type)) {
						type = "b2b";
					}
				}
				if (cutomerRefrraStatus) {
					navigate(
						`/application/${type}/default-${text}?referral=${referralCode}`
					);
					updateAddressForReferral(
						dispatch,
						currentUser.uid,
						selected?.complete
					);
				} else {
					navigate(`/application/${type}/default-${text}`);
				}
				if (!isFromSignup) {
					setAddress("");
				}
				handleClose(true);
			})
			.catch((err) => {
				console.log(err);
				navigate(`/application/b2c/default-${text}`);
				if (!isFromSignup) {
					setAddress("");
				}
				handleClose(true);
			});
	};
	const setQuote = (selected: any) => {
		if (selected !== undefined) {
			// dispatch({ type: "SET_USER_SELECTING_ADDRESS", payload: true });
			let queryAddress = selected.address.replace("undefined", "").trim();
			let type = "b2c";
			let output = selected?.complete.split(",");
			let work = [];
			for (let i = 0; i < output.length; i++) {
				work.push(output[i].trim().replace(" ", "-"));
			}
			let text = work.join("/");
			if (currentUser) {
				if (popups.WELCOME_ADDRESS.open) {
					setTimeout(() => {
						openWebinarConfirmationPopup();
					}, 1000);
				}
				db.collection("saved_projects")
					.where("uid", "==", currentUser?.uid)
					.get()
					.then((snapshot) => {
						let data: any = [];
						snapshot.forEach((doc) => {
							if (
								doc
									.data()
									?.project_details?.address?.address.includes(queryAddress)
							) {
								data.push(doc?.data());
							}
						});
						if (data.length) {
							console.log(data, "data");
							data.sort((a: any, b: any) => (a.version < b.version ? -1 : 1));
							if (window.location.pathname.includes(data[0]?.pid)) {
								moveToProject(queryAddress, type, text);
							} else {
								navigate("/application/saved-" + data[0]?.pid);
								if (!isFromSignup) {
									setAddress("");
								}
								handleClose(true);
							}
						} else {
							moveToProject(queryAddress, type, text);
						}
					})
					.catch((err) => {
						console.log(err, "err");
						moveToProject(queryAddress, type, text);
					});
			} else {
				moveToProject(queryAddress, type, text);
			}
		} else {
			if (address === "") {
				toast.warning(t("Address is not entered please enter an address"));
			} else {
				toast.warning(
					t("Address is not correct please enter an valid address")
				);
			}
		}
	};

	useEffect(() => {
		if (apiResults.length && upPress) {
			setCursor((prevState) => (prevState > 0 ? prevState - 1 : prevState));
			const result: any = parseReverseGeo(
				apiResults[cursor - 1 === 1 || cursor <= 0 ? cursor : cursor - 1]
			);
			setAddress(result.complete);
			setSelected(result);
		}
	}, [upPress]);
	useEffect(() => {
		if (apiResults.length && enterPress) {
			const result: any = parseReverseGeo(apiResults[cursor]);
			setSelected(result);
			setAddress(result.complete);
			if (enterPress) {
				setQuote(result);
				setApiResults([]);
			}
		}
	}, [cursor, enterPress]);
	useEffect(() => {
		if (apiResults.length && hovered) {
			setCursor(apiResults.indexOf(hovered));
		}
	}, [hovered]);

	const onChnageHandler = async (value: any) => {
		const result = await getAdressResults(value);
		console.log(result?.data?.features, "result?.data?.features");
		setApiResults(result?.data?.features);
	};
	const onclear = () => {
		setAddress("");
		setApiResults([]);
		setSelected(undefined);
	};

	const { height, width } = useWindowDimensions();
	const searchButtonContent = useCallback(() => {
		if (width < 600) {
			return <img src={search} />;
		} else {
			return t("Discover");
		}
	}, [width]);

	console.log(address, "address");

	return (
		<SearchSelectorWrapper>
			<div
				className={`mainBar ${
					address !== "" && apiResults.length > 0 ? "extra" : ""
				}`}
			>
				<div className="secound_part">
					<div
						className={`left_side ${
							address !== "" && apiResults.length > 0 ? "extra_left" : ""
						}`}
					>
						<img src={location} alt="" />
						<input
							onChange={(e) => {
								setAddress(e.target.value);
								onChnageHandler(e.target.value);
								if (e.target.value === "") {
									setApiResults([]);
								}
							}}
							className="input_main"
							placeholder={t("ENTER YOUR LOCATION")}
							value={address}
						/>
						{address !== "" ? (
							<div className="text" onClick={onclear}>
								<img src={RemoveButtonSVG} alt={"Close"} />
							</div>
						) : (
							""
						)}
					</div>
					<Button
						className={`right_button ${
							address !== "" && apiResults.length > 0 ? "extra_button" : ""
						}`}
						onClick={() => setQuote(selected)}
					>
						{searchButtonContent()}
					</Button>
				</div>
				{address !== "" && apiResults?.length > 0 && (
					<div className="resultContaijer">
						{apiResults?.length > 0
							? apiResults?.map((data: any, key: any) => {
									return (
										<div
											key={key}
											className={`reslut ${key === cursor ? "active" : ""}`}
											onClick={() => {
												const result: any = parseReverseGeo(apiResults[cursor]);
												setSelected(result);
												setAddress(result.complete);
												setQuote(result);
												setApiResults([]);
											}}
											onMouseEnter={() => setHovered(data)}
											onMouseLeave={() => setHovered(undefined)}
										>
											{data?.place_name}
										</div>
									);
							  })
							: ""}
					</div>
				)}
			</div>
		</SearchSelectorWrapper>
	);
};

export default AddressSelector;
