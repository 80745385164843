import { ProjectConstants } from "global-constants";
import { getTotalProjectCost } from "./total-project-cost";

/**
 * A = 1 + (interest_rate / 12)
 * monthly_payment = total_sum x ( (A^duration) * (A - 1) / ((A^duration) - 1) )
 * @returns financing details
 */
export const getTotalFinancingCost = (proj?: any) => {
  const DURATION_YEARS = ProjectConstants.FINANCING_DURATION_IN_YEARS;
  const DURATION_MONTHS = DURATION_YEARS * 12;

  const totalCost = getTotalProjectCost(proj);
  const interestRate = proj?.increaseRateByBank / 100;

  const A = 1 + interestRate / 12;
  const B = A ** DURATION_MONTHS * (A - 1);
  const C = A ** DURATION_MONTHS - 1;
  const monthlyPaymentCost = totalCost * (B / C);

  const totalFinancingCost = monthlyPaymentCost * DURATION_MONTHS;
  return totalFinancingCost;
};
