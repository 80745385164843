import { createStyles, makeStyles } from "@mui/styles";
import solar from "./images/solar.png";

export const styles = makeStyles((theme: any) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },

    root: { width: "100%", minHeight: "90vh" },
    image: {
      backgroundImage: `url(${solar})`,
      backgroundRepeat: "no-repeat",
      backgroundColor:
        theme.palette.type === "light"
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    paper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "50px",
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },

    loginBtn: {
      background: "#FFD75D",
      borderRadius: "5px",
      height: "44px",
      marginTop: "10px",
      marginBottom: "25px",
      fontFamily: "Nunito",
      boxShadow: "none",
      "&:hover": {
        background: "rgba(45, 71, 100, 1)",
        color: "white",
        boxShadow: "none",
      },
      fontSize: "16px",
      color: "#2D4764",
      textTransform: "none",
      fontWeight: "bold",
    },
    whiteButton: {
      margin: "5px",
      borderRadius: "5px",
      fontWeight: "700",
      width: "255px",
      backgroundColor: "transparent",
      boxShadow: "none",
      border: "1px solid black",
      height: "50px",
      [theme.breakpoints.down("xs")]: {
        width: "276px",
        height: "39px",
        fontSize: "13px",
      },
      objectFit: "cover",
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "transparent",
      },
    },
    blueButton: {
      margin: "5px",
      borderRadius: "5px",
      fontWeight: "700",
      color: "white",
      width: "255px",
      backgroundColor: "#2D3748",
      height: "50px",
      objectFit: "cover",
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        width: "276px",
        height: "39px",
        fontSize: "13px",
      },
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#2D3748",
      },
    },
    nextBlue: {
      margin: "5px",
      borderRadius: "5px",
      backgroundColor: "#002D5A",
      fontWeight: "700",
      color: "white",
      width: "255px",
      height: "50px",
      boxShadow: "none",
      [theme.breakpoints.down("xs")]: {
        width: "276px",
        height: "39px",
        fontSize: "13px",
      },
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#002D5A",
      },
      objectFit: "cover",
    },
    labels: {
      color: "#fff",
      fontSize: "16px",
      fontFamily: "Nunito",
    },
    links: {
      cursor: "pointer",
      textDecoration: "none",
      color: "#2D4764",
      "&:hover": {
        color: "#FFD75D",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
      },
      fontSize: "15px",
      fontFamily: "Nunito",
    },
    heading: {
      fontFamily: "Nunito",
      fontSize: "29px",
      fontWeight: 600,
      color: "#ffff",
    },
    radioLabel: {
      cursor: "default",
      "&.MuiFormControlLabel-root .MuiTypography-root": {
        color: "#2D4764",
        fontSize: "15px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
        },
        fontFamily: "Nunito",
      },
    },
    multilineColor: {
      fontFamily: "Roboto",
      fontWeight: "600",
      fontSize: "14px",
      color: "rgba(82, 82, 82, 1)",
      borderRadius: "5px",
      [theme.breakpoints.down("xs")]: {
        marginTop: "-10px",
      },
      border: "none",
      "&.MuiInputBase-input:invalid": {
        color: "rgba(82, 82, 82, 1)",
        fontFamily: "Roboto",
        fontWeight: "600",
        fontSize: "14px",
      },
    },
    img: {
      marginLeft: "57px",
      [theme.breakpoints.down("xs")]: {
        height: "51px",
      },
    },
    btnContainer: {
      display: "flex",
      justifyContent: "center",
      width: "520px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "center",
        width: "auto",
        marginLeft: "0px",
      },
      marginLeft: "-50px",
    },
    removeWidth: {
      [theme.breakpoints.down("xs")]: {
        overflow: "hidden",
      },
    },
    h1Class: {
      fontFamily: "Nunito",
      fontSize: "26px",
      fontWeight: "600",
      margin: "0px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "22px",
      },
      color: "#2D4764",
    },
    h5Class: {
      fontFamily: "Nunito",
      fontSize: "22px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
      fontWeight: "600",
      color: "#525252",
    },
    icon: {
      width: "30px",
      justifySelf: "flex-end",
      position: "absolute",
      right: "0px",
      top: "10px",
      color: "#2D4764",
      marginRight: "10px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    accountH1: {
      margin: "15px 0px 0px -25px",
      marginTop: "25px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        margin: "15px 0px 0px 0px",
      },
    },
    removeMargin: {
      marginBottom: "10px",
      color: "#fff",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
      },
      border: "1px solid white",
    },
  })
);
