import { Project } from "redux/project/initialState";

export const getEVConsumption = (project: Project) => {
  const wallbox = project.components_parts.wallBox;
  const wallboxConsumption = wallbox.ev_yearly_consumption;

  let carsConsumption = 0;
  const cars = project.components.items.cars;
  for (let car of cars) {
    carsConsumption = carsConsumption + car.consumption.ev_yearly_consumption;
  }

  const total = wallboxConsumption + carsConsumption;

  return total;
};
