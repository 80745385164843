import * as actionHandlers from "./application.actionHandlers";
import * as actionTypes from "./application.actionTypes";

const initialState = {
  currentStep: 0,
  popupCurrentStep: 1,
};

const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_STEP:
      return actionHandlers.setCurrentStepHandler(state, action.payload);
    case actionTypes.SET_POPUP_CURRENT_STEP:
      return actionHandlers.setPopupCurrentStepHandler(state, action.payload);
    default:
      return state;
  }
};

export default reducer;
