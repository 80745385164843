import styled from "styled-components/macro";

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1051px;
  overflow: auto;
  overflow-x: hidden;
  margin: 0;
  background: #ffffff;
  padding-top: 2rem;
`;

export const PopupHeader = styled.div`
  border-bottom: 1px solid #e2e2e2;
  display: flex;
  justify-content: space-between;
  padding: 0 40px 20px;
  cursor: pointer;
`;

export const Heading = styled.div`
  font-family: "Nunito";
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #2d4764;
`;

export const PopupMainContent = styled.div`
  display: flex;
  align-items: stretch;
  margin-top: "47px";
  padding: 0 80px;

  @media (max-width: 991px) {
    padding: 0 40px;
  }

  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

export const PopupText = styled.div`
  padding: 30px 31px 30px 0;
  border-right: 1px solid #e2e2e2;
`;

export const PopupHeading = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
`;

export const PopupHeading2 = styled.div`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #2d4764;
  padding-bottom: 20px;
`;
interface popuplist {
  isSampleQuote: boolean;
}
export const PopupList = styled.ul`
  li {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #2d4764;
    list-style: disc;
    margin: 10px 0 10px 20px;
    &:first-of-type {
      margin-top: 20px;
    }
    span {
      font-weight: 400;
    }
  }
`;

export const PopupFrame = styled.div`
  min-width: 65%;
  padding: 30px 0 100px 40px;
  width: 100%;
  height: 478px;
  iframe {
    max-height: 450px;
  }
`;

interface ButtonProps {
  direction: String;
}
interface DotProps {
  isActive: boolean;
}

export const SliderButton = styled.button<ButtonProps>`
  height: fit-content;
  width: fit-content;
  border: ${(props: any) =>
    props.direction === "right" ? "0" : "1px solid #2D4764"};
  box-sizing: border-box;
  border-radius: 36px;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 13px;
  line-height: 100%;
  text-transform: uppercase;
  color: #2d4764;
  padding: 12px 30px;
  filter: drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.1));
  cursor: pointer;
  background: ${(props: any) =>
    props.direction === "right" ? "#FFD75D" : "#ffffff"};
`;
export const Pagination = styled.div`
  height: fit-content;
  width: fit-content;
  display: flex;
  gap: 5px;
`;
export const Dots = styled.div<DotProps>`
  width: 7px;
  height: 7px;
  background: ${(props: any) => (props.isActive ? "#FFCB2D" : "#E2E2E2")};
  border-radius: 50%;
  cursor: pointer;
`;
export const FrameFooter = styled.div<popuplist>`
  margin: 30px 0 0;
  height: fit-content;
  display: flex;
  justify-content: ${({ isSampleQuote }) => {
    if (isSampleQuote) {
      return "center";
    } else {
      return "space-between";
    }
  }};
  align-items: center;
`;
