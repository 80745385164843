import { loadStripe } from "@stripe/stripe-js";
import commaDotHandler, { getNumber } from "helpers/HeplerMothods";
export const promise = loadStripe(
  "pk_live_51JEqYhItm0NLgHnUQQMUhyenZQn7TPsY9PbJg3hxZBuZ9Rgio0zNPCdvYWu6mJwzuLOg571LjanTGP1MXdotpDKv00gZaEtBtE"
);
export const convertToEmbedYoutubeLink = (youtubeLink: any) => {
  let id = youtubeLink.substr(youtubeLink.lastIndexOf("/") + 1);
  if (id.includes("watch")) {
    id = youtubeLink.substr(youtubeLink.lastIndexOf("=") + 1);
  }
  return `https://youtube.com/embed/${id}`;
};
export const costDetails = (component: any, isB2B: any) => {
  if (component?.item) {
    const component_cost = getNumber(
      component?.item[isB2B ? "price_b2b" : "price_b2c"]
    );
    const warranty_cost = getNumber(
      component?.item?.extended_warranty_cost || component_cost * 0.01
    );

    const total_cost = component.extended_warranty
      ? component_cost + warranty_cost
      : component_cost;
    return `€${commaDotHandler(component?.quantity * Math.round(total_cost))} `;
  } else {
    return 0;
  }
};

export const costDetails1 = (component: any, isB2B: any) => {
  if (component?.item) {
    const component_cost = getNumber(
      component?.item[isB2B ? "price_b2b" : "price_b2c"]
    );
    const warranty_cost = getNumber(
      component?.item?.extended_warranty_cost || component_cost * 0.01
    );

    const total_cost = component.extended_warranty
      ? component_cost + warranty_cost
      : component_cost;
    return `${component?.quantity}  x €${commaDotHandler(
      Math.round(total_cost)
    )} `;
  } else {
    return 0;
  }
};

export const timeBreaker = (totalSeconds: any) => {
  const seconds = totalSeconds % 60;
  const minutesWM = (totalSeconds - seconds) / 60;
  const minutes = minutesWM % 60;
  const hoursWM = (minutesWM - minutes) / 60;
  const hours = hoursWM % 24;
  const days = (hoursWM - hours) / 24;

  return { seconds, minutes, hours, days };
};
