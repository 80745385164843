import { createStyles } from "@mui/material";
import { makeStyles } from "@mui/styles";

const genUseStyles: any = () => {
  return makeStyles((theme: any) =>
    createStyles({
      root: {
        "--blue-text": "#304864",
        "--green-text": "#4D7E6D",
        width: "97%",
        height: "auto",
        margin: "auto",
        fontFamily: "Nunito",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: "2.5rem",
        [theme.breakpoints.up("sm")]: {
          width: "100%",
          justifyContent: "center",
        },
      },
      heading: {
        fontWeight: "700",
        fontSize: "24px",
        color: "var(--blue-text)",
        marginBottom: "1rem",
        [theme.breakpoints.up("sm")]: {
          fontSize: "30px",
        },
        [theme.breakpoints.up("md")]: {
          fontSize: "36px",
        },
      },
      subHeading: {
        fontSize: "16px",
        color: "var(--blue-text)",
        textAlign: "left",
        lineHeight: "30px",
        [theme.breakpoints.up("sm")]: {
          fontWeight: "600",
          fontSize: "25px",
          textAlign: "center",
          marginBottom: "7px",
          lineHeight: "initial",
        },
      },
      text: {
        fontFamily: "Nunito",
        fontWeight: "400",
        fontSize: "16px",
        color: "var(--blue-text)",
        textAlign: "left",
        lineHeight: "30px",
        [theme.breakpoints.up("sm")]: {
          fontSize: "22px",
          color: "var(--green-text)",
          textAlign: "center",
          lineHeight: "initial",
        },
        marginBottom: "30px",
      },
    })
  );
};

export default genUseStyles;
