import Hero from "assets/noAdressPopup/Hero.svg";
import PopupSkeleton from "components/popups/popupSkeleton/popupSkeleton";
import { getCurrentUser, getPopups } from "helpers/Selector";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// @ts-ignore
import { RootState } from "redux/store";
import AddressSelector from "./adressselect/AdressSelecor";
import MiddlePath from "./MiddlePath";
import { useStyles } from "./styles";
import cssClasses from "./welcomeWithNoAddress.module.css";

const WelcomePopupNoAddress = ({
  open,
  setOpen,
  isBlank,
  comingForCommunity,
  isNewUser,
  setOpen3,
  cutomerRefrraStatus,
}: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = useSelector(getCurrentUser);
  const currentUserIsNotLoggedIn = useSelector(
    (state: RootState) => state.notification.currentUserIsNotLoggedIn
  );
  const [address, setAddress] = useState<string>("");
  const dispatch = useDispatch();
  const popup = useSelector(getPopups);
  let history = useNavigate();
  const location = useLocation();
  const [whichIconShouldBeVisible, setWhichIconShouldBeVisible] =
    useState(false);

  const classes = useCallback(useStyles, [])();
  useEffect(() => {
    if (currentUser) {
      return;
    } else if (currentUserIsNotLoggedIn === "not Found") {
      if (
        isNewUser &&
        localStorage.getItem("isUserSeenWelcomeScreen") !== "true"
      ) {
        if (
          !popup.WEBINAR_POPUP ||
          !popup.WEBINAR_PROJECT_DETAILS ||
          !popup.WELCOME_ADDRESS ||
          !popup.AUTH_POPUP ||
          !popup.WELCOME_WEBINAR
        ) {
          setOpen3(true);
        }
      }
    }
  }, [currentUserIsNotLoggedIn, currentUser]);
  const returnRight = (noNeedToOpen: boolean) => {
    return noNeedToOpen;
  };
  const handleClose = (noNeedToOpen: boolean) => {
    dispatch({ type: "IS_NEW_USER_MODAL_OPEN" });
    returnRight(noNeedToOpen);
    setOpen(false);
    dispatch({ type: "SET_USER_ADRESS_STATUS", payload: false });
    dispatch({ type: "SET_USER_SELECTING_ADDRESS", payload: false });
    setAddress("");
  };
  return (
    <PopupSkeleton handleClose={() => handleClose(false)} open={open}>
      <div className={cssClasses.dialogContent}>
        <div>
          <MiddlePath />
        </div>
        <AddressSelector
          address={address}
          cutomerRefrraStatus={cutomerRefrraStatus}
          handleClose={handleClose}
          setAddress={setAddress}
          setOpen={setOpen}
        />
        <div>
          <img src={Hero} className={classes.imgRes} alt="" />
        </div>
        <div>
          <button
            className={classes.button_group}
            onClick={() => {
              handleClose(false);
            }}
          >
            {t("Play around with sample")}
          </button>
        </div>
      </div>
    </PopupSkeleton>
  );
};

export default WelcomePopupNoAddress;
