export const USER_COMES_ON_STEP1 = "User comes on Step1";
export const USER_LOGIN = "User Login";
export const USER_REGISTER = "User Register";
export const USER_CLICKS_ON_CONSUMPTION_DETAILS_STEP1 =
  "User Clicks on Consumption Details Step1";
export const USER_CLICKS_ON_ECO_STEP1 = "User Clicks on Eco Step1";
export const USER_CLICKS_ON_SMART_STEP1 = "User Clicks on Smart Step1";
export const USER_CLICKS_ON_GREEN_STEP1 = "User Clicks on Green Step1";
export const USER_CLICKS_ON_ADDCOMPONENTS_STEP1 =
  "User Clicks on Add components Step1";
export const USER_CLICKS_ON_SHOW_EDIT_ASSUMPTIONS_STEP1 =
  "User Clicks on Show or Edit Assumptions Step1";
export const USER_CLICKS_ON_BUY_STEP1 = "User Clicks on Buy Step1";
export const USER_CLICKS_ON_FINANCE_STEP1 = "User Clicks on Finance Step1";
export const USER_CLICKS_ON_SHOW_DETAILED_CALCULATIONS_STEP1 =
  "User Clicks on Show detailed calculations Step1";
export const USER_CLICKS_ON_SHOW_PROCESS_STEP1 =
  "User Clicks on Show Process Step1";
export const USER_CLICKS_ON_NEXT_STEP_STEP1 = "User Clicks on Next Step Step1";
export const USER_CLICKS_ON_TALK_OUR_EXPERT =
  "User Clicks on Talk to our Expert";
export const USER_CLICKS_ON_COMMUNITY = "User Clicks on Community Icon";
export const USER_CLICKS_ON_COMMET_CHAT_ICON =
  "User Clicks on Commet Chat Icon";
export const USER_CLICKS_ON_PROJECT_SAVE_BUTTON =
  "User Clicks on Project Save Button";
export const USER_CLICKS_ON_PROJECT_SHARE_BUTTON =
  "User Clicks on Project Share Button";
export const USER_CLICKS_ON_SAVED_OFFERS = "User Clicks on Saved offers";
export const USER_CLICKS_ON_CUSTOMER_REFERRAL =
  "User Clicks on Customer referral";
export const USER_CLICKS_ON_ADD_ADDITIONAL_CONFIGURATION =
  "User Clicks on Add aditional configruation";
export const USER_CLICKS_ON_GENERATE_PDF = "User Clicks on Generate Pdf";
export const USER_CLICKS_ON_LOGOUT = "User Clicks on Logout";
export const USER_CLICKS_ON_ARROW_BUTTON = "User Clicks arrow button to go next step";
export const USER_IS_USING_SLIDER_TO_ADJUST_SYSTEM = "User is using slider to adjust system";
export const USER_CLICKS_ON_STEP1 = "User Clicks on  Step1";
export const USER_CLICKS_ON__STEP2 = "User Clicks on  Step2";
export const USER_CLICKS_ON_STEP3 = "User Clicks on  Step3";
export const USER_CLICKS_ON_STEP4 = "User Clicks on  Step4";
export const USER_ENTERED_HIS_ADDRESS = "User is entered his address";
export const USER_CLICK_ON_MONTHLY_DROPDOWN = "User Clicks on monthly drop down";
export const USER_CLICK_ON_CHANGE_LANGUAGE = "User Clicks on change language";
export const USER_SEARCH_FOR_INSTALLER = "User is searching for installer";
export const USER_CLICKS_ON_ADDING_IMAGES = "User is adding images";
export const USER_CLICKS_ON_CHECKOUT_BUTTON = "User clicks on checkout button";
export const USER_CLICK_ON_REQUEST_INSTALLER = "User clicks on a request an installer"
export const USER_IS_HOVRING_ON_SHARE = "User is hovering on share"
export const USER_IS_SHARING_PROJECT = "User is sharing his project"
export const USER_IS_CHANGING_ELECTRICITY_CONSUMPTION = "User is changing his electricity consmption"
export const USER_CLICKS_ON_CHANGE_PRODUCT_STEP2 = "User is clicking on change product step2"
export const USER_CLICKS_ON_SHOW_PROJECT_PROCESS = "User clicks on show project process"
export const USER_IS_MAXIMIZING_HIS_IMAGE = "User is maximize the image"