import { Dialog } from "@mui/material";
import React, { FunctionComponent } from "react";
import { CheckoutWithPaymentWrapper } from "./checkoutWithPaymentStyles";
import cssClasses from "../auth/auth.module.css";
import CloseIcon from "components/vectors/auth/closeIcon";
import DetailsPlan from "./detailplan/DetailsPlan";
import { detailsPlanData } from "./cardsData";
import PaymentFourm from "./paymentFourm/PaymentFourm";
import SolarHub_Logo from "assets/application_step_1/SolarHub_Logo.svg";
import stripe from "assets/application_step_1/stripe.png";
import { useSelector } from "react-redux";
import { getMyCredit } from "helpers/Selector";
import Logo from "components/vectors/header/logo";
interface checkOutWithPayment {
  setOpen: (data: boolean) => void;
  open: boolean;
  selectedPlan: number;
}
const CheckoutWithPayment: FunctionComponent<checkOutWithPayment> = ({
  setOpen,
  open,
  selectedPlan,
}) => {
  const referral_credit = useSelector(getMyCredit);
  return (
    <Dialog
      onClose={() => {
        setOpen(false);
      }}
      open={open}
      style={{ zIndex: 1000 }}
      maxWidth={false}
    >
      <CheckoutWithPaymentWrapper>
        <div
          className={cssClasses.closeIcon}
          onClick={() => {
            setOpen(false);
          }}
        >
          <CloseIcon />
        </div>
        <div className="mainContainer">
          <div className="part_left">
            {referral_credit?.length > 0 ? (
              <div>
                <input type="checkbox" name="" id="" />
                <div>
                  you have $ {300 * referral_credit?.length} credit you can use
                  it
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="work">
              <Logo />
            </div>
            <DetailsPlan
              data={detailsPlanData[selectedPlan]}
              key={1}
              isfrom={true}
            />
            <div className="work">
              <img src={stripe} alt="" />
            </div>
          </div>
          <div className="part_right">
            <PaymentFourm planDetails={detailsPlanData[selectedPlan]} />
          </div>
        </div>
      </CheckoutWithPaymentWrapper>
    </Dialog>
  );
};

export default CheckoutWithPayment;
