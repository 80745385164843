import { getTotalFinancingCost } from "helpers/project-costs/total-financial-cost";
import { getTotalProjectCost } from "helpers/project-costs/total-project-cost";
import { getProjectDetails } from "helpers/Selector";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export type ProjectCosts = {
  /**
   * Financing cost of the project per year
   */
  totalFinancingCost: number;

  /**
   * Total cost of the project : `(components_cost) + (additional_web_constants)`
   */
  totalProjectCost: number;

  /**
   * if `project.isFinancing` is true, then it's `yearlyFinancingCost`
   * or if `project.Financing` is false, then it's `totalProjectcost`
   *
   * So gives the current applicable cost of the project based
   * on the financing setting
   */
  appliedProjectCost: number;
};

export function useProjectCosts() {
  const project = useSelector(getProjectDetails);

  const [costs, setCosts] = useState<ProjectCosts>({
    totalFinancingCost: 0,
    totalProjectCost: 0,
    appliedProjectCost: 0,
  });

  useEffect(() => {
    const finCost = getTotalFinancingCost(project);
    const projCost = getTotalProjectCost(project);

    let appliedCost = projCost;
    if (project.isFinancing) {
      appliedCost = finCost;
    }

    const updatedCosts: ProjectCosts = {
      totalFinancingCost: finCost,
      totalProjectCost: projCost,
      appliedProjectCost: appliedCost,
    };
    setCosts(updatedCosts);
  }, [
    project.isFinancing,
    project?.increaseRateByBank,
    project?.components?.items,
    project?.webConstants.constants,
  ]);

  return costs;
}
