import { componentCost } from './component-cost';

/**
 * Gives the summation of project.components.items cost
 * @param project Project
 * @returns total cost of components
 */
export const allComponentsCost = (project: any) => {
	let componentCosts = 0;
	const mainComponents = project?.components?.items;

	const hasNoComponents = !mainComponents;
	if (hasNoComponents) return 0;

	const priceKey = project.isB2B ? 'price_b2b' : 'price_b2c';

	for (let componentKey in mainComponents) {
		const component = mainComponents[componentKey];
		const productCost =
			componentKey === 'battery' && component.isExcludeCost
				? 0
				: componentCost(component, priceKey);
		componentCosts += productCost;
	}

	return componentCosts;
};
