import defaultQuote from "resources/json/defaultQuote.json";

export const initialState: any = {
  quote: defaultQuote,
  errorMessage: undefined,
  imgUrl: "/images/application/application_step_1/img-loading1.gif",
  projectRequestTime: undefined,
  status: null,
  crn_requested: null,
  isImageIsFeatched: false,
  smarEcoGreenImgPanelDetails: {
    smart: {
      img_url: [],
      module_quantity: 0,
    },
    eco: {
      img_url: [],
      module_quantity: 0,
    },
    Green: {
      img_url: [],
      module_quantity: 0,
    },
  },
};
