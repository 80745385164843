import { db } from "firebase-global";
import { call, put, takeLatest } from "redux-saga/effects";
import defaultQuote from "resources/json/defaultQuote.json";

const setQuote = (data: any) => {
  db.collection("quotes")
    .doc(data.qrn)
    .set(data)
    .then(() => {})
    .catch((error) => {
      console.log(error);
    });
};

export const generateQuotewithSampleData = (
  action: any,
  quoteIdFromEnact: string
) => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  function generateString(length: number): string {
    let result = " ";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }
  const date: any = new Date();
  const new_date = `${
    date.getMonth() + 1
  }.${date.getDate()}.${date.getFullYear()}`;
  const new_time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  const setQuotedata = {
    ...defaultQuote,
    addr1: action.payload.complete
      .substr(0, action.payload.complete.indexOf(","))
      .toLowerCase(),
    customer_name: action.payload.complete
      .substr(0, action.payload.complete.indexOf(","))
      .toLowerCase(),
    city: action.payload.city,
    country: action.payload.city,
    building_type: "Single-Family Building",
    crn: quoteIdFromEnact,
    qrn: quoteIdFromEnact,
    lat: String(action.payload.geometry.coordinates[1]),
    long: String(action.payload.geometry.coordinates[0]),
    state: action.payload?.state,
    postcode: action.payload?.postcode,
    zip_code: action.payload?.postcode,
    date: new Date(),
    time: new_time,
    isVerified: "not verified",
    new_quotes: true,
    flagOfQuote: "Green",
  };
  setQuote(setQuotedata);
};

export function* fetchQuoteAsync(action: any) {
  function fetchQuoteStartAsync() {
    return new Promise((resolve, reject) => {
      //Task No 3 - done
      const docref = db
        .collection("quotes")
        .where("addr1", "==", action.payload.address.toLowerCase())
        .where("zip_code", "==", action.payload.postcode);
      docref
        .get()
        .then((snap: any) => {
          const data: any = [];
          snap.forEach((doc: any) => {
            const quoteData = doc.data();
            if (quoteData.flagOfQuote == "Green") {
              data.push(doc.data());
            }
          });
          if (data.length) {
            resolve(data[0]);
          } else {
            const data = {
              first_name: "test",
              last_name: "name",
              email: "test1@gmail.com",
              phone: "(123) 123-1231",
              isLatlongAddress: "true",
              address: action.payload.complete,
              city: action.payload.city,
              state: action.payload.state,
              country: action.payload.country,
              zip_code: action.payload.postcode,
              type: "R",
              annual_electric_bill: "5000",
              project_owner_first_name: "random first name",
              project_owner_last_name: "random last name",
              project_owner_email: "david@solarhub24.de",
              account_id: "SOLARHUB",
              latitude: action.payload.geometry.coordinates[1].toString(),
              longitude: action.payload.geometry.coordinates[0].toString(),
            };
            db.collection("crn_details")
              .where(
                "address1",
                "==",
                action.payload.complete.replaceAll(",", "")
              )
              .get()
              .then((snapshot) => {
                let crn_data: any = [];
                snapshot.forEach((doc) => {
                  crn_data.push(doc.data());
                });
                console.log("crn_data : ", crn_data);
                if (crn_data.length > 0) {
                  generateQuotewithSampleData(action, crn_data[0].id);
                  // we already have quote, so don't call enact now
                } else {
                  fetch("https://envisionapi.enact-systems.com/lead", {
                    method: "POST",
                    headers: {
                      "x-api-key": "DmxtC5gi7V6Wegpmaw8tr4ywv4xBBQR8THXXIJ9f",
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data), // body data type must match "Content-Type" header
                  })
                    .then((res) => res.json())
                    .then((data) => {
                      const date = new Date();
                      const new_date = `${
                        date.getMonth() + 1
                      }.${date.getDate()}.${date.getFullYear()}`;
                      const new_time = `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
                      generateQuotewithSampleData(action, data?.data?.id);
                      console.log("We got id from enact as : ", data?.data?.id);
                      db.collection("crn_details")
                        .doc(data?.data?.id)
                        .set({
                          quote: defaultQuote,
                          address: data?.data?.address,
                          address1: data?.data?.address1,
                          city: data?.data?.city,
                          country: data?.data?.country,
                          crn: data?.data?.crn,
                          id: data?.data?.id,
                          latitude: data?.data?.latitude,
                          longitude: data?.data?.longitude,
                          zip_code: data?.data?.zip_code,
                          state: data?.data?.state,
                          date: new_date,
                          time: new_time,
                        })
                        .then(() => {})
                        .catch((error: any) => {
                          console.log("failed to set crn details", error);
                        });
                    })
                    .catch((e) => console.log(e));
                }
              })
              .catch((e) => {
                console.log(e);
              });
            reject(new Error("Quote does not exist"));
          }
        })
        .catch((err: any) => console.log(err));
    });
  }

  try {
    //@ts-ignore
    const data = yield call(fetchQuoteStartAsync);
    const final = data?.roofPitch ? data?.roofPitch : 30;
    yield put({ type: "FETCH_QUOTE_SUCCESS", payload: data });
    yield put({ type: "SET_CRN_REQUEST", payload: data.crn });
    yield put({ type: "SET_QUOTE_STATUS", payload: "found" });
    yield put({
      type: "SET_INCRESE_CAPACITY_ADMIN_PANEL",
      payload: data?.production_increse ? data?.production_increse : 0,
    });
    yield put({ type: "SET_IMG_URL", payload: data.img_url });
    yield put({ type: "SET_PROJECT_ADDRESS", payload: action.payload });
    yield put({ type: "SET_IS_QUOTE_FEATCHED", payload: false });
    yield put({ type: "SET_ROOF_PITCH_2", payload: final });
    yield put({ type: "ADD_PROJECT_ASYNC", payload: { quote: data } });
    yield put({ type: "SET_DIREACT_REDIRECT", payload: false });
    if (action.meta) {
      yield call(action.meta.redirect, action.meta.path);
    }
  } catch (e: any) {
    yield put({ type: "FETCH_QUOTE_FAILURE", payload: e.message });
    yield put({ type: "SET_QUOTE_STATUS", payload: "notFound" });
    yield put({
      type: "ADD_PROJECT_ASYNC",
      payload: { quote: defaultQuote },
    });
    yield put({ type: "SET_QUOTE_FEATCHING", payload: false });
    if (action.meta) {
      yield call(action.meta.redirect, action.meta.path);
    }
  }
}

export function* fetchQuoteStart() {
  yield takeLatest("FETCH_QUOTE_START", fetchQuoteAsync);
}
