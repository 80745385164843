import React, { useState } from "react";

export const useKeyPress = function (targetKey: any) {
  const [keyPressed, setKeyPressed] = useState(false);
  function downHandler({ key }: any) {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }

  const upHandler = ({ key }: any) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  });

  return keyPressed;
};

export function parseReverseGeo(geoData: any) {
  let locality, region, country, postcode, returnStr;
  if (geoData.context) {
    geoData.context.forEach((v: any, i: any) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text;
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text;
      }
      if (v.id.indexOf("region") >= 0) {
        region = v.text;
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text;
      }
    });
  }
  if (postcode && region && country) {
    returnStr = `${geoData.address} ${geoData.text}, ${locality} ${region} ${postcode}, ${country}`;
  } else {
    returnStr = geoData.place_name;
  }
  return {
    number: geoData.address,
    street: geoData.text,
    address: (geoData.text + " " + geoData.address).toLowerCase(),
    locality,
    region,
    postcode,
    country,
    concat: returnStr,
    complete: geoData.place_name,
    geometry: geoData.geometry,
  };
}

export function parseReverseGeo2(geoData: any) {
  // debugger;
  let locality, city, state, country, postcode, returnStr;
  if (geoData.context) {
    geoData.context.forEach((v: any, i: any) => {
      if (v.id.indexOf("locality") >= 0) {
        locality = v.text;
      }
      if (v.id.indexOf("postcode") >= 0) {
        postcode = v.text;
      }
      if (v.id.indexOf("place") >= 0) {
        city = v.text;
      }
      if (v.id.indexOf("region") >= 0) {
        state = v.text;
      }
      if (v.id.indexOf("country") >= 0) {
        country = v.text;
      }
    });
  }
  returnStr = geoData.place_name;

  return {
    number: geoData.address || "",
    locality: locality || "",
    address: (
      geoData.text +
      (geoData.address ? " " : "") +
      (geoData.address ? geoData.address : "")
    ).toLowerCase(),
    city,
    state,
    postcode,
    country,
    complete: geoData.place_name,
    geometry: geoData.geometry,
  };
}
