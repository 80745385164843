import styled from "styled-components/macro";
export const PaymenWrapper = styled("div")`
  .PaymentFourmMain {
    margin-right: 61px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .makeCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;
  }
`;
