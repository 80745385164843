import styled from "styled-components/macro";

export const MainContainer = styled.div`
  // flex: 1;
  height: 78px;
  left: 510px;
  top: 318px;

  background: #ffffff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  /* identical to box height */

  display: flex;
  align-items: center;
  letter-spacing: -0.015em;

  color: #d5d5d5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  padding: 15px;
`;
