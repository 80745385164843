import { ReactComponent as TickMark } from "assets/TickMark.svg";
import { ReactComponent as TickMarkBlack } from "assets/TickMarkBlack.svg";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { detailsPlanData2 } from "../cardsData";
import CheckoutWithPayment from "../checkoutWithPayment";
import { CheckoutWrapper } from "../styles";
interface deatilPlan {
  data: detailsPlanData2;
  key: number;
  isfrom?: boolean;
}
const DetailsPlan = ({ data, key, isfrom }: deatilPlan) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState<Boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<number>(0);
  useEffect(() => {
    if (data.price === "200 €" || isfrom) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  }, [isfrom]);
  return (
    <CheckoutWrapper isHover={isHover}>
      <div
        key={key}
        className="inside_main_container"
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => {
          if (isfrom || data.price === "200 €") {
            return;
          } else {
            setIsHover(false);
          }
        }}
      >
        <div className="flex_main">
          <div className="price_detials">
            <div className="price_flex">
              <div className="price">{data.price}</div>
              {data.price === "200 €" ? <button>{t("BELIEBTESTE")}</button> : ""}
            </div>
            <div className="title">{data.titile}</div>
            <div className="description">{data.description}</div>
          </div>
          <div className="bullet">
            {data.bulletPoint.map((data: string, index: number) => {
              return (
                <div className="bullet_flex" key={index}>
                  <div className="circle">{isHover ? <TickMarkBlack /> : <TickMark className="tickmark" />}</div>
                  <div className="point_name">{data}</div>
                </div>
              );
            })}
          </div>
          <button
            className="choos_button"
            onClick={() => {
              if (!isfrom) {
                setSelectedPlan(data.index);
                setOpen(true);
              }
            }}
          >
            {t("Choose plan")}
          </button>
        </div>
      </div>
      <CheckoutWithPayment open={open} setOpen={setOpen} selectedPlan={selectedPlan} />
    </CheckoutWrapper>
  );
};

export default DetailsPlan;
