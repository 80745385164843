import { combineReducers } from "redux";
import languageReducer from "./language/language.reducer";

import userReducer from "./user/user.reducer";

import projectReducer from "./project/project.reducer";

import quoteReducer from "./quote/quote.reducer";

import applicationReducer from "./application/application.reducer";
import chatReducer from "./chat/chat.reducer";
import communityReducer from "./community/community.reducer";
import notificationReducer from "./notification/notification.reducer";
import popupReducer from "./popups/popups.reducer";
import webinarReducer from "./webinar/webinar.reducer";
import productReducer from "./products/prodcut.reducer";
import hubspotDealsReducer from "./hubspotDeals/hubspotDeals.reducer";

export default combineReducers({
  user: userReducer,
  global: languageReducer,
  project: projectReducer,
  quote: quoteReducer,
  chat: chatReducer,
  notification: notificationReducer,
  community: communityReducer,
  popups: popupReducer,
  webinar: webinarReducer,
  application: applicationReducer,
  product: productReducer,
  hubspotDeals: hubspotDealsReducer
});
