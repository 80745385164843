import StepConnector from "@mui/material/StepConnector";
import { withStyles } from "@mui/styles";
import styled from "styled-components/macro";

export const Connector = withStyles({
  alternativeLabel: {
    top: 11,
    cursor: "pointer",
    width: "calc(100% - 24px) !important",
    right: "24px !important",
    transform: "translateX(-50%)",
    left: "0 !important",
  },
  lineVertical: {
    height: 20,
  },
  active: {
    "& $line": {
      borderColor: "var(--primary-color)",
    },
  },
  completed: {
    "& $line": {
      borderColor: "var(--primary-color)",
    },
  },
  line: {
    mouse: "pointer",
  },
})(StepConnector);

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  margin-inline: auto;
  margin-top: 32px;
  width: min(95%, 900px);

  .MuiStepper-root {
    width: 100%;
    cursor: pointer;
  }

  .MuiStepLabel-label,
  .MuiStepLabel-iconContainer {
    font-family: var(--theme-font);
    color: #c0c0c0 !important;
    cursor: pointer;
  }

  .MuiStepLabel-label.Mui-active,
  .MuiStepLabel-iconContainer.Mui-active {
    color: var(--blue) !important;
    font-weight: bold;
  }

  .Mui-completed .MuiStepIcon-text,
  .MuiSvgIcon-root.Mui-active > .MuiStepIcon-text {
    fill: var(--blue) !important;
  }

  @media (max-width: 400px) {
    .MuiStepLabel-label {
      display: none;
    }
  }
`;
