import UploadIcon from "components/vectors/step3/UploadIcon";
import UploadIcon1 from "components/vectors/step3/UploadIcon1";
import firebase from "firebase-global";
import { getCurrentUser, getProjectDetails } from "helpers/Selector";
import { Mixpanel } from "mixpanel";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/store";
import * as trackingEvents from "trackingEvents";
import { v4 as uuidv4 } from "uuid";
import * as S from "./styles";

const UploadCardArea = ({ setFiles, imagesList, type, setLoading }: any) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const total = useSelector(
    (state: RootState) => state?.project?.project_images?.[type]?.total
  );
  const project = useSelector(getProjectDetails);

  const currentUser = useSelector(getCurrentUser);
  //dispatch helper method
  const setProjectImages = (data: any) =>
    dispatch({ type: "SET_PROJECT_IMAGES2", payload: data });

  const addImgLocation = (imgLocation: any) => {
    setProjectImages({ imgLocation, type });
  };

  const uploadImagesToFirebase = (acceptedFiles: any) => {
    acceptedFiles.forEach((images: any) => {
      const imgLocation =
        "uploadedImages/" +
        currentUser.uid +
        "/" +
        type +
        "_" +
        (Date.now() + uuidv4());

      setLoading(true);
      firebase
        .storage()
        .ref(imgLocation)
        .put(images)
        .then((snapshot) => {
          toast.success(t("Image uploaded successfully"));
          addImgLocation(imgLocation);
          setLoading(false);
        })
        .catch(() => {
          toast.error("Image upload failed");
        });
    });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    accept: {
      "image/*": [".png", ".gif", ".jpeg", ".jpg"],
      "image/jpeg": [".jpg", ".jpeg"],
      "text/html": [".html", ".htm"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "image/svg+xml": [".svg"]
    },
    useFsAccessApi: false,

    onDrop: (acceptedFiles: any) => {
      Mixpanel.track(trackingEvents.USER_CLICKS_ON_ADDING_IMAGES, {});
      if (imagesList.length) {
        let temp = [...imagesList];

        acceptedFiles.map((file: any) => {
          temp.push(
            Object.assign(file, {
              preview: URL.createObjectURL(file)
            })
          );
        });

        uploadImagesToFirebase(acceptedFiles);
        setFiles(temp);
      } else {
        setFiles(
          acceptedFiles.map((file: any) => {
            return Object.assign(file, {
              preview: URL.createObjectURL(file)
            });
          })
        );

        uploadImagesToFirebase(acceptedFiles);
      }
    }
  });

  return (
    <div>
      <S.UploadArea
        {...getRootProps({ className: "dropzone" })}
        filesUploadHover={isDragActive}
      >
        {isDragActive ? <UploadIcon1 /> : <UploadIcon />}

        <input {...getInputProps()} />
        <S.SubText>{t("Drag and drop images to upload here")}</S.SubText>
        <S.SubText1>{t("OR")}</S.SubText1>
        <S.SubText2>{t("Browse")}</S.SubText2>
      </S.UploadArea>
    </div>
  );
};

export default UploadCardArea;
