import { Project } from 'redux/project/initialState';
import { getBuildingConsumption, getEVConsumption } from './consumption';
import { getNumber } from './dispatchFunctions';
import { getSolarPanelProduction } from './solar-panel-production';

export const giveRightPercentageForSolarPower = (project: Project): number => {
	const { model } = project;
	return model.percentages.autarky;
};

export const giveRightPercentageForHeatPumpSolarPower = (project: Project) => {
	const { model } = project;
	return model.heatpump.percentages.autarky;
};

export const giveRightPercentageForBasicSolarPower = (project: Project) => {
	const { model } = project;
	return model.household.percentages.autarky;
};

export const energyDetails = (project: Project) => {
	const consumption = getBuildingConsumption(project);
	const production = getSolarPanelProduction();
	const { model } = project;

	const energy_details = {
		total_production: production,
		consumption: consumption,
		total_self_supply: model.fromPVAndBattery,
		feed_into_grid: model.feedIn,
		grid_supply: model.fromGrid,
		total_electricity_consumption: model.consumption
	};
	return energy_details;
};

export const total_ev_consumption = (project: Project) => {
	const { ev } = project.model;

	const price_per_kWh = project?.isB2B
		? project?.consumption_details_b2b.price_per_kWh
		: project?.consumption_details.price_per_kWh;

	let wallbox: any = project?.components_parts?.wallBox;

	let consumption_ev_from_grid = ev.fromGrid;
	let saving_gas_bill_wallbox =
		wallbox?.ev_kilometers_travelled * (wallbox?.gas_price_per_100km / 100);
	let total_cost = consumption_ev_from_grid * getNumber(price_per_kWh);

	let car_consumption = 0;

	let car_consumption_ev_from_grid = 0;
	let totoal_consumption_ev = 0;
	let saving_mobility_bill = 0;
	for (let i = 0; i < project?.components?.items?.cars?.length; i++) {
		let sequence_car_consumption =
			project?.components?.items?.cars[i]?.consumption?.ev_yearly_consumption *
			(project?.components?.items?.cars[i]?.consumption
				?.solarpower_for_vehicle /
				100);
		let sequence_car_consumption_total =
			project?.components?.items?.cars[i]?.consumption?.ev_yearly_consumption;
		car_consumption = car_consumption + sequence_car_consumption;
		totoal_consumption_ev =
			totoal_consumption_ev + sequence_car_consumption_total;
		let car__ev_grid_for_calclulation =
			(1 -
				project?.components?.items?.cars[i]?.consumption
					?.solarpower_for_vehicle /
					100) *
			project?.components?.items?.cars[i]?.consumption?.ev_yearly_consumption;
		car_consumption_ev_from_grid =
			car_consumption_ev_from_grid + car__ev_grid_for_calclulation;
		let car_electricity_cost_wallBox =
			car__ev_grid_for_calclulation * getNumber(price_per_kWh);
		total_cost = total_cost + car_electricity_cost_wallBox;
		saving_mobility_bill =
			saving_mobility_bill +
			project?.components?.items?.cars[i]?.consumption
				?.ev_kilometers_travelled *
				(project?.components?.items?.cars[i]?.consumption?.gas_price_per_100km /
					100);
	}

	totoal_consumption_ev =
		totoal_consumption_ev + wallbox?.ev_yearly_consumption;
	let electricity_cost_per_100km =
		project?.consumption_details?.price_per_kWh *
		wallbox?.consumption_per_100km;
	consumption_ev_from_grid =
		car_consumption_ev_from_grid + consumption_ev_from_grid;
	let consumption_ev_from_grid_cost =
		consumption_ev_from_grid * getNumber(price_per_kWh);
	saving_mobility_bill = saving_mobility_bill + saving_gas_bill_wallbox;
	const ev_details = {
		solar_consumption_ev: ev.fromPVAndBattery,
		consumption_ev_from_grid,
		electricity_cost_per_100km,
		electricity_cost_wallBox: total_cost,
		totoal_consumption_ev: getEVConsumption(project),
		saving_mobility_bill,
		consumption_ev_from_grid_cost
	};
	return ev_details;
};

export const toatal_heatpump_consumption = (project: Project) => {
	const { model } = project;
	const price_per_kWh = project?.isB2B
		? project?.consumption_details_b2b.price_per_kWh
		: project?.consumption_details.price_per_kWh;
	let heatpump: any = project?.components_parts?.heatPump;
	let solar_consumption_heatpump = model.heatpump.fromPVAndBattery;
	let consumption_heat_pump_from_grid = model.heatpump.fromGrid;
	let heat_pump_grid_cost =
		consumption_heat_pump_from_grid * getNumber(price_per_kWh);
	const electricity_cost_heatpump =
		(heatpump.consumption_heatpump - solar_consumption_heatpump) * 0.34;
	const heatpump_details_consumption = {
		solar_consumption_heatpump,
		electricity_cost_heatpump,
		consumption_heat_pump_from_grid,
		heat_pump_grid_cost
	};
	return heatpump_details_consumption;
};

export const HeatpumpCalculation = (project: any) => {
	let helper: any = project?.components_parts?.heatPump;
	let oil_cost_selected_by_user = helper?.oil_gas_cost;
	let total_heatpump_consumption = helper?.consumption_heatpump;
	const saving_gas_oil_bill =
		oil_cost_selected_by_user * total_heatpump_consumption;
	return { saving_gas_oil_bill };
};

export const costsAndSavings = (project: Project) => {
	const consumption = getBuildingConsumption(project);

	const self_supply_factor =
		project?.model?.household?.percentages?.autarky / 100;

	const price_per_kWh = project?.isB2B
		? project?.consumption_details_b2b.price_per_kWh
		: project?.consumption_details.price_per_kWh;

	//self_supply_factor=0.1 for 10%
	// console.log(project?.model?.feedIn, "project?.model?.feedIn");
	let feedin_savings = project?.model?.feedIn * project?.FeedInTariff;
	// let elec_bill_after =
	//   (1 - self_supply_factor) * consumption * getNumber(price_per_kWh);
	// const electricity_cost_before = consumption * getNumber(price_per_kWh);
	// let saving_elec_bill = electricity_cost_before - elec_bill_after;
	let saving_elec_bill =
		consumption * self_supply_factor * getNumber(price_per_kWh);
	let elec_cost_without_pv =
		getNumber(price_per_kWh) *
		energyDetails(project).total_electricity_consumption;
	const return_from_supply_to_grid =
		project?.model?.feedIn * project?.FeedInTariff;
	const cost_for_grid_supply =
		energyDetails(project).grid_supply * getNumber(price_per_kWh);
	const costs_and_savings = {
		estimated_yearly_price_increase:
			project.costs_and_savings.estimated_yearly_price_increase,
		electricity_cost_before: saving_elec_bill,
		feedin_savings: feedin_savings,
		total_savings: getNumber(saving_elec_bill + return_from_supply_to_grid),
		elec_cost_without_pv,
		cost_for_grid_supply
	};

	return costs_and_savings;
};
