import firebase from "firebase-global";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/store";
import ShowImage from "./ShowImage";
import * as S from "./styles";
const UploadImage = ({ imagesList, setFiles, type, setLoading }: any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const total = useSelector(
    (state: RootState) => state?.project?.project_images?.[type]?.total
  );
  const project_images = useSelector(
    (state: RootState) => state?.project?.project_images
  );
  //dispatch helper method
  const removeImages = (data: any) =>
    dispatch({ type: "REMOVE_PROJECT_IMAGES", payload: data });
  useEffect(() => {}, [total]);
  const removeImageLocation = (imgLocation: any) => {
    removeImages({ type, imgLocation });
  };
  const crossHandler: any = (item: any) => {
    toast.success(t("Image removed successfully"));
    removeImageLocation(item);
  };
  return (
    <div>
      <S.MainText1>{t("Uploaded image")}</S.MainText1>
      <S.SubContainer11>
        {total?.length > 0
          ? total.map((item: any, index: any) => {
              return (
                <ShowImage
                  img_path={item}
                  index={index}
                  crossHandler={crossHandler}
                />
              );
            })
          : ""}
      </S.SubContainer11>
      <S.ColContain />
    </div>
  );
};

export default UploadImage;
