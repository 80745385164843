import { JoinedWebinar, Webinar } from "db-types/types";
import { db } from "firebase-global";
import { openPopup, setPopupData } from "redux/popups/popups.actions";
import { POPUPS } from "redux/popups/popups.reducer";
import store from "redux/store";
import { actionJoinWebinar } from "redux/webinar/webinar.actions";

export const isAnyWebinarLive = () => {
  const state = store.getState();
  const webinars: Array<Webinar> = state.webinar.webinars;
  return webinars.some((web: Webinar) => web.live);
};

export const isAnyWebinarInProgress = () => {
  const state = store.getState();
  const webinars: Array<Webinar> = state.webinar.webinars;
  const now = Date.now() / 1000; // Current time in seconds
  const durationInSec = 2 * 60 * 60; // Duration of 1 hour in seconds

  return webinars.some((web: Webinar) => {
    const webinarStartTime = web.date.seconds - 300; // 5 minutes earlier than the webinar start time
    const webinarEndTime = webinarStartTime + durationInSec;
    const isDateAfterNow = now > webinarStartTime || now > webinarEndTime;
    return isDateAfterNow && web.live;
  });
};

export const isToShowTenMinCountDown = () => {
  const state = store.getState();
  const webinars: Array<Webinar> = state.webinar.webinars;
  const now = Date.now() / 1000; // Current time in seconds

  return webinars.some((web: Webinar) => {
    const webinarStartTime = web.date.seconds - 300; // 5 minutes earlier than the webinar start time
    const isDateAfterNow = now > webinarStartTime;
    return isDateAfterNow && web.live;
  });
};

export const openWebinarConfirmationPopup = () => {
  const dispatch = store.dispatch;
  dispatch(
    setPopupData(POPUPS.WEBINAR_POPUP, {
      title: "WEBINAR_POPUP_TITLE",
      heading: "SolarHub Energy Webinar",
      button_text: "Let´s start collaborating",
      extraContent:
        "To optimize your sustainable energy setup some information from your side is helpful",
      changeWebinar: false
    })
  );
  dispatch(openPopup(POPUPS.WEBINAR_POPUP));
};

export const joinLiveWebinar = () => {
  const onGoing = isAnyWebinarInProgress();
  const state = store.getState();
  const webinars: Array<Webinar> = state.webinar.webinars;
  const now = Date.now() / 1000; // Current time in seconds
  const durationInSec = 2 * 60 * 60; // Duration of 1 hour in seconds

  const result = webinars.find((web: Webinar) => {
    const webinarStartTime = web.date.seconds - 300; // 5 minutes earlier than the webinar start time
    const webinarEndTime = webinarStartTime + durationInSec;
    const isDateAfterNow = now > webinarStartTime || now > webinarEndTime;
    return isDateAfterNow && web.live;
  });
  if (onGoing) {
    return window.open(`https://us06web.zoom.us/j/${result?.meetingNumber}`);
  }
  const meetingNumber = store.getState()?.webinar?.joinedWebinar?.meetingNumber;

  window.open(`https://us06web.zoom.us/j/${meetingNumber}`);
};

export const getLiveWebinarTime = () => {
  const onGoing = isAnyWebinarInProgress();
  const state = store.getState();
  const webinars: Array<Webinar> = state.webinar.webinars;
  const now = Date.now() / 1000; // Current time in seconds
  const durationInSec = 2 * 60 * 60; // Duration of 1 hour in seconds
  console.log(webinars, "webinars");

  const nextWebinar = webinars.find((web: Webinar) => {
    const webinarStartTime = web.date.seconds - 300; // 5 minutes earlier than the webinar start time
    const webinarEndTime = webinarStartTime + durationInSec;
    const isDateAfterNow = now > webinarStartTime || now > webinarEndTime;
    return isDateAfterNow && web.live;
  });
  console.log(nextWebinar, "nextWebinar");

  // If there is a live webinar, return its start time
  if (onGoing && nextWebinar) {
    const webinarStartTime = nextWebinar.date.seconds - 300; // 5 minutes earlier than the webinar start time
    return webinarStartTime;
  }

  // If there is no live webinar, return the start time of the joined webinar (if available)
  const joinedWebinar = store.getState()?.webinar?.joinedWebinar;
  if (joinedWebinar && joinedWebinar.live) {
    const webinarStartTime = joinedWebinar.date.seconds - 300; // 5 minutes earlier than the webinar start time
    return webinarStartTime;
  }

  // If there is no live webinar or joined webinar, return null
  return null;
};

export const updateLastJoinedWebinarColletion = (
  lastJoinedWebinar: JoinedWebinar
) => {
  const uid = store.getState().user.currentUser?.uid;
  if (!uid) return;
  console.log(lastJoinedWebinar, "lastJoinedWebinar");

  const docRef = db.collection("joinedWebinars").doc(uid);
  docRef.get().then((doc) => {
    docRef
      .set({ ...doc.data(), lastJoinedWebinar })
      .then(() => {})
      .catch((err) => console.log(err));
  });
};
