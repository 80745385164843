import store from "redux/store";

const project = store.getState().project;
export const stepperHeadingEnglish = [
  "System",
  "Components",
  "Images",
  "Realization",
];

export const popupStepperHeading = [
  "Location provided",
  "Upload your images",
  // "Battery",
  // "Wallbox",
  // "HEATPUMP",
];

export const stepperHeadingGerman = [
  "System",
  "Komponenten",
  "Bilder",
  "Umsetzung",
];

export const consumption_details =
  project?.project_type === "b2c"
    ? project?.consumption_details
    : project?.consumption_details_b2b;
