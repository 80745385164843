import lodash from "lodash";
import {
  ConsumptionModel,
  ModelInputs,
  OverallConsumptionModel,
} from "prediction-model/types/model.types";

export const defaultModelInput: ModelInputs = {
  noOfPeople: 0,
  battery: 0,
  evDistance: 0,
  haveHeatingRod: false,
  haveHeatpump: false,
  heatpumpConsumption: 0,
  heatpumpRequirement: 0,
  householdConsumption: 0,
  pv: 0,
};

const _defaultConsumptionModel: ConsumptionModel = {
  consumption: 0,
  fromBattery: 0,
  fromGrid: 0,
  fromPV: 0,
  fromPVAndBattery: 0,
  percentages: {
    autarky: 0,
    maxPossibleAutarky: 0,
    fromBattery: 0,
    fromGrid: 0,
    fromPV: 0,
  },
};

export const defaultModelState: OverallConsumptionModel = {
  ...lodash.cloneDeep(_defaultConsumptionModel),
  feedIn: 0,
  fromBatteryWithAdjustment: 0,
  fromPVAndAdjustedBattery: 0,
  ev: {
    ...lodash.cloneDeep(_defaultConsumptionModel),
  },
  heatShare: {
    heatpump: 0,
    kessel: 0,
  },
  heatingRod: {
    ...lodash.cloneDeep(_defaultConsumptionModel),
    requirement: 0,
  },
  heatpump: {
    ...lodash.cloneDeep(_defaultConsumptionModel),
    requirement: 0,
  },
  household: {
    ...lodash.cloneDeep(_defaultConsumptionModel),
  },
  inputs: {
    ...lodash.cloneDeep(defaultModelInput),
  },
};
