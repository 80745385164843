import { ProjectConstants } from "global-constants";
import { getTotalFinancingCost } from "./total-financial-cost";

/**
 * Givent the project it will return the yearlyFinancing amount
 * @param project Project
 * @returns return the yearly financing amount/cost
 */
export const getYearlyFinanceCost = (project: any) => {
  const DURATION_YEARS = ProjectConstants.FINANCING_DURATION_IN_YEARS;
  return getTotalFinancingCost(project) / DURATION_YEARS;
};
