import { initialState } from "./initialState";

const INITIAL_STATE = {
  ...initialState,
};

const quoteReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "FETCH_QUOTE_SUCCESS":
      return {
        ...state,
        quote: action.payload,
        status: "found",
      };
    case "SET_CRN_REQUEST":
      return {
        ...state,
        crn_requested: action.payload,
      };
    case "SET_SMART_ECO_GREEN_IMG_PANEL_DETAILS":
      return {
        ...state,
        smarEcoGreenImgPanelDetails: { ...action.payload },
      };
    case "FETCH_QUOTE_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        projectRequestTime: Date.now(),
        status: "notFound",
      };
    case "UPDATE_QUOTE_IMAGE":
      return {
        ...state,
        quote: {
          ...state.quote,
          img_url: action.payload,
        },
      };
    case "SET_IMG_URL":
      return {
        ...state,
        imgUrl: action.payload,
        isImageIsFeatched: true,
      };
    case "SET_QUOTE_ANALYZED":
      return {
        ...state,
        isQuoteAnalyzed: true,
      };
    case "SET_QUOTE_STATUS_ON_QUOTE":
      return {
        ...state,
        status: null,
      };
    default:
      return state;
  }
};

export default quoteReducer;
