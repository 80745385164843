import {
  buildingConsumptionsB2B,
  buildingConsumptionsB2C,
} from "data/building-consumption";
import { getNumber } from "helpers/HeplerMothods";
import {
  getIsProjectB2C,
  getIsYearlyConsumptionCustomEntered,
} from "helpers/project-helper";

/**
 * Total building consumption needed for the year based on the project
 * @returns total building consumption
 */
export const getBuildingConsumption = (proj: any) => {
  const isProjectB2C = getIsProjectB2C(proj);

  const customEnteredCons = getIsYearlyConsumptionCustomEntered(proj);
  if (customEnteredCons) return Math.round(getNumber(customEnteredCons));

  if (isProjectB2C) return _buildingConsumptionForB2C(proj);
  else return _buildingConsumptionForB2B(proj);
};

/**
 * Total building consumption for b2c building
 * - Based on building_type, single or multi, it will selected the part of the
 * static data that corresponds to consumption : buildingConsumptionsB2C['single'] or
 * buildingConsumptionsB2C['multi']
 *
 * - Based on people (no of people) it will give index of the row in dataset
 * buildingConsumptionsB2C['single' | 'multi'][people - 1]
 *
 * - Based on isWaterHeating it will choose the correct variant of consumption in the row
 * buildingConsumptionsB2C['single' | 'multi'][people - 1][isWaterHeating(0|1)]
 *
 * @param proj Project
 * @returns total building consumption
 */
export const _buildingConsumptionForB2C = (proj: any) => {
  const { building_type, people, isWaterHeating } = proj.consumption_details;
  const cons =
    buildingConsumptionsB2C[building_type][people - 1][isWaterHeating ? 1 : 0];
  return cons;
};

/**
 * Total building consumption for b2b building
 *
 * @param proj Project
 * @returns total building consumption
 */
export const _buildingConsumptionForB2B = (proj: any) => {
  const { business_type, no_of_employees, kwa_per_employee } =
    proj.consumption_details_b2b;

  const businessDefault = buildingConsumptionsB2B[business_type].default;
  const employeesCons = no_of_employees * kwa_per_employee;
  const total = businessDefault + employeesCons;

  return total;
};
