import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { closePopup } from "redux/popups/popups.actions";
import { POPUPS } from "redux/popups/popups.reducer";
import PopupSkeleton from "../popupSkeleton/popupSkeleton";
import Hero from "././../../vectors/Step2/Hero.png";
import * as S from "./InstallerRequestSuccessfull.styles";

const InstallerRequestSuccessfull = (props: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    dispatch(closePopup(POPUPS.INSTALLER_CONFIRMATION));
  }, [dispatch]);

  return (
    <PopupSkeleton handleClose={handleClose} open={true}>
      <S.InstallerRequestSuccessfullWrapper>
        <S.MainText>
          {t(
            "We're requesting possible installation times for your PV project. On average projects get realized in 6-8 weeks"
          )}
        </S.MainText>
        <div className="image_and_text">
          <div className="text">
            <S.SubText1>
              {t(
                "We will let you know as soon as we have received possible relization dates."
              )}
            </S.SubText1>
            <S.SubText1>
              {t(
                "In the meantime feel free to change your setup and browse your project"
              )}
            </S.SubText1>
          </div>
          <img src={Hero} />
        </div>
      </S.InstallerRequestSuccessfullWrapper>
    </PopupSkeleton>
  );
};

export default InstallerRequestSuccessfull;
