export const setCurrentStepHandler = (state: any, step: number) => {
  return {
    ...state,
    currentStep: step,
  };
};

export const setPopupCurrentStepHandler = (state: any, step: number) => {
  return {
    ...state,
    popupCurrentStep: step,
  };
};
