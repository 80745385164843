import axios from "axios";
import firebase, { currentDatabase, db } from "firebase-global";
import {
  getSevenDaysAfterDate,
  removeGermanyFromAddress,
  url
} from "helperMethod";
import i18n from "i18n/config";
import { toast } from "react-toastify";
import { closePopup } from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import store from "redux/store";
import { setUserDetails } from "redux/user/user.actions";
import { v4 as uuidv4 } from "uuid";
import { sentSignUpWebinarMail } from "./email/sentEmail";
import { firebaseProjectId } from "./FirebaseHelperMethods";
import { associateDeal } from "./hubspot/Hubspot";
import { addDealToDatabase } from "./hubspot/hubspotFirebase";
import { hubSpotOwnerDetails, hubspotStages } from "./hubspot/hubspotStages";
import {
  registerInJoinedWebinar,
  registerUserInZoomWebinar
} from "./zoom/zoom";

var hubspotAxios = axios.create({
  baseURL:
    "https://us-central1-solarhub-44450.cloudfunctions.net/api/hubspotapi/",
  timeout: 10000,
  headers: { "X-Custom-Header": "foobar" }
});

export const checkLanguage = () => {};

//comman methods for gerating discourse password
const genrateRandomUserName = (email: String) => {
  const result = uuidv4().split("-");
  let username = email + result[1];
  if (username.length >= 20) {
    let result = username.length - 20;
    username.slice(0, -result);
  }
  return username;
};

const pushBackToTheirLastProject = (uid: any, navigate: any) => {
  if (uid) {
    db.collection("saved_projects")
      // .orderBy("createdAt", "desc") // Order documents by added_at field in descending order
      .where("uid", "==", uid)
      .get()
      .then((snap) => {
        const all_projects: any = [];
        snap.forEach((doc: any) => {
          all_projects.push(doc.data());
        });
        all_projects.sort(
          (a: any, b: any) =>
            //@ts-ignore
            new Date(b?.createdAt?.seconds * 1000) -
            //@ts-ignore
            new Date(a?.createdAt?.seconds * 1000)
        );
        if (all_projects.length > 0) {
          navigate("/application/saved-" + all_projects[0].pid);
        }
      })
      .catch((e) => console.log(e));
  }
};

const genrateRandomPassword = (email: String) => {
  const password = email + uuidv4();
  return password;
};

//api mehtod for regestirng user in discourse
const registerUserInDiscourse = async (userDetails: any) => {
  const data = {
    name: userDetails.fullName,
    email: userDetails.email,
    password: userDetails.disPassword,
    username: userDetails.username
  };
  const url = `https://solarhub24.herokuapp.com/SolarHub-Discourse/user/createUser`;
  try {
    const response = await axios.post(url, data);
    if (response) {
    }
  } catch (err) {
    console.log(err);
  }
};

//api mehtod for regestirng user in discourse
const registerUserInHubspot = async (userDetails: any) => {
  if (currentDatabase.projectId === firebaseProjectId.stage) {
    return;
  }
  const data = {
    company: "xyz company",
    email: userDetails.email,
    firstname: userDetails.fullName?.split(" ")[0],
    lastname: userDetails.fullName?.split(" ")[1],
    address: userDetails?.hasOwnProperty("address") ? userDetails?.address : "",
    phone: userDetails?.hasOwnProperty("phoneNumber")
      ? userDetails?.phoneNumber
      : "",
    website: "https://solarhub24.de",
    city:
      userDetails?.hasOwnProperty("address") && userDetails?.address !== ""
        ? userDetails?.address?.split(",")[1].trim().split(" ")[1]
        : ""
  };
  try {
    const response = await hubspotAxios.post("contacts", { properties: data });
  } catch (err) {
    console.log("hubspot error", err);
  }
};

const createHubSpotFirstDeal = async (userDetails: any) => {
  if (currentDatabase.projectId === firebaseProjectId.stage) {
    return;
  }
  if (
    !userDetails?.hasOwnProperty("address") ||
    userDetails?.address === null ||
    userDetails?.address === undefined ||
    userDetails?.address === ""
  ) {
    return;
  }
  const dealDeatils = {
    amount: "",
    closedate: getSevenDaysAfterDate(),
    dealname: `${userDetails?.fullName?.split(" ")[0]} - ${
      userDetails?.hasOwnProperty("address")
        ? removeGermanyFromAddress(userDetails?.address)
        : ""
    } D`,
    dealstage: hubspotStages[0].id,
    hubspot_owner_id: hubSpotOwnerDetails[1].id,
    pipeline: "default",
    dealtype: "newbusiness",
    project_url: url()
  };
  try {
    const result = await hubspotAxios.post("deal", { properties: dealDeatils });
    if (result) {
      return result;
    }
  } catch (err) {
    console.log("hubspot error", err);
  }
};

const getWebinarId = () => {
  const selectedWebinarId = store.getState()?.webinar?.selectedWebinarId;
  if (selectedWebinarId) {
    return selectedWebinarId;
  }
  return null;
};

//comman method for updaing details in our databse for disocure and all
export const updateUserInfo = async (userDetails: any, info: any) => {
  if (info == "login") {
    const docref = db.collection("users").doc(userDetails.uid);
    docref.get().then((doc: any) => {
      if (doc.exists) {
        if (doc.data().fullName) {
          delete userDetails.fullName;
        }
        docref.set({
          ...doc.data(),
          ...userDetails
        });
        store.dispatch(
          setUserDetails({
            ...doc.data(),
            ...userDetails
          })
        );
      }
    });
  } else if (info == "register") {
    await registerUserInHubspot(userDetails);
    // const userDealData = await createHubSpotFirstDeal(userDetails);
    // let dealId: any = null;
    // if (userDealData) {
    //   dealId = userDealData?.data?.id;
    //   addDealToDatabase({
    //     uid: userDetails.uid,
    //     address: userDetails?.address,
    //     dealId,
    //     uploadImageTaskId: null,
    //     installerRequestTaskId: null
    //   });
    //   associateDeal(dealId, userDetails?.email);
    // }
    const docref = db.collection("users").doc(userDetails.uid);
    docref.get().then(async (doc: any) => {
      if (doc.exists) {
        if (!doc.data().username) {
          // await registerUserInDiscourse(userDetails);
        } else {
          delete userDetails.disPassword;
          delete userDetails.username;
        }
        if (doc.data().fullName) {
          delete userDetails.fullName;
        }
        docref.set({
          ...doc.data(),
          ...userDetails,
          webinarId: getWebinarId()
        });
        store.dispatch(
          setUserDetails({
            ...doc.data(),
            ...userDetails,
            webinarId: getWebinarId()
          })
        );
      } else {
        db.collection("users")
          .doc(userDetails.uid)
          .set({ ...userDetails, webinarId: getWebinarId() })
          .then((data) => {
            store.dispatch(
              setUserDetails({
                ...userDetails,
                webinarId: getWebinarId()
              })
            );
          })
          .catch((error) => alert(error));
        // await registerUserInDiscourse(userDetails);
      }
    });
  } else {
    const uid = userDetails.uid;
    delete userDetails.uid;
    db.collection("users")
      .doc(uid)
      .update({ needToShowWebinarWelcome: false })
      .then((data) => {
        store.dispatch(
          setUserDetails({
            ...store.getState().user.userDetails,
            needToShowWebinarWelcome: false
          })
        );
      })
      .catch((error) => alert(error));
  }
};

export const forgetPassword: any = (email: any) => {
  if (email === "") {
    return;
  }
  var actionCodeSettings = {
    url: window.location.origin,
    handleCodeInApp: true
  };
  firebase
    .auth()
    .sendPasswordResetEmail(email, actionCodeSettings)
    .then((result: any) => {})
    .catch((error: any) => {
      console.log(error);
    });
};

export const logInWithEmailPassword = async (
  loginDetails: any,
  save: any,
  project: any,
  navigate: any
) => {
  try {
    const userCredential: any = await firebase
      .auth()
      .signInWithEmailAndPassword(loginDetails?.email, loginDetails?.password);

    var user = userCredential.user;
    const userDetails: any = {
      fullName: user?.displayName,
      email: user?.email,
      uid: user.uid
    };

    store.dispatch(closePopup(POPUPS.AUTH_POPUP));
    store.dispatch(closePopup(POPUPS.WEBINAR_POPUP));

    if (
      window.location.pathname.includes("default") &&
      project?.quoteStatus === "found"
    ) {
      db.collection("saved_projects")
        .where("uid", "==", user?.uid)
        .get()
        .then((snapshot) => {
          let data: any = [];
          snapshot.forEach((doc) => {
            if (
              doc
                .data()
                ?.project_details?.address?.address.includes(
                  project?.address?.address
                )
            ) {
              data.push(doc?.data());
            }
          });
          if (data.length) {
            data.sort((a: any, b: any) => (a.version < b.version ? -1 : 1));
            navigate("/application/saved-" + data[0]?.pid);
          } else {
            save(user.uid);
          }
        })
        .catch((err) => {
          console.log(err, "err");
          save(user.uid);
        });
    }

    const state = store.getState();
    const mode = state.popups.AUTH_POPUP.data.authMode;
    const isWebinarSignup =
      mode == AUTH_MODES.WEBINAR_SIGN_UP || mode == AUTH_MODES.WEBINAR_LOG_IN;
    if (isWebinarSignup) {
      registerInJoinedWebinar(getWebinarId(), userDetails);
    }
    // window.location.reload();
  } catch (err: any) {
    alert(err.message);
  }
};

export const GoogleHandler = async (
  provider: any,
  navigate: any,
  project: any,
  save: any
) => {
  if (provider === "google.com") {
    provider = new firebase.auth.GoogleAuthProvider();
  }
  // alert("google handler");
  firebase
    .auth()
    .signInWithPopup(provider)
    .then(async (result: any) => {
      let user: any = result.user;
      const ind = user?.email.indexOf("@");
      const userDetails: any = {
        fullName: user?.displayName,
        email: user?.email,
        username: genrateRandomUserName(user?.email.slice(0, ind)),
        disPassword: genrateRandomPassword(user?.email.slice(0, ind)),
        otherDetails: "other details",
        uid: user.uid
      };
      const state = store.getState();

      store.dispatch(closePopup(POPUPS.AUTH_POPUP));
      store.dispatch(closePopup(POPUPS.WEBINAR_POPUP));

      updateUserInfo(userDetails, "register");
      if (window.location.pathname.includes("default")) {
        if (
          window.location.pathname.includes("default") &&
          project?.quoteStatus === "found"
        ) {
        }
        db.collection("saved_projects")
          .where("uid", "==", user?.uid)
          .get()
          .then((snapshot) => {
            let data: any = [];
            snapshot.forEach((doc) => {
              if (
                doc
                  .data()
                  ?.project_details?.address?.address.includes(
                    project?.address?.address
                  )
              ) {
                data.push(doc?.data());
              }
            });
            if (data.length) {
              data.sort((a: any, b: any) => (a.version < b.version ? -1 : 1));
              navigate("/application/saved-" + data[0]?.pid);
            } else {
              save(user.uid);
            }
          })
          .catch((err) => {
            console.log(err, "err");
            save(project, user.uid);
          });
      } else {
        pushBackToTheirLastProject(user.uid, navigate);
      }

      const mode = state.popups.AUTH_POPUP.data.authMode;
      const isWebinarSignup =
        mode == AUTH_MODES.WEBINAR_SIGN_UP || mode == AUTH_MODES.WEBINAR_LOG_IN;
      if (isWebinarSignup) {
        registerInJoinedWebinar(getWebinarId(), userDetails);
      }
    })
    .catch((error) => {});
};

//signup createuser with email and password and send verification mail

const createUserWithEmailAndPassword = async (email: any, password: any) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: "solarhub-44450-staging.web.app",
    // This must be true.
    handleCodeInApp: true,
    dynamicLinkDomain: "solarhub24.de"
  };

  try {
    const userCred: any = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);
    await userCred.user.sendEmailVerification({ url: window.location.href });
    return {
      user: userCred.user
    };
  } catch (e: any) {
    return {
      error: e.message
    };
  }
};

export const signInWithUserEmailLink = async (
  signupDetails: any,
  save: any,
  project: any,
  navigate: any
) => {
  try {
    const { email, password, firstname, lastname, address, phoneNumber } =
      signupDetails;
    const { error, user } = await createUserWithEmailAndPassword(
      email,
      password
    );
    if (user) {
      await user.updateProfile({
        displayName: firstname + " " + lastname
      });

      const state = store.getState();
      const mode = state.popups.AUTH_POPUP.data.authMode;
      const isWebinarSignup =
        mode == AUTH_MODES.WEBINAR_SIGN_UP || mode == AUTH_MODES.WEBINAR_LOG_IN;
      const ind = email?.indexOf("@");
      const userDetails = {
        fullName: firstname + " " + lastname,
        email,
        username: genrateRandomUserName(email.slice(0, ind)),
        disPassword: genrateRandomPassword(email.slice(0, ind)),
        otherDetails: "other details",
        address,
        needToShowWebinarWelcome: isWebinarSignup,
        webinarId: getWebinarId(),
        uid: user.uid,
        phoneNumber
      };

      updateUserInfo(userDetails, "register");
      store.dispatch(closePopup(POPUPS.AUTH_POPUP));
      store.dispatch(closePopup(POPUPS.WEBINAR_POPUP));

      if (
        window.location.pathname.includes("default") &&
        project?.quoteStatus === "found"
      ) {
        db.collection("saved_projects")
          .where("uid", "==", user?.uid)
          .get()
          .then((snapshot) => {
            let data: any = [];
            snapshot.forEach((doc) => {
              if (
                doc
                  .data()
                  ?.project_details?.address?.address.includes(
                    project?.address?.address
                  )
              ) {
                data.push(doc?.data());
              }
            });
            if (data.length) {
              data.sort((a: any, b: any) => (a.version < b.version ? -1 : 1));
              navigate("/application/saved-" + data[0]?.pid);
            } else {
              save(user.uid);
            }
          })
          .catch((err) => {
            console.log(err, "err");
            save(user.uid);
          });
      }
      if (isWebinarSignup) {
        registerInJoinedWebinar(getWebinarId(), userDetails);
      } else {
        toast.success(i18n.t("Verification mail sent"));
      }
    }
    if (error) {
      console.log(error, "erorr");
      return;
    }
  } catch (err: any) {
    alert(err.error);
  }
};
