import {
  Box,
  Divider,
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
  Spacer,
  VStack,
} from "@chakra-ui/react";

const ApplicationLoader = () => {
  return (
    <Box w={"100%"}>
      <VStack padding={4}>
        <HStack w={"100%"} h={"4rem"}>
          <Skeleton height={"2rem"} width={"40%"} />
          <Spacer />
          <SkeletonCircle />
        </HStack>
        <Divider />
        <Box h={"3rem"} />
        <SkeletonText
          w={["90%", null, null, null, "7xl"]}
          noOfLines={3}
          mt={4}
          spacing={4}
        />
        <Box h={"2rem"} />
        <Flex wrap={"wrap"} w={["90%", null, null, null, "7xl"]} gap={4}>
          <Skeleton
            flex={1}
            h={["md", null, "2xl"]}
            minW={"min(200px, 100%)"}
          />
          <Skeleton
            flex={1}
            h={["md", null, "2xl"]}
            minW={"min(200px, 100%)"}
          />
          <Skeleton
            flex={1}
            h={["md", null, "2xl"]}
            minW={"min(200px, 100%)"}
          />
        </Flex>
      </VStack>
    </Box>
  );
};

export default ApplicationLoader;
