const INITIAL_STATE = {
  category: null,
  topicId: [],
};

const communityReducer = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case "SET_CATEGORY":
      return {
        ...state,
        category: action.payload,
      };
    case "SET_TopicId":
      return {
        ...state,
        topicId: action.payload,
      };
    default:
      return state;
  }
};

export default communityReducer;
