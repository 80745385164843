export const openPopup = (key: string) => {
  return {
    type: "SET_VISIBILITY",
    payload: {
      popupKey: key,
      open: true,
    },
  };
};

export const closePopup = (key: string) => {
  return {
    type: "SET_VISIBILITY",
    payload: {
      popupKey: key,
      open: false,
    },
  };
};

export const setPopupData = (key: string, data: any) => {
  return {
    type: "SET_DATA",
    payload: {
      popupKey: key,
      data,
    },
  };
};
