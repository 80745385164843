export const colors: any = {
  darkRain: `linear-gradient(to left,#09152F,#1B4963)`,
  darkBlue: "#1B4963",
  greenHover: `linear-gradient(to left,#197061 ,#1B7A69)`,
  linear: `linear-gradient(to left,#FFE925 ,#FFAA39)`,
  sunny: `linear-gradient(to left,#FBDA61 ,#FFC371)`,
  sun: `linear-gradient(to left,#FBDA61 ,#F76B1C)`,
  sun_r: `linear-gradient(to right,#FBDA61 ,#F76B1C)`,
  fire: `linear-gradient(to left,#FF5F6D  ,#FFC371)`,
  orangeSolid: "#FFAA39",
  ctaSolid: "#FFD75D",
  lightYellow: "#FFF3CA",
};

// david_live_key=pk_live_51JEqYhItm0NLgHnUQQMUhyenZQn7TPsY9PbJg3hxZBuZ9Rgio0zNPCdvYWu6mJwzuLOg571LjanTGP1MXdotpDKv00gZaEtBtE
// front end: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
