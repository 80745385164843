import React, { useEffect, useState } from "react";
import Cross from "./Cross";
import * as S from "./styles";
import * as trackingEvents from "trackingEvents";
import firebase from "firebase-global";
import { Image } from "antd";
import { Mixpanel } from "mixpanel";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

const ShowImage = ({ img_path, crossHandler, index }: any) => {
  const [url, setUrl] = useState<any>(null);
  const [imageContent, setImageContent] = useState<any>(null);

  const download = async () => {
    const originalImage = url;
    const image = await fetch(originalImage);

    //Split image name
    const nameSplit = originalImage.split("/");
    const duplicateName = nameSplit.pop();

    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "" + duplicateName + "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    firebase
      .storage()
      .ref(img_path)
      .getDownloadURL()
      .then((url) => {
        setUrl(url);
      })
      .catch((e) => {
        console.error(e.message);
      });
    firebase
      .storage()
      .ref(img_path)
      .getMetadata()
      .then((metadata) => {
        setImageContent(metadata);
      })
      .catch((e) => {
        console.error(e.message);
      });
  }, [img_path]);

  return (
    <div
      style={{ position: "relative" }}
      onMouseMove={() => {
        Mixpanel.track(trackingEvents.USER_IS_MAXIMIZING_HIS_IMAGE, {});
      }}
    >
      {url !== null &&
      imageContent !== null &&
      imageContent?.contentType !== "application/pdf" ? (
        <Image src={url} style={{ height: "100px", width: "133px" }} />
      ) : (
        url !== null &&
        imageContent !== null &&
        imageContent?.contentType === "application/pdf" && (
          <object
            data={url}
            type="application/pdf"
            style={{ height: "100px", width: "133px" }}
          >
            <p>
              Alternative text - include a link <a href={url}>to the PDF!</a>
            </p>
          </object>
        )
      )}
      <S.SubContainer13>
        <ArrowDownwardIcon
          onClick={() => download()}
          sx={{ color: "#666666" }}
        />
      </S.SubContainer13>
      <S.SubContainer12
        onClick={() => {
          crossHandler(img_path);
        }}
      >
        <Cross />
      </S.SubContainer12>
    </div>
  );
};

export default ShowImage;
