import { t } from "i18next";

export interface detailsPlanData2 {
  price: string;
  titile: string;
  description: string;
  bulletPoint: Array<string>;
  index: number;
}

export const detailsPlanData: Array<detailsPlanData2> = [
  {
    price: t("60 €"),
    titile: t("3D-Simulation"),
    description: t("PLAN_DESCRIPTION_1"),
    bulletPoint: [t("3D-Simulation"), t("Selection of optimal components"), t("Take part in solar webinars"), t("Access to solar learning")],
    index: 0,
  },
  {
    price: t("200 €"),
    titile: t("Energy consulting"),
    description: t("PLAN_DESCRIPTION_2"),
    bulletPoint: [t("Everything from 3D-simulation"), t("Online consulting"), t("1 hour phone + chat support"), t("Installer search")],
    index: 1,
  },
  {
    price: t("750 €"),
    titile: t("Realization"),
    description: t("PLAN_DESCRIPTION_3"),
    bulletPoint: [t("Check installer offers"), t("Check energy setup"), t("Energy and tax consultation*"), t("Grid enrollment")],
    index: 2,
  },
];
