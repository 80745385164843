import { roofPitchFactor as ROOF_PITCH_FACTORS } from "lib/roofPitchFactor_db";
import store from "redux/store";
import { getNumber } from "./dispatchFunctions";
import { getSolarPanelCapacity, getSolarPanelQuantity } from "./project-helper";

export const getSolarPanelProduction = () => {
  const state = store.getState();
  const proj = state.project;

  const quantity = getSolarPanelQuantity(proj);
  const capacity = getSolarPanelCapacity(proj);

  const roofPitchFactor = getNumber(ROOF_PITCH_FACTORS[proj.roofPitch]?.factor);

  const rawProduction = quantity * capacity * roofPitchFactor;
  const reductionByShade =
    (rawProduction * proj.reduceProductionDueToShade) / 100;

  const realProduction = rawProduction - reductionByShade;

  let production = 0;
  if (realProduction == 0) production = proj.productionByBatterySimulation;
  else
    production = realProduction + getNumber(proj.increseCapacityFromAdminPanle);

  return Math.round(production);
};

export const getTotalProduction = () => {};
