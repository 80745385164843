import {
  CardElement,
  IbanElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { Switch } from "antd";
import axios from "axios";
import { db } from "firebase-global";
import { getCurrentUser, getProjectDetails } from "helpers/Selector";
import { useProjectCosts } from "hooks/project-hooks/useProjectCosts";
import { useProjectTools } from "hooks/useProjectTools";
import IbanForm from "pages/application/applicationSteps/step4/payment/IbanForm";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { detailsPlanData2 } from "../cardsData";
import { PaymenWrapper } from "./PaymentFourmStyles";
import PaymentWithCard from "./PaymentWithCard";
interface PaymentFourms {
  planDetails: detailsPlanData2;
}
const PaymentFourm = ({ planDetails }: PaymentFourms) => {
  const { t } = useTranslation();
  const stripe: any = useStripe();
  const navigate = useNavigate();
  const elements = useElements();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const project = useSelector(getProjectDetails);
  //components states
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState<Boolean>(false);
  const [error, setError] = useState(null);
  const [isPaymentWithBankAcc, setIsPaymentWithBankAcc] = useState(true);
  const [disabled, setDisabled] = useState(true);
  const [clientSecret, setClientsecret] = useState(true);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("Germany");
  const [zipCode, setZipCode] = useState("");
  // helper dispatch mehtods
  const setPayment = (vendor: any) =>
    dispatch({ type: "SET_PAYMENT", payload: vendor });
  const setLoading = (data: any) =>
    dispatch({ type: "SET_LOADING", payload: data });

  const [monthlyCost, setMonthlyCost] = useState(0);
  const projectCosts = useProjectCosts();

  useEffect(() => {
    const mCost = projectCosts.totalFinancingCost / 12 / 12;
    setMonthlyCost(mCost);
  }, [projectCosts]);

  const { save } = useProjectTools();

  // calling uesEffect for getting client intent for our backend server
  useEffect(() => {
    if (isPaymentWithBankAcc) {
      const getClientSecret = async () => {
        const needThings = {
          name: currentUser?.displayName,
          address: {
            line1: project.address.complete,
            postal_code: project.address.postcode,
            city: project.address.city,
            state: project.address.state,
            country: project.address.country,
          },
        };
        const response = await axios({
          method: "POST",
          url: `https://us-central1-solarhub-44450.cloudfunctions.net/payments/create?total=${
            planDetails.price.split(" ")[0]
          }`,
          data: needThings,
          headers: { "Content-Type": "application/json" },
        });
        setClientsecret(response.data.clientSecret);
      };
      getClientSecret();
    } else {
      const getClientSecret1 = async () => {
        const needThings = {
          name: currentUser?.displayName,
          address: {
            line1: project.address.complete,
            postal_code: project.address.postcode,
            city: project.address.city,
            state: project.address.state,
            country: project.address.country,
          },
          id: currentUser?.uid,
          description: project.pid,
          email: currentUser?.email,
        };
        const response = await axios({
          method: "POST",
          url: `https://us-central1-solarhub-44450.cloudfunctions.net/payments/create-payment-intent?total=${
            planDetails.price.split(" ")[0]
          }`,
          data: needThings,
          headers: { "Content-Type": "application/json" },
        });
        setClientsecret(response.data.clientSecret);
      };
      getClientSecret1();
    }
  }, [isPaymentWithBankAcc]);

  // calling useEffect for getting user details form out database
  useEffect(() => {
    if (currentUser) {
      const docref = db.collection("users").doc(currentUser?.uid);
      docref.get().then((doc: any) => {
        if (doc.exists) {
          const { userDetails } = doc.data();
          setEmail(userDetails?.email);
          setName(userDetails?.fullName);
        } else {
          alert("Error Occured");
          setLoading(false);
        }
      });
    } else {
      navigate("/");
    }
  }, [currentUser]);

  //calling useEffect for checking is applepay,google pay or microsofe age pay is available or not
  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Demo total",
          amount: 1099,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then((result: any) => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  //submit hangler for card payments
  const submitHandler = async (event: any) => {
    event.preventDefault();
    setProcessing(true);
    if (monthlyCost === 0) {
      alert("Monthly cost can not be zero");
      return;
    }

    const payload = await stripe
      .confirmCardPayment(clientSecret, {
        payment_method: {
          //@ts-ignore
          card: elements.getElement(CardElement),
        },
      })
      .then((res: any) => {
        const { paymentIntent } = res;
        alert("Payment done!" + JSON.stringify(res));

        setPayment({ status: "succeeded", paymentIntent: paymentIntent });
        save(null, null, false, {
          ...project,
          payment: {
            ...project.payment,
            status: "succeeded",
            paymentIntent: paymentIntent,
          },
        });
        navigate(-1);
      });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const iban = elements.getElement(IbanElement);
    const accountholderName = event.target["accountholder-name"];
    const email = event.target.email;

    const result = await stripe.confirmSepaDebitPayment(clientSecret, {
      payment_method: {
        sepa_debit: iban,
        billing_details: {
          name: accountholderName.value,
          email: email.value,
        },
      },
    });
    if (result.error) {
      toast.error(result.error.message);
    } else {
      const { paymentIntent } = result;
      if (paymentIntent.status === "processing") {
        toast.success(
          t(
            "Your payment is processing. You can check payment status after some time in your dashboard profile"
          )
        );
        setPayment({ status: "processing", paymentIntent: paymentIntent });
        save(null, null, false, {
          ...project,
          payment: {
            ...project.payment,
            status: "processing",
            paymentIntent: paymentIntent,
          },
        });
      }
    }
  };
  function onChange(checked: boolean) {
    setIsPaymentWithBankAcc(!checked);
  }
  return (
    <PaymenWrapper>
      <div className="PaymentFourmMain">
        <div className="makeCenter">
          {isPaymentWithBankAcc
            ? "----- or Pay with BankAccount -----"
            : "----- or Pay with Card -----"}
          <Switch
            style={{
              background: isPaymentWithBankAcc ? "#FFD75D" : "#1B4963",
              marginLeft: "20px",
            }}
            //@ts-ignore
            value={isPaymentWithBankAcc}
            onChange={onChange}
          />
        </div>
        {isPaymentWithBankAcc ? (
          <PaymentWithCard
            setDisabled={setDisabled}
            setError={setError}
            setIsPaymentWithBankAcc={setIsPaymentWithBankAcc}
            isPaymentWithBankAcc={isPaymentWithBankAcc}
            paymentRequest={paymentRequest}
            submitHandler={submitHandler}
            email={email}
            setEmail={setEmail}
            setName={setName}
            country={country}
            setCountry={setCountry}
            name={name}
            zipCode={zipCode}
            setZipCode={setZipCode}
            processing={processing}
            error={error}
            disabled={disabled}
            succeeded={succeeded}
          />
        ) : (
          <IbanForm
            onSubmit={handleSubmit}
            disabled={!stripe}
            price={planDetails.price.split(" ")[0]}
          />
        )}
      </div>
    </PaymenWrapper>
  );
};

export default PaymentFourm;
