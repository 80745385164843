import { TextField } from "@mui/material";
import { sendMainToUserOnPasswordSuccessfullyChnaged } from "api/email/sentEmail";
import firebase from "firebase-global";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setPopupData } from "redux/popups/popups.actions";
import { AUTH_MODES, POPUPS } from "redux/popups/popups.reducer";
import cssClasses from "./authForms/login.module.css";

const ResetPassword = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [passwordReset, setPasswordReset] = useState({
    newPass: "",
    confirmPass: "",
  });

  const submitHandler = () => {
    if (passwordReset.newPass !== passwordReset.confirmPass) {
      return toast.error(t("Password doesn't match please check"));
    }
    const search = window.location.search;
    const query = new URLSearchParams(search);
    let code: any = query.get("oobCode");
    firebase
      .auth()
      .verifyPasswordResetCode(code)
      .then(function (email) {
        firebase
          .auth()
          .confirmPasswordReset(code, passwordReset.confirmPass)
          .then(function () {
            // Success
            dispatch(
              setPopupData(POPUPS.AUTH_POPUP, {
                authMode: AUTH_MODES.LOG_IN,
              })
            );
            sendMainToUserOnPasswordSuccessfullyChnaged(email);
            toast.success("Reset password was successfull");
          })
          .catch(function () {
            // Invalid code
            toast.error("Your reset code is invalid pelase try again");
          });
      })
      .catch(function () {
        toast.error("Your reset code is invalid pelase try again");
      });
  };
  return (
    <div style={{ width: "100%" }}>
      <div className={cssClasses.loginContainer}>
        <TextField
          fullWidth
          id="email-form-field"
          label={t("Enter a new password")}
          variant="outlined"
          onChange={(e: any) => {
            let data = {
              ...passwordReset,
              newPass: e.target.value,
            };
            setPasswordReset(data);
          }}
        />
        <TextField
          fullWidth
          id="password-form-field"
          label={t("Confirm your password")}
          type="password"
          variant="outlined"
          onChange={(e: any) => {
            let data = {
              ...passwordReset,
              confirmPass: e.target.value,
            };
            setPasswordReset(data);
          }}
        />
      </div>
      <div className={cssClasses.loginButton2} onClick={submitHandler}>
        {t("Reset Your Password")}
      </div>
    </div>
  );
};
export default ResetPassword;
