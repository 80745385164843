import lodash from "lodash";
import { SET_CONSUMPTION_MODEL } from "./actions";
import { initialState } from "./initialState";

const INITIAL_STATE = {
  ...initialState,
};

const setPredictions = (state: any, data: any) => {
  return {
    ...state,
    predictions: { ...data },
  };
};

const addComponent = (state: any, data: any) => {
  let x: any = {};
  x[data.item.type] = {
    ...data,
    quantity: data.quantity
      ? data.quantity
      : state.components.items[data.item.type].quantity,
    extended_warranty: data.extended_warranty
      ? data.extended_warranty
      : state.components.items[data.item.type].extended_warranty,
    isExcludeCost: data.isExcludeCost
      ? data.isExcludeCost
      : state.components.items[data.item.type].isExcludeCost,
  };
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        ...x,
      },
    },
  };
  return newState;
};

const chageExpectedReturnTime = (state: any, data: number) => {
  const newState = {
    ...state,
    expectedReturnTime: data,
  };
  return newState;
};

const increaseSolarPanelQuantity = (state: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: state.components.items.solar_panel.quantity + 1,
        },
      },
    },
  };
  return newState;
};

const setSolarPanelQuantity = (state: any, data: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: data,
        },
      },
    },
  };
  return newState;
};

const setRoofPitch = (state: any, data: any) => {
  const newState = {
    ...state,
    roofPitch: data,
  };
  return newState;
};
const decreaseSolarPanelQuantity = (state: any, data: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: state.components.items.solar_panel.quantity - 1,
        },
      },
    },
  };
  return newState;
};

const toggleWarranty = (state: any, data: any) => {
  let x: any = {};
  x[data.item.type] = { ...data, extended_warranty: !data.extended_warranty };
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        ...x,
      },
    },
  };
  return newState;
};

const removeComponent = (state: any, data: any) => {
  let x: any = {};
  x[data] = {
    item: null,
    quantity: 0,
    extended_warranty: false,
    isExcludeCost: false,
  };
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        ...x,
      },
    },
  };
  return newState;
};
const setConsumption = (state: any, data: any) => {
  if (state.project_type == "b2b") {
    const newState = {
      ...state,
      consumption_details_b2b: {
        ...state.consumption_details_b2b,
        entered_consumption: data,
      },
    };
    return newState;
  } else {
    const newState = {
      ...state,
      consumption_details: {
        ...state.consumption_details,
        entered_consumption: data,
      },
    };
    return newState;
  }
};

const setProjectImages = (state: any, data: any) => {
  const newState = {
    ...state,
    project_images: data,
  };
  return newState;
};

const setProjectExcludeCost = (state: any, data: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components?.items,
        [data.key]: {
          ...state.components?.items[data.key],
          isExcludeCost: data.isExcludeCost,
        },
      },
    },
  };
  return newState;
};

const setProjectImages2 = (state: any, data: any) => {
  let x: any = {};
  const newTotal = [...state.project_images[data.type].total, data.imgLocation];
  x[data.type] = {
    ...state?.project_images[data.type],
    type: data.type,
    total: newTotal,
  };

  const newState = {
    ...state,
    project_images: { ...state.project_images, ...x },
  };
  return newState;
};

const setProjectDescription = (state: any, data: any) => {
  let x: any = {};
  x[data.type] = {
    ...state?.project_images[data.type],
    description: data.value,
  };
  const newState = {
    ...state,
    project_images: { ...state.project_images, ...x },
  };
  return newState;
};
const addRealCar = (state: any, data: any) => {
  let arr = state.components.items.cars;
  for (let i = 0; i < arr.length; i++) {
    if (data.consumption.id === arr[i].consumption.id) {
      arr[i] = {
        ...data,
      };
    }
  }
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...arr],
      },
    },
  };
  return newState;
};
const addNextCar = (state: any, data: any) => {
  let nextCar = {
    item: data?.item,
    quantity: 1,
    extended_warranty: false,
    consumption: data?.consumption,
  };
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...state.components.items.cars, nextCar],
      },
    },
  };
  return newState;
};
const addCar = (state: any, data: any) => {
  let neededCars = [];
  let arr = state.components.items.cars;
  if (arr.length === 0) {
    neededCars = [
      {
        item: data?.item,
        quantity: 1,
        extended_warranty: false,
        consumption: data?.consumption,
      },
    ];
  } else {
    for (let i = 0; i < arr.length; i++) {
      // const findReplaceConsumptionCar = arr
      if (arr[i].consumption.id === data.consumption.id) {
        arr[i] = {
          ...arr[i],
          item: data?.item,
          quantity: 1,
          extended_warranty: false,
          consumption: data?.consumption,
        };
      }
      neededCars.push(arr[i]);
    }
  }
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...neededCars],
      },
    },
  };
  return newState;
};

const setProjectOption = (state: any, data: any) => {
  let x: any = {};
  x[data.type] = {
    ...state?.project_images[data.type],
    selectedOption: data.value,
  };
  const newState = {
    ...state,
    project_images: { ...state.project_images, ...x },
  };
  return newState;
};

const removeImages = (state: any, data: any) => {
  let x: any = {};
  const result = state.project_images[data.type].total.filter(
    (datas: any) => datas !== data.imgLocation
  );
  x[data.type] = {
    ...state?.project_images[data.type],
    type: data.type,
    total: result,
  };

  const newState = {
    ...state,
    project_images: { ...state.project_images, ...x },
  };
  return newState;
};

const setProject = (state: any, data: any) => {
  delete data.isNewUser;
  const newState = {
    ...state,
    ...data,
    isProjectSet: true,
  };
  return newState;
};

const setProfile = (state: any, data: any) => {
  const factor_for_profile: any = {
    Eco: 0.25,
    Smart: 0.5,
    Green: 1,
  };
  const { profile, total_solar_modules } = data;
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        solar_panel: {
          ...state.components.items.solar_panel,
          quantity: Math.round(
            total_solar_modules * factor_for_profile[profile]
          ),
        },
      },
    },
    profile: profile,
  };
  return newState;
};
const setProjectAddress = (state: any, data: any) => {
  const newState = {
    ...state,
    address: data,
  };
  return newState;
};

const setQRN = (state: any, data: any) => {
  const newState = {
    ...state,
    qrn: data,
  };
  return newState;
};
const setVendor = (state: any, data: any) => {
  const newState = {
    ...state,
    vendor: data,
  };
  return newState;
};

const setPayment = (state: any, data: any) => {
  const newState = {
    ...state,
    payment: data,
  };
  return newState;
};
const changeWallBoxConsumption = (state: any, data: any) => {
  const newState = {
    ...state,
    components_parts: {
      ...state.components_parts,
      wallBox: {
        ...state.components_parts.wallBox,
        ev_yearly_consumption: data,
      },
    },
  };
  return newState;
};
const setConsumptionDetails = (state: any, data: any) => {
  if (state.project_type == "b2b") {
    const newState = {
      ...state,
      consumption_details_b2b: { ...data },
    };
    return newState;
  } else {
    const newState = {
      ...state,
      consumption_details: { ...data },
    };
    return newState;
  }
};

const setConsumptionDetails2 = (state: any, data: any) => {
  if (state.project_type == "b2b") {
    const newState = {
      ...state,
      consumption_details_b2b: {
        ...state?.consumption_details_b2b,
        no_of_employees: data,
        entered_consumption: false,
      },
    };
    return newState;
  } else {
    const newState = {
      ...state,
      consumption_details: {
        ...state?.consumption_details,
        people: data,
        entered_consumption: false,
      },
    };
    return newState;
  }
};
const setPricePerKwa = (state: any, data: any) => {
  const newState = {
    ...state,
    consumption_details_b2b: {
      ...state?.consumption_details_b2b,
      kwa_per_employee: data,
      entered_consumption: null,
    },
  };
  return newState;
};

const newUserModal = (state: any) => {
  const newState = {
    ...state,
    isNewUser: false,
  };
  return newState;
};

const setQuoteStatus = (state: any, data: any) => {
  const newState = { ...state, quoteStatus: data };
  return newState;
};

const setFinancingDetails = (state: any, data: any) => {
  const newState = {
    ...state,
    isFinancing: data,
  };
  return newState;
};

const setProjectType = (state: any, data: any) => {
  if (data === "b2b") {
    return { ...state, project_type: data, isB2B: true };
  } else {
    return { ...state, project_type: data, isB2B: false };
  }
};

const setIncreseRateByBank = (state: any, data: any) => {
  const newState = {
    ...state,
    increaseRateByBank: data,
  };
  return newState;
};

const setFinancingTime = (state: any, data: any) => {
  const newState = {
    ...state,
    finacing_datails: {
      ...state.finacing_datails,
      duration_in_years: data,
    },
  };
  return newState;
};

const setHeatPumpComponentParts = (state: any, data: any) => {
  const newState = {
    ...state,
    components_parts: {
      ...state.components_parts,
      heatPump: data,
    },
  };
  return newState;
};

const setWallBoxComponentParts = (state: any, data: any) => {
  const newState = {
    ...state,
    components_parts: {
      ...state.components_parts,
      wallBox: data,
    },
  };
  return newState;
};
const changeHeatPumpCon = (state: any, data: any) => {
  const newState = {
    ...state,
    components_parts: {
      ...state.components_parts,
      heatPump: {
        ...state.components_parts.heatPump,
        consumption_heatpump: data,
      },
    },
  };
  return newState;
};

const changeCar = (state: any, data: any) => {
  const { item, id, needTochange } = data;
  let total_cars = state.components.items.cars;
  for (let i = 0; i < total_cars.length; i++) {
    if (total_cars[i].hasOwnProperty("item")) {
      if (total_cars[i].item.objectId === id) {
        total_cars[i].item = { ...item };
        let ev_yerly =
          total_cars[i].consumption.ev_kilometers_travelled *
          needTochange.consumption_per_100km;
        total_cars[i].consumption = {
          ...total_cars[i].consumption,
          consumption_per_100km: needTochange.consumption_per_100km,
          ev_yearly_consumption: ev_yerly / needTochange.diveded_value,
        };
      }
    }
  }
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...total_cars],
      },
    },
  };
  return newState;
};
const changeCarDetails = (state: any, data: any) => {
  const { item, id } = data;
  let total_cars = state.components.items.cars;
  for (let i = 0; i < total_cars.length; i++) {
    if (total_cars[i].consumption.id === id) {
      total_cars[i].consumption = { ...item };
    }
  }
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...total_cars],
      },
    },
  };
  return newState;
};
const setEvYearlyConsumption = (state: any, data: any) => {
  const newState = {
    ...state,
    Ev_yearly_consumption: data,
  };
  return newState;
};
const updateConsumptionOfCars = (state: any, data: any) => {
  // const rightCar =  state.components.items.cars.filter((doc:any)=>doc.item.data.id === doc.item.objectId)
  const { value, id } = data;
  let total_cars = state.components.items.cars;
  // const rightCar = state.components.items.cars.findIndex((doc:any)=>doc.item.data.id === doc.item.objectId)

  for (let i = 0; i < total_cars.length; i++) {
    if (total_cars[i].item.objectId === id) {
      let updatedItem = {
        ...total_cars[i].item.consumption,
        ev_yearly_consumption: value,
      };
      total_cars[i].consumption = { ...updatedItem };
    }
  }
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...total_cars],
      },
    },
  };
  return newState;
};

const updateTheFeedInTraiff = (state: any, data: any) => {
  const newState = {
    ...state,
    FeedInTariff: data,
  };
  return newState;
};
const deleteCar = (state: any, data: any) => {
  const { id } = data;
  const rightCars = state.components.items.cars.filter(
    (doc: any) => id !== doc.consumption.id
  );
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...rightCars],
      },
    },
  };
  return newState;
};
const removeCar = (state: any, data: any) => {
  const { id } = data;
  const rightCars = state.components.items.cars.filter(
    (doc: any) => id !== doc.consumption.id
  );
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...rightCars],
      },
    },
  };
  return newState;
};
const updateCar = (state: any, data: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...data],
      },
    },
  };
  return newState;
};
const wallBoxUpdate = (state: any, data: any) => {
  const newState = {
    ...state,
    components_parts: {
      ...state.components_parts,
      wallBox: data[0],
    },
  };
  return newState;
};
const updateTheFeedInTraiffReason = (state: any, data: any) => {
  const newState = {
    ...state,
    selectedFeedInReason: data,
  };
  return newState;
};
const setYearlyConsumption = (state: any, data: any) => {
  const newState = {
    ...state,
    yearlyConsumption: data,
  };
  return newState;
};
const setRightConsumptionAsEv = (state: any, data: any) => {
  // const rightCar =  state.components.items.cars.filter((doc:any)=>doc.item.data.id === doc.item.objectId)
  const { value, id } = data;
  let total_cars = state.components.items.cars;
  // const rightCar = state.components.items.cars.findIndex((doc:any)=>doc.item.data.id === doc.item.objectId)

  for (let i = 0; i < total_cars.length; i++) {
    if (total_cars[i].item.objectId === id) {
      let updatedItem = {
        ...total_cars[i].item.consumption,
        consumption_per_100km: value.consumption_per_100km,
        ev_yearly_consumption: value.ev_yearly_consumption,
      };
      total_cars[i].consumption = { ...updatedItem };
    }
  }
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...total_cars],
      },
    },
  };
  return newState;
};
const addCarFromPopup = (state: any, data: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...state.components.items.cars, data],
      },
    },
  };
  return newState;
};
const setIncreseCapacity = (state: any, data: any) => {
  const newState = {
    ...state,
    increseCapacityFromAdminPanle: data,
  };
  return newState;
};
const addUnthorCar = (state: any, data: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [...state.components.items.cars, { consumption: { ...data } }],
      },
    },
  };
  return newState;
};
const removeAllCars = (state: any, data: any) => {
  const newState = {
    ...state,
    components: {
      ...state.components,
      items: {
        ...state.components.items,
        cars: [],
      },
    },
  };
  return newState;
};
const projectReducer = (
  state = INITIAL_STATE,
  action: any
): typeof INITIAL_STATE => {
  switch (action.type) {
    case "ADD_COMPONENT":
      return addComponent(state, action.payload);
    case "REMOVE_COMPONENT":
      return removeComponent(state, action.payload);
    case "INCREASE_SOLAR_PANEL":
      return increaseSolarPanelQuantity(state);
    case "DECREASE_SOLAR_PANEL":
      return decreaseSolarPanelQuantity(state, action.payload);
    case "SET_SOLAR_PANEL_QUANTITY":
      return setSolarPanelQuantity(state, action.payload);
    case "SET_ROOF_PITCH":
      return setRoofPitch(state, action.payload);
    case "TOGGLE_WARRANTY":
      return toggleWarranty(state, action.payload);
    case "SET_CONSUMPTION":
      return setConsumption(state, action.payload);
    case "SET_CONSUMPTION_2":
      return setConsumptionDetails2(state, action.payload);
    case "SET_PRICE_PER_KWA":
      return setPricePerKwa(state, action.payload);
    case "SET_PROJECT_TYPE": {
      return {
        ...state,
        isB2B: action.payload.isB2B,
        project_type: action.payload.project_type,
      };
    }
    case "SET_FREE_CHOICE_INSTLLER": {
      return {
        ...state,
        vendor: {
          ...state.vendor,
          freeChoiceInstaller: action.payload,
        },
      };
    }
    case "SET_PRODCUTION_BATTERY_SIMULATION": {
      return {
        ...state,
        productionByBatterySimulation: action.payload,
      };
    }
    case "UPDATE_CONSUMPTION_PROFILE": {
      return {
        ...state,
        [action.payload.whereToMadeUpdate]: {
          //@ts-ignore
          ...state[action.payload.whereToMadeUpdate],
          [action.payload.filedType]: action.payload.showBuldingType,
        },
      };
    }
    case "SET_NEXT_CAR":
      return addNextCar(state, action.payload);
    case "SET_PROJECT_TO_INITAIL_STATE":
      return {
        ...lodash.cloneDeep(initialState),
      };
    case "ADD_REAL_CAR":
      return addRealCar(state, action.payload);
    case "SET_PROJECT_IMAGES":
      return setProjectImages(state, action.payload);
    case "SET_ROOFPITCH":
      return {
        ...state,
        roofPitch: action.payload,
      };
    case "SET_EXCLUDE_COST":
      return setProjectExcludeCost(state, action.payload);
    case "SET_RIGHT_CONSUMPTIONS_AS_EV":
      return setRightConsumptionAsEv(state, action.payload);
    case "SET_PROJECT_IMAGES2":
      return setProjectImages2(state, action.payload);
    case "REMOVE_PROJECT_IMAGES":
      return removeImages(state, action.payload);
    case "SET_PROJECT_DESCRIPTION":
      return setProjectDescription(state, action.payload);
    case "SET_PROJECT_OPTION":
      return setProjectOption(state, action.payload);
    case "SET_PROJECT":
      return setProject(state, action.payload);
    case "SET_PROFILE":
      return setProfile(state, action.payload);
    case "SET_PROJECT_ADDRESS":
      return setProjectAddress(state, action.payload);
    case "SET_VENDOR":
      return setVendor(state, action.payload);
    case "SET_PAYMENT":
      return setPayment(state, action.payload);
    case "SET_INCRESE_CAPACITY_ADMIN_PANEL":
      return setIncreseCapacity(state, action.payload);
    case "SET_QRN":
      return setQRN(state, action.payload);
    case "SET_CONSUMPTION_DETAILS":
      return setConsumptionDetails(state, action.payload);
    case "IS_NEW_USER_MODAL_OPEN":
      return newUserModal(state);
    case "SET_QUOTE_STATUS":
      return setQuoteStatus(state, action.payload);
    case "SET_FINANCING":
      return setFinancingDetails(state, action.payload);
    case "SET_PROJECTTYPE":
      return setProjectType(state, action.payload);
    case "SET_EXPECTEDRETURN_TIME": {
      return chageExpectedReturnTime(state, action.payload);
    }
    case "SET_FINACING_TIME": {
      return setFinancingTime(state, action.payload);
    }
    case "REMOVE_CAR": {
      return removeCar(state, action.payload);
    }
    case "SET_INCRESERATE_BY_BANK": {
      return setIncreseRateByBank(state, action.payload);
    }
    case "SET_HEATPUMP_COMPONENT_PARTS": {
      return setHeatPumpComponentParts(state, action.payload);
    }
    case "SET_WALLBOX_COMPONENT_PARTS": {
      return setWallBoxComponentParts(state, action.payload);
    }
    case "SET_FEED_IN_TRAIFF": {
      return updateTheFeedInTraiff(state, action.payload);
    }
    case "SET_FEED_IN_TRAIFF_REASON": {
      return updateTheFeedInTraiffReason(state, action.payload);
    }
    case "SET_YEARLY_CONSUMPTION": {
      return setYearlyConsumption(state, action.payload);
    }
    case "SET_INVERTER_DONE":
      return {
        ...state,
        isInverterDone: action.payload,
      };
    case "SET_CAR": {
      return addCar(state, action.payload);
    }
    case "add_unthor_car": {
      return addCarFromPopup(state, action.payload);
    }
    case "SET_CAR_CONSUMPTION": {
      return updateConsumptionOfCars(state, action.payload);
    }
    case "CHANGE_CAR": {
      return changeCar(state, action.payload);
    }
    case "REMOVE_ALL_CARS": {
      return removeAllCars(state, action.payload);
    }
    case "CAHNGE_WALLBOX_CONSUMPTION": {
      return changeWallBoxConsumption(state, action.payload);
    }
    case "SET_EV_YEARLY_CONSUMPTION": {
      return setEvYearlyConsumption(state, action.payload);
    }
    case "DELETE_CAR": {
      return deleteCar(state, action.payload);
    }
    case "SET_HEATPUMP_CONSUMPTION": {
      return changeHeatPumpCon(state, action.payload);
    }
    case "SET_UNTHOR_CAR": {
      return addUnthorCar(state, action.payload);
    }
    case "SET_UPDATE_CAR": {
      return updateCar(state, action.payload);
    }
    case "SET_WALLBOX_UPDATE": {
      return wallBoxUpdate(state, action.payload);
    }
    case "CHANGE_CAR_DETIALS": {
      return changeCarDetails(state, action.payload);
    }
    case "REMOVE_COMPOENNTS": {
      return {
        ...state,
        components: {
          ...state.components,
          items: {
            ...state.components.items,
            solar_panel: {
              item: null,
              quantity: 0,
              isExcludeCost: false,
              extended_warranty: false,
            },
            inverter: {
              item: null,
              quantity: 0,
              isExcludeCost: false,
              extended_warranty: false,
            },
          },
        },
      };
    }
    case "SET_ELECTRICITY_PRICE":
      const isB2B = action.payload.isB2B;
      if (isB2B) {
        return {
          ...state,
          consumption_details_b2b: {
            ...state.consumption_details_b2b,
            price_per_kWh: action.payload.value,
          },
        };
      } else {
        return {
          ...state,
          consumption_details: {
            ...state.consumption_details,
            price_per_kWh: action.payload.value,
          },
        };
      }

    case "SET_ESTIMATED_PRICE_INCREASE":
      return {
        ...state,
        costs_and_savings: {
          ...state.costs_and_savings,
          estimated_yearly_price_increase: action.payload,
        },
      };
    case "SET_PROJECT_PID":
      return {
        ...state,
        pid: action.payload,
      };
    case "SET_ROOF_PITCH_2":
      return {
        ...state,
        roofPitch: action.payload,
      };
    case "SET_REDUCE_PRODUCTION":
      return {
        ...state,
        reduceProductionDueToShade: action.payload,
      };
    case "SET_CALCULATION_CONSTANT":
      return {
        ...state,
        web_constants_calulation: action.payload,
      };
    case "SET_BATTERY_KWH":
      return {
        ...state,
        components: {
          ...state.components,
          items: {
            ...state.components.items,
            battery: {
              ...state.components.items.battery,
              batteryKwh: action.payload,
            },
          },
        },
      };
    case "UPDATE_INVERTER":
      return {
        ...state,
        components: {
          ...state.components,
          items: {
            ...state.components.items,
            inverter: {
              ...state.components.items.inverter,
              item: action.payload,
              quantity: 1,
            },
          },
        },
      };
    case SET_CONSUMPTION_MODEL:
      return {
        ...state,
        model: {
          ...action.payload,
        },
      };
    case "SET_HOUSEHOLD_SELF_SUPPLY":
      return {
        ...state,
        model: {
          ...state.model,
          household: {
            ...state.model.household,
            percentages: {
              ...state.model.household.percentages,
              autarky: action.payload,
            },
          },
        },
      };
    case "SET_EV_SELF_SUPPLY":
      return {
        ...state,
        model: {
          ...state.model,
          ev: {
            ...state.model.ev,
            percentages: {
              ...state.model.ev.percentages,
              autarky: action.payload,
            },
          },
        },
      };
    case "SET_HEATPUMP_SELF_SUPPLY":
      return {
        ...state,
        model: {
          ...state.model,
          heatpump: {
            ...state.model.heatpump,
            percentages: {
              ...state.model.heatpump.percentages,
              autarky: action.payload,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default projectReducer;
