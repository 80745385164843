const INITIAL_STATE = {
	webinars: [],
	joinedWebinars: [],
	lastJoinedWebinar: null,
	lastFetch: null,
	selectedWebinarDateTime: null,
	selectedWebinarId: null,
	joinedWebinar: false
};

const webinarReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {
		case 'SET_WEBINARS':
			return {
				...state,
				webinars: action.payload.slice()
			};
		case 'SET_JOINED_WEBINARS': {
			return {
				...state,
				joinedWebinars: action.payload
			};
		}
		case 'SET_SELECTED_WEBINAR_DATE':
			return {
				...state,
				selectedWebinarDateTime: action.payload
			};
		case 'SET_SELECTED_WEBINAR_ID':
			return {
				...state,
				selectedWebinarId: action.payload
			};
		case 'SET_LAST_JOINED_WEBINAR':
			return {
				...state,
				lastJoinedWebinar: action.payload
			};
		case 'JOIN_WEBINAR':
			return {
				...state,
				joinedWebinar: action.payload
			};
		default:
			return state;
	}
};

export default webinarReducer;
