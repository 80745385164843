import moment from "moment";
import store from "redux/store";

export const generalFormat = (date: Date | undefined) => {
  if (date == null) return "";

  const lang = store.getState().global.language;

  if (lang == "en") return moment(date).format("dddd, MMMM Do YYYY, h:mm a");
  else if (lang == "de")
    return date.toLocaleString("de-AT", {
      dateStyle: "full",
      timeStyle: "short",
      hourCycle: "h24",
    });
  else if (lang == "es")
    return date.toLocaleString("de-AT", {
      dateStyle: "full",
      timeStyle: "short",
      hourCycle: "h24",
    });
};

export const germanDate = (date: Date | undefined) => {
  if (date == null) return "";
  return date.toLocaleString("de-AT", {
    dateStyle: "full",
    timeStyle: "short",
    hourCycle: "h24",
  });
};
