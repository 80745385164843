import 'antd/dist/antd.css';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import store from 'redux/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import './styles/global.css';

import 'i18n/config';

const queryClient = new QueryClient();

if (process.env.NODE_ENV === 'production') {
	console.log = () => {};
	console.debug = () => {};
}

Sentry.init({
	dsn: 'https://cb86f2a9b86a430a8faf3235041f2749@o4504355764109312.ingest.sentry.io/4504355773546496',
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
});

ReactDOM.render(
	<Provider store={store}>
		<BrowserRouter>
			<QueryClientProvider client={queryClient}>
				<App />
			</QueryClientProvider>
		</BrowserRouter>
	</Provider>,
	document.getElementById('root')
);
