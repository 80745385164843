import { getNumber } from "helpers/dispatchFunctions";

/**
 * Calculates cost of the single component with warranty if included
 *
 * Formula = quantity * price + warranty_cost
 *
 * @param component Component object found in project.components.item
 * @param priceKey Which price to check for either price_b2b or price_b2c
 * @returns given component's cost
 */
export const componentCost = (component: any, priceKey: any) => {
  let cost = 0;

  const hasNoItem = !component?.item;
  if (hasNoItem) return cost;

  const productCost = component.quantity * getNumber(component.item[priceKey]);

  let extendedWarrantyCost = 0;
  const hasWarranty = component.extended_warranty;
  if (hasWarranty) {
    const defaultWarranty = productCost * 0.01;
    const customWarranty = component.item?.extended_warranty_cost;
    extendedWarrantyCost = getNumber(customWarranty || defaultWarranty);
  }

  cost = productCost + extendedWarrantyCost;
  return cost;
};
